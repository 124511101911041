import React, { useState, useContext } from "react";
import { Container, TextField, Button, Typography, Box, CircularProgress, Modal, Snackbar } from "@mui/material";
import { CardElement, useStripe, useElements, loadStripe, Elements } from "@stripe/react-stripe-js";
import axios from 'axios';
import { AuthContext } from "../features/authContext";
import { db } from '../firebase/firebase';
import { doc, updateDoc, collection, query, where, getDocs, addDoc, getDoc, getFirestore } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Decimal from 'decimal.js';
import stripeLogo from '../assets/stripe-buttons.png';

const StripeApp = ({ totalPrice, itemId, currency, itemType, serviceFee, commanduid }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [currencyError, setCurrencyError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [userIdError, setUserIdError] = useState(false);
  const [userIdErrorMessage, setUserIdErrorMessage] = useState('');

  const couturierAmount = totalPrice - serviceFee;
  const itemtype = Array.isArray(itemType) && itemType.length === 1 ? itemType[0] : itemType;
  const itemid = Array.isArray(itemId) && itemId.length === 1 ? itemId[0] : itemId;
  const commandAmount = totalPrice + serviceFee;
  const amount = itemtype === 'commandes' ? commandAmount : totalPrice;

  const saveOrder = async (orderDetails) => {
    if (orderDetails.itemType !== undefined) {
      console.log("order details: ", orderDetails);
      try {
        await addDoc(collection(db, 'clientorders'), orderDetails);
        console.log('Order saved successfully');
      } catch (error) {
        console.error('Error saving order:', error);
      }
    } else {
      console.error('Error saving order: itemType is undefined');
    }
  };

  const fetchPaymentIntentClientSecret = async () => {
    try {
      const response = await axios.post('https://us-central1-couturia-auth.cloudfunctions.net/createPaymentIntent', {
        amount: totalPrice,
        currency: currency,
        itemId: itemid,
      });
      const { clientSecret } = response.data;
      return clientSecret;
    } catch (error) {
      console.error('Error fetching client secret:', error);
      alert('Unable to fetch client secret.');
      return null;
    }
  };

  const handlePatternPaymentPress = async () => {
    setIsLoading(true);
    
    try {
      const clientSecret = await fetchPaymentIntentClientSecret();

      if (!clientSecret) {
        setIsLoading(false);
        return;
      }

      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            email: email,
          },
        },
      });
      

      if (error) {
        console.log('Payment confirmation error', error);
        setIsSuccess(false);
        setModalMessage(`Votre paiement pour le patron n'a pas abouti. Veuillez réessayer! \nDétails de l'erreur: ${error.message}`);
      } else if (paymentIntent) {
        console.log('Pattern payment successful', paymentIntent);
        setIsSuccess(true);
        setModalMessage("Votre paiement pour le patron a été effectué avec succès !");

        const itemOwnerId = await fetchItemOwnerId(itemid, 'patterns');
        const itemName = await fetchItemName(itemid, 'patterns');

        if (itemOwnerId) {
          await updateNotifications(currentUser.uid, itemOwnerId, itemName);
          console.log('Notifications updated successfully');
        } else {
          console.error('Item owner ID not found.');
        }
      }

    } catch (error) {
      console.error('An error occurred during payment processing:', error);
      setIsSuccess(false);
      setModalMessage('Une erreur est survenue lors du traitement de votre paiement. Veuillez réessayer.');
    }

    setModalVisible(true);
    setIsLoading(false);
  };

  const handleOrderPaymentPress = async () => {
    setIsLoading(true);
    const clientSecret = await fetchPaymentIntentClientSecret();

    if (!clientSecret) {
      setIsLoading(false);
      return;
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: email,
        },
      },
    });

    if (error) {
      console.log('Payment confirmation error', error);
      setIsSuccess(false);
      setModalMessage(`Votre paiement pour la commande n'a pas abouti. Veuillez réessayer! \nDétails de l'erreur: ${error.message}`);
    } else if (paymentIntent) {
      console.log('Order payment successful', paymentIntent);
      setIsSuccess(true);
      setModalMessage("Votre paiement pour la commande a été effectué avec succès !");
      // Additional logic for updating notifications
      try {
        const itemOwnerId = commanduid;
        const itemName = await fetchItemName(itemid, 'commandes');
        //console.log("id de celui qui a propose: ", itemOwnerId);
  
        if (itemOwnerId) {
          await updateNotifications(currentUser.uid, itemOwnerId, itemName);
          console.log('Notifications updated successfully');
        } else {
          console.error('Item owner ID not found.');
        }
      } catch (e) {
        console.error('Error updating order payment notifications:', e);
      }
    }
    setModalVisible(true);
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    if (isSuccess) {
      navigate('/notifications');
    }
  };

  const handleShoesPaymentPress = async () => {
    setIsLoading(true);
    const clientSecret = await fetchPaymentIntentClientSecret();

    if (!clientSecret) {
      setIsLoading(false);
      return;
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: email,
        },
      },
    });

    if (error) {
      console.log('Payment confirmation error', error);
      setIsSuccess(false);
      setModalMessage(`Votre paiement pour les chaussures n'a pas abouti. Veuillez réessayer! \nDétails de l'erreur: ${error.message}`);
    } else if (paymentIntent) {
      console.log('Shoes payment successful', paymentIntent);
      setIsSuccess(true);
      setModalMessage("Votre paiement pour les chaussures a été effectué avec succès !");
      // Additional logic for updating notifications
    }
    setModalVisible(true);
    setIsLoading(false);
  };

  const handleFabricPaymentPress = async () => {
    setIsLoading(true);
    const clientSecret = await fetchPaymentIntentClientSecret();

    if (!clientSecret) {
      setIsLoading(false);
      return;
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: email,
        },
      },
    });

    if (error) {
      console.log('Payment confirmation error', error);
      setIsSuccess(false);
      setModalMessage(`Votre paiement pour le tissu n'a pas abouti. Veuillez réessayer! \nDétails de l'erreur: ${error.message}`);
    } else if (paymentIntent) {
      console.log('Fabric payment successful', paymentIntent);
      setIsSuccess(true);
      setModalMessage("Votre paiement pour le tissu a été effectué avec succès !");
      // Additional logic for updating notifications
    }
    setModalVisible(true);
    setIsLoading(false);
  };

  const handleAccessoryPaymentPress = async () => {
    setIsLoading(true);
    const clientSecret = await fetchPaymentIntentClientSecret();

    if (!clientSecret) {
      setIsLoading(false);
      return;
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: email,
        },
      },
    });

    if (error) {
      console.log('Payment confirmation error', error);
      setIsSuccess(false);
      setModalMessage(`Votre paiement pour l'accessoire n'a pas abouti. Veuillez réessayer! \nDétails de l'erreur: ${error.message}`);
    } else if (paymentIntent) {
      console.log('Accessory payment successful', paymentIntent);
      setIsSuccess(true);
      setModalMessage("Votre paiement pour l'accessoire a été effectué avec succès !");
      // Additional logic for updating notifications
    }
    setModalVisible(true);
    setIsLoading(false);
  };

  const fetchItemOwnerId = async (itemId, collectionName) => {
    try {
      const docRef = doc(getFirestore(), collectionName, itemId);
      const itemDoc = await getDoc(docRef);

      if (itemDoc.exists()) {
        const data = itemDoc.data();
        if (data && typeof data.userId === 'string') {
          return data.userId;
        } else {
          console.error('userId is not a string or is undefined', data);
          return null;
        }
      } else {
        console.error(`No document found with ID: ${itemId} in collection ${collectionName}`);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching document from ${collectionName}:`, error);
      return null;
    }
  };

  const fetchItemName = async (itemId, collectionName) => {
    try {
      const docRef = doc(getFirestore(), collectionName, itemId);
      const itemDoc = await getDoc(docRef);

      if (itemDoc.exists()) {
        const data = itemDoc.data();
        if (data && typeof data.title === 'string') {
          return data.title;
        } else {
          console.error('Title is not a string or is undefined', data);
          return 'No Title';
        }
      } else {
        console.error(`No document found with ID: ${itemId} in collection ${collectionName}`);
        return 'No Title';
      }
    } catch (error) {
      console.error(`Error fetching document from ${collectionName}:`, error);
      return 'Error fetching title';
    }
  };

  const updateNotifications = async (clientId, couturierId, itemName) => {
    try {
      const orderDe = {
        userId: clientId,
        article: itemName,
        status: 'paid',
        timestamp: new Date(),
        type: 'clientOrders',
        itemId: itemid,
        itemType: itemtype 
      };

      let message = `Vous avez réglé le paiement de ${amount} ${currency} 💵 pour l'article ${itemName}.`;
      if (itemtype === 'commandes' || itemtype === 'patterns') {
        message += ' Votre commande est en cours de confection.';
      }

      await addDoc(collection(db, 'notifications'), {
        userId: clientId,
        message: message,
        status: 'paid',
        timestamp: new Date(),
        type: 'clientPay',
        itemId: itemid,
        name: 'Paiement par carte' 
      });
      await saveOrder(orderDe);

      const orderD = {
        couturierId: couturierId,
        article: itemName,
        status: 'paid',
        timestamp: new Date(),
        type: 'couturierOrders',
        itemId: itemid,
        itemType: itemtype 
      };

      await addDoc(collection(db, 'notifications'), {
        userId: couturierId,
        message: `Vous avez reçu un paiement de ${itemtype === 'commandes' ? totalPrice : couturierAmount} ${currency} 💵 pour l'article ${itemName}. Cliquez pour voir l'adresse de livraison.`,
        status: 'paid',
        timestamp: new Date(),
        type: 'couturierPay',
        itemId: itemid,
        name: 'Paiement par carte'
      });

      await saveOrder(orderD);
    } catch (error) {
      console.error('Error adding payment notifications:', error);
    }
  };

  // Affichage conditionnel du bouton de paiement en fonction du type d'article
  let paymentButtonLabel = '';
  if (itemtype === "tissus") {
    paymentButtonLabel = 'Payer';
  } else if (itemtype === 'accessoires') {
    paymentButtonLabel = 'Payer';
  } else {
    paymentButtonLabel = 'Payer';
  }

  // Define a new function to handle payment press based on the button label
  const handlePaymentPress = () => {
    if (itemtype === "tissus") {
      handleFabricPaymentPress();
    } else if (itemtype === "shoes") {
      handleShoesPaymentPress();
    }
    else if (itemtype === "accessoires" || itemType==='accessories') {
      handleAccessoryPaymentPress();
    } else if (itemtype === "patterns") {
      console.log("je suis dans la collection: ", itemtype)
      handlePatternPaymentPress();
    } else if (itemtype === "commandes") {
      handleOrderPaymentPress();
      console.log("je suis dans la collection: ", itemtype)
    } else {
      console.error('Unknown item type:', itemtype);
    }
  };
  

  return (
    <Container sx={styles.container}>
      <img src={stripeLogo} style={{ width: '150px', marginBottom: '20px' }} />
      <Box sx={styles.title}>
        <Typography variant="h5">Paiement par carte bancaire</Typography>
      </Box>
      <Typography variant="body1" sx={styles.amountText}>
        Total: {currency} {amount}
      </Typography>
      <TextField
          label="E-mail"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={styles.input}
      />
      <Box mt={2} mb={2} width="100%">
          <CardElement options={styles.cardField} />
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handlePaymentPress}
        disabled={isLoading}
        sx={styles.payButton}
      >
        {isLoading ? <CircularProgress size={24} /> : 'Payer'}
      </Button>

      <Modal open={isModalVisible} onClose={handleCloseModal}>
        <Box sx={styles.modalContent}>
          <Typography variant="h6" gutterBottom>
            {modalMessage}
          </Typography>
          <Button onClick={handleCloseModal} sx={styles.closeButton}>Fermer</Button>
        </Box>
      </Modal>
     
    </Container>
  );
};

export default StripeApp;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: "white",
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    marginTop: '24px',
  },
  title: {
    fontSize: '24px',
    marginBottom: '16px',
    textAlign: "center",
  },
  amountText: {
    fontSize: '20px',
    marginBottom: '16px',
    textAlign: "center",
  },
  input: {
    marginBottom: '16px',
  },
  cardField: {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
    hidePostalCode: true,
  },
  payButton: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '16px',
    textAlign: 'center',
  },
  closeButton: {
    marginTop: '16px',
  },
};
