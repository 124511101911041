import React, { useContext, useState, useEffect } from 'react';
import { Container, Typography, CircularProgress, Avatar, Button, Box, Badge, IconButton, AppBar, Toolbar } from '@mui/material';
import { Route, Routes, useNavigate, useLocation, Link } from 'react-router-dom';
import { collection, query, where, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { AuthContext } from '../features/authContext';
import ProfileScreen from './ProfileScreen';
import PublishScreen from './PublishScreen';
import NotificationsScreen from './NotificationsScreen';
import MessagesScreen from './MessagesScreen';
import CommandeClient from './CommandeClient';
import { logout } from '../firebase/userAuth';
import logo from '../assets/logobis.png';
import { UserCircle, List, PlusCircle, Bell, Mail } from 'lucide-react';
import ClientOrder from './ClientOrder';

const CouturierHomePage = () => {
  const { currentUser } = useContext(AuthContext);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'profiles', currentUser.uid);
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          setProfile(docSnapshot.data());
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [currentUser]);

  const handleLogout = async () => {
    const res = await logout();
    if (res.success) {
      navigate('/auth');
    }
  };

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container sx={{ padding: '2rem' }}>
      <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
        {profile?.logo ? (
          <Avatar src={profile.logo} sx={{ width: 120, height: 120, margin: '0 auto' }} />
        ) : (
          <Avatar sx={{ width: 120, height: 120, margin: '0 auto', bgcolor: 'gray' }}>
            <UserCircle />
          </Avatar>
        )}
        <Typography variant="h5" mt={2}>{profile?.Name}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/couturier-profile')}
          sx={{ mt: 2 }}
        >
          Voir mon profil
        </Button>
      </Box>

      <Button
        variant="contained"
        color="secondary"
        onClick={handleLogout}
        sx={{ width: '100%', mt: 4 }}
      >
        Se Déconnecter
      </Button>
    </Container>
  );
};

const CouturierTabNavigator = () => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentUser) return;

    const unsubscribe = onSnapshot(
      query(collection(db, 'notifications'), where('userId', '==', currentUser.uid)),
      (querySnapshot) => {
        const unreadNotifications = querySnapshot.docs.filter(doc => !doc.data().read).length;
        setNotificationCount(unreadNotifications);
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  return (
    <>
    <Container maxWidth="lg">
      <AppBar position="static">
        <Toolbar>
          <img src={logo} alt="Couturia Logo" style={{ height: '50px', marginRight: '1rem' }} />
          <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <IconButton component={Link} to="/notifications" color="inherit">
              <Badge badgeContent={notificationCount} color="error">
                <Bell />
              </Badge>
            </IconButton>
            <IconButton component={Link} to="/messages" color="inherit">
              <Badge badgeContent={unreadCount} color="error">
                <Mail />
              </Badge>
            </IconButton>
            <IconButton component={Link} to="/commandes" color="inherit">
              <List />
            </IconButton>
            <IconButton component={Link} to="/publish" color="inherit">
              <PlusCircle />
            </IconButton>
            <IconButton component={Link} to="/couturier-profile" color="inherit">
              <UserCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <CommandeClient/>
      <Routes>
        <Route path="/commandes" element={<CommandeClient />} />
        <Route path="/publish" element={<PublishScreen />} />
        <Route path="/notifications" element={<NotificationsScreen />} />
        <Route path="/messages" element={<MessagesScreen setUnreadCount={setUnreadCount} />} />
        <Route path="/couturier-profile" element={<ProfileScreen />} />
      </Routes>
    </Container>
    </>
  );
};

export default CouturierTabNavigator;
