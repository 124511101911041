import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

const MediaGallery = ({ post, handleMediaClick }) => {
  // Initialize selected media with the first image if available, otherwise null
  const [selectedMedia, setSelectedMedia] = useState(post.images ? post.images[0] : null);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      {/* Thumbnails */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '20px' }}>
        {post.images && post.images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index + 1}`}
            style={{
              width: '60px',
              height: '60px',
              marginBottom: '10px',
              cursor: 'pointer',
              border: selectedMedia === image ? '2px solid blue' : 'none'
            }}
            onClick={() => setSelectedMedia(image)}
          />
        ))}
      </Box>

      {/* Main Media Display */}
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {selectedMedia ? (
          <img
            src={selectedMedia}
            alt="Selected Media"
            style={{ width: '40%', height: 'auto', cursor: 'pointer' }}
            onClick={() => handleMediaClick(selectedMedia)}
          />
        ) : post.video ? (
          <video
            src={post.video}
            style={{ width: '40%', height: 'auto', cursor: 'pointer' }}
            controls
            onClick={() => handleMediaClick(post.video)}
          />
        ) : (
          <Typography variant="body1">No media available</Typography>
        )}
      </Box>
    </Box>
  );
};

export default MediaGallery;