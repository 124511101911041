import React, { useContext, useState } from 'react';
import { TextField, Button, CircularProgress, Typography, Container, Box, Link as MuiLink, AppBar, Toolbar, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../features/authContext';
import { registerWithEmailAndPassword, isValidEmail } from '../firebase/userAuth';
import LogoImage from '../assets/logobis.png';
import { Link } from 'react-router-dom';

const CouturierRegistration = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const role = 'couturier'; // Rôle défini à couturier

  const { setCurrentUser, setIsLoggedIn, setIsProfileComplete } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      alert('Les mots de passe ne correspondent pas.');
      return;
    }

    if (!isValidEmail(email)) {
      alert('Veuillez entrer un email valide.');
      return;
    }

    setLoading(true);
    const res = await registerWithEmailAndPassword(name.toLowerCase(), email, password, role);
    if (res.success) {
      alert('Un email de vérification a été envoyé à votre adresse. Veuillez vérifier votre email pour confirmer votre compte.');
      setCurrentUser(res.user);
      setIsLoggedIn(true);
      setIsProfileComplete(false);
      navigate('/couturier-profile'); // Redirect couturier to couturier completion page
    } else {
      alert(res.error); // Show registration error if any
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="sm" marginTop="50px">
      <AppBar position="static" sx={{ marginBottom: '1rem' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img src={LogoImage} alt="Logo" style={{ height: 50 }} />
          </IconButton>
          <Typography variant="h6">Couturia</Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
        <Typography variant="h4" gutterBottom>Bienvenue chez COUTURIA!</Typography>
        <Typography variant="h6" color="textSecondary">Inscription Couturier</Typography>
      </Box>

      <Box component="form" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
        <TextField
          label="Nom d'utilisateur"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
        <TextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          fullWidth
        />
        <TextField
          label="Mot de passe"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          fullWidth
        />
        <TextField
          label="Confirmer le mot de passe"
          variant="outlined"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleRegister}
          fullWidth
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'S’inscrire'}
        </Button>
      </Box>

      <Box sx={{ textAlign: 'center', marginTop: 2 }}>
        <Typography>
          Déjà inscrit?{' '}
          <Link to="/auth" style={{ color: 'green', textDecoration: 'underline' }}>
            Se connecter
          </Link>
        </Typography>
      </Box>

      <Box sx={{ textAlign: 'center', marginTop: 2 }}>
        <Typography variant="body2">
          En vous inscrivant, vous acceptez notre{' '}
          <MuiLink href="https://www.privacypolicies.com/live/1fe18701-bf8d-450d-bae9-dc94e02193f1" target="_blank" rel="noopener">
            politique de confidentialité
          </MuiLink>.
        </Typography>
      </Box>
    </Container>
  );
};

export default CouturierRegistration;
