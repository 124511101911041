import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, Box, Button, Modal, TextField, Select, MenuItem, Snackbar, IconButton, Rating, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { CartContext } from '../features/cartContext'; 
import { AuthContext } from '../features/authContext'; 
import { collection, query, where, getDocs, addDoc} from 'firebase/firestore';
import { db } from '../firebase/firebase'; 
import Decimal from 'decimal.js';
import {Edit} from 'lucide-react';
import MediaGallery from './MediaGallery';

const SizeGuide = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Guide des tailles</h1>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <h2>Taille Abaya</h2>
          <p>52</p>
          <p>54</p>
          <p>56</p>
          <p>58</p>
          <p>60</p>
          <p>62</p>
        </div>
        <div style={{ marginLeft: '50px' }}>
          <h2>Taille en pantalon</h2>
          <p>Du 36 au 42</p>
          <p>Du 38 au 44</p>
          <p>Du 38 au 44</p>
          <p>Du 38 au 46</p>
          <p>Du 38 au 46</p>
          <p>Du 40 au 48</p>
        </div>
        <div style={{ marginLeft: '50px' }}>
          <h2>Taille en cm</h2>
          <p>Moins d'1m55</p>
          <p>Entre 1m56 et 1m62</p>
          <p>Entre 1m63 et 1m67</p>
          <p>Entre 1m68 et 1m72</p>
          <p>Entre 1m73 et 1m80</p>
          <p>Plus d'1m80</p>
        </div>
      </div>
    </div>
  );
};

const DetailScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { category, newPrice, post } = location.state || {};
  const { addToCart, cartItems } = useContext(CartContext);
  const { currentUser } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [currencyError, setCurrencyError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [yardModalVisible, setYardModalVisible] = useState(false);
  const [selectedYards, setSelectedYards] = useState(1);
  const [currentPrice, setCurrentPrice] = useState(new Decimal(newPrice ?? (post?.price ?? 0)));
  const [userIdError, setUserIdError] = useState(false);
  const [userIdErrorMessage, setUserIdErrorMessage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [volume, setVolume] = useState(1.0);
  const [userDeliveryAddress, setUserDeliveryAddress] = useState(null);
  const [relevantDeliveryOptions, setRelevantDeliveryOptions] = useState([]);
  const [selectedSize, setSelectedSize] = useState(post.type === 'shoes' ? post.minSize : null);
  const [sizeModalVisible, setSizeModalVisible] = useState(false);
  const [rating, setRating] = useState(null);

  // State for image/video modal
  const [enlargedMedia, setEnlargedMedia] = useState(null);
  const [isEnlargedModalVisible, setEnlargedModalVisible] = useState(false);
  const [selectedPantsSize, setSelectedPantsSize] = useState('');
  const [sizeError, setSizeError] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePantsSizeChange = (event) => {
    setSelectedPantsSize(event.target.value);
  };

  const fetchAverageRating = async (itemId) => {
    const ratingsRef = collection(db, 'ratings');
    const q = query(ratingsRef, where('itemId', '==', itemId));
    const querySnapshot = await getDocs(q);
  
    const ratings = querySnapshot.docs.map(doc => doc.data().rating);
    const averageRating = ratings.reduce((acc, rating) => acc + rating, 0) / ratings.length;
  
    return averageRating;
  };
  
  // Example usage
  useEffect(() => {
    const loadAverageRating = async () => {
      const avgRating = await fetchAverageRating(post.id);
      setRating(avgRating);
    };
  
    loadAverageRating();
  }, [post.id]);

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    setSizeError(false);  // Réinitialise l'erreur de taille
    setSizeModalVisible(false);
  };
  

  useEffect(() => {
    const price = new Decimal(newPrice ?? post.price).times(selectedYards);
    setCurrentPrice(price);
  }, [selectedYards, post.price, newPrice]);

  useEffect(() => {
      const fetchUserAddress = async () => {
        try {
          const deliveryAddressesRef = collection(db, 'deliveryAddresses');
          const q = query(deliveryAddressesRef, where('userId', '==', currentUser.uid));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const deliveryAddressDoc = querySnapshot.docs[0];
            const deliveryAddressData = deliveryAddressDoc.data();
            setUserDeliveryAddress(deliveryAddressData);
          } else {
            console.log('No delivery address found for this item!');
          }
        } catch (error) {
          console.error('Error fetching user delivery address:', error);
        }
      };

      fetchUserAddress();
    
  }, [post.id]);

  useEffect(() => {
    const fetchDeliveryOptions = async () => {
      if (userDeliveryAddress) {
        try {
          const deliveryOptionsRef = collection(db, 'deliveryOptions');
          const q = query(deliveryOptionsRef, where('articleId', '==', post.id));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const deliveryOptionsDocs = querySnapshot.docs;
            const options = [];

            deliveryOptionsDocs.forEach(doc => {
              const data = doc.data();
              if (data.duration) {
                if (data.method === 'National' && data.country === userDeliveryAddress.country) {
                  options.push(data);
                }
                if (data.method === 'Region' && data.region === userDeliveryAddress.region) {
                  options.push(data);
                }
                if (data.method === 'International' && data.country !== userDeliveryAddress.country) {
                  options.push(data);
                }
              }
            });

            setRelevantDeliveryOptions(options);
          } else {
            console.log('No delivery options found for this product!');
          }
        } catch (error) {
          console.error('Error fetching delivery options:', error);
        }
      }
    };

    fetchDeliveryOptions();
  }, [post, userDeliveryAddress, category]);

  const handleAddToCart = async () => {
    if (post.type === 'readytowear' && !selectedSize) {
      setSizeError(true);
      return;
    }
    setSizeError(false);
  
    if (cartItems.length > 0) {
      if (cartItems[0].currency !== post.currency) {
        setCurrencyError(true);
        return;
      }
  
      const existingType = cartItems[0].type;
      const newItemType = post?.type;
      if (
        (existingType === 'tissus' && newItemType !== 'tissus') ||
        (existingType !== 'tissus' && newItemType === 'tissus') ||
        (existingType === 'readytowear' && newItemType !== 'readytowear') ||
        (existingType !== 'readytowear' && newItemType === 'readytowear') ||
        (existingType === 'shoes' && newItemType !== 'shoes') ||
        (existingType !== 'shoes' && newItemType === 'shoes') ||
        (existingType === 'accessoires' && newItemType !== 'accessoires') ||
        (existingType !== 'accessoires' && newItemType === 'accessoires')
      ) {
        setTypeError(true);
        return;
      }
  
      const existingUserId = cartItems[0].userId;
      if (existingUserId !== post.userId) {
        setUserIdErrorMessage('Veuillez choisir les produits du même vendeur pour votre panier.');
        setUserIdError(true);
        return;
      }
    }
  
    const itemToAdd = {
      ...post,
      selectedYards,
      currentPrice: currentPrice.toNumber(),
      ...(post?.type === 'shoes' && { selectedSize }),
      ...(post.type === 'readytowear' && { selectedSize, selectedPantsSize }),
      rating,
    };
  
    await addToCart(itemToAdd);
    setVisible(true);
  
    setTimeout(() => {
      setVisible(false);
      navigate('/cart', { state: { selectedYards } });
    }, 1000);
  
    // Store the rating in Firestore
    if (rating !== null) {
      try {
        await addDoc(collection(db, 'ratings'), {
          itemId: post.id,
          rating: rating,
          timestamp: new Date(),
        });
        console.log('Rating added successfully');
      } catch (error) {
        console.error('Error adding rating: ', error);
      }
    }
  };

  const handleMediaClick = (media) => {
    setEnlargedMedia(media);
    setEnlargedModalVisible(true);
  };

  const handleCloseEnlargedModal = () => {
    setEnlargedModalVisible(false);
  };

  const handleSizeGuideClick = () => {
    console.log('Toggling size modal', sizeModalVisible);
    setSizeModalVisible(true);  // Affiche la modal
  };

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
    // Here you can add logic to save the rating to the database if needed
  };

  return (
    <Box
    sx={{
      backgroundImage: 'url("https://www.transparenttextures.com/patterns/45-degree-fabric-light.png")',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      minHeight: '100vh',
      py: 4
    }}
  >
    <Container maxWidth="md">
      <Typography variant="h4" align="center" gutterBottom>
        Détails du produit
      </Typography>

      <Box>
        <Typography variant="h5">{post.title}</Typography>
        {post.paymentType === 'AtDelivery' && (
          <Typography variant="body2" sx={{ color: 'green' }}>
            Le paiement à la livraison est disponible pour ce produit
          </Typography>
        )}
        {post.paymentType === 'AfterDelivery' && (
          <Typography variant="body2" sx={{ color: 'blue' }}>
            Le paiement après essai est disponible pour ce produit
          </Typography>
        )}
      
        <Typography variant="body1" gutterBottom>
          {post.description}
        </Typography>

        {userDeliveryAddress && relevantDeliveryOptions.length > 0 && (
        <Box sx={{ marginTop: '16px' }}>
          <div>
            {relevantDeliveryOptions.map((item, index) => (
              <div key={index} style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                <Typography variant="body2" sx={{ color: 'red' }}>
                  Date de livraison maximale estimée : {item.duration} jours
                </Typography>
              </div>
            ))}
          </div>
        </Box>
      )}
        
        {post?.type === 'readytowear' && (
                  <Button variant="contained" color="primary" onClick={handleSizeGuideClick} sx={{ marginBottom: '16px' }}>
                    Guide des tailles
                  </Button>
                )}
        
        {/* Modal pour le guide des tailles */}
        <Modal open={sizeModalVisible} onClose={() => setSizeModalVisible(false)}>
          <Box sx={{ maxWidth: '100vw', marginTop: '5%', marginLeft: '10%', marginRight: '10%', backgroundColor: '#FFF', padding: '1rem' }}>
            {/* <Typography variant="h5">Sélectionnez la taille:</Typography> */}
            <SizeGuide />
            <Button onClick={() => setSizeModalVisible(false)}>Fermer</Button>
          </Box>
        </Modal>
        {post.type === 'readytowear' && (
          <>
          {sizeError && (
                  <Snackbar
                    open={sizeError}
                    autoHideDuration={6000}
                    onClose={() => setSizeError(false)}
                    message="Veuillez sélectionner une taille avant d'ajouter au panier."
                  />
                )}
            {post.sizes && post.sizes.length > 0 && (
          <Box display="flex" alignItems="center" marginTop={2}>
            <Typography variant="body1" marginRight={2}>
              Sélectionnez la taille:
            </Typography>
            <Select
              value={selectedSize}
              onChange={(e) => setSelectedSize(e.target.value)}
              sx={{ minWidth: 120 }}
              error={sizeError && !selectedSize} // Ajoutez cette ligne pour indiquer l'erreur sur le champ
            >
              {post.sizes.map((size, index) => (
                <MenuItem key={index} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}

        {post.pantsSizes && post.pantsSizes.length > 0 && (
          <Box display="flex" alignItems="center" marginTop={2}>
            <Typography variant="body1" marginRight={2}>
              Sélectionnez la taille pantalon:
            </Typography>
            <Select
              value={selectedPantsSize}
              onChange={(e) => setSelectedPantsSize(e.target.value)}
              sx={{ minWidth: 120 }}
              error={sizeError && !selectedPantsSize} // Ajoutez cette ligne pour indiquer l'erreur sur le champ
            >
              {post.pantsSizes.map((size, index) => (
                <MenuItem key={index} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
          </>
        )}

        {/* Media Gallery */}
       <MediaGallery post={post} handleMediaClick={handleMediaClick} />

        <Typography variant="h6">Prix: {currentPrice.toFixed(2)} {post.currency}</Typography>
        
        {post.type === 'tissus' && (
        <Box display="flex" alignItems="center" marginTop={2}>
          <Typography variant="body1" marginRight={2}>
            Sélectionnez le nombre de yards:
            <Box display="flex" alignItems="center" marginLeft={2}>
                <Button onClick={() => setYardModalVisible(true)} variant="outlined" sx={{ display: 'flex', alignItems: 'center' }}>
                  {selectedYards} yard(s)
                  
                </Button>
              </Box>
            <Edit style={{ marginLeft: 8 }} />
          </Typography>
          
          <TextField
            type="number"
            value={selectedYards}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10);
              if (value <= post.longueur) {
                setSelectedYards(value);
              }
            }}
            inputProps={{ min: 1, max: post.longueur }}
            sx={{ maxWidth: 100 }}
          />
          <Typography variant="body1" marginLeft={2}>
            Prix total: {currentPrice.toFixed(2)} {post.currency}
          </Typography>
          {post.gender && (
            <Typography variant="body1" marginLeft={2}>
              Genre: {post.gender}
            </Typography>
          )}
          {post.ageGroup && (
            <Typography variant="body1" marginLeft={2}>
              Âge: {post.ageGroup}
            </Typography>
          )}
          <Typography variant="body1" marginLeft={2}>
            Longueur maximale: {post.longueur} yard(s)
          </Typography>
          {post.longueur && post.longueur > 0 && post.tissusTypes[0] !== 'OnePiece' && (
            <>
              <Typography variant="body1" marginLeft={2}>
                Prix par yard: {post.price} {post.currency}
              </Typography>
             
            </>
          )}
        </Box>
      )}

        {post.type === 'shoes' && (
          <Box display="flex" alignItems="center" marginTop={2}>
            <Typography variant="body1" marginRight={2}>
              Sélectionnez la taille:
            </Typography>
            <Select
              value={selectedSize}
              onChange={(e) => handleSizeChange(e.target.value)}
              sx={{ minWidth: 120 }}
            >
              {Array.from({ length: parseInt(post.maxSize) - parseInt(post.minSize) + 1 }, (_, i) => parseInt(post.minSize) + i).map(size => (
              <MenuItem
                key={size}
                value={size}
                onClick={() => handleSizeChange(size)}
                sx={{ padding: '8px 16px', cursor: 'pointer' }} // Equivalent to styles.dropdownItem
              >
                <Typography variant="body1" sx={{ color: 'black' }}>
                  {size}
                </Typography>
              </MenuItem>
            ))}

            </Select>
          </Box>
        )}

        <Box display="flex" justifyContent="space-between" marginTop={2}>
          <Button variant="contained" color="primary" onClick={handleAddToCart}>
            Ajouter au panier
          </Button>
          <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
            Retour
          </Button>
        </Box>

        {/* Customer Reviews Section */}
      <Box display="flex" flexDirection="column" alignItems="center" marginTop={4}>
        <Typography variant="h6">Avis des clients:</Typography>
        <Rating
          name="average-rating"
          value={rating}
          precision={0.5}
          readOnly
        />
      </Box>

        <Box>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'black',
            color: 'white',
            '&:hover': { backgroundColor: '#333' },
            
          }}
          onClick={handleOpen}
        >
          Ecrire un avis
        </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Comment évalueriez-vous cet article?</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center" marginTop={2}>
            <Typography variant="body1">Veuillez évaluer cet article:</Typography>
            <Rating
              name="product-rating"
              value={rating}
              onChange={handleRatingChange}
              precision={0.5}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fermer
          </Button>
          <Button onClick={handleClose} color="primary">
            Soumettre
          </Button>
        </DialogActions>
      </Dialog>
    </Box>

        {/* Error Messages */}
        {currencyError && (
          <Snackbar
            open={currencyError}
            autoHideDuration={6000}
            onClose={() => setCurrencyError(false)}
            message="Vous ne pouvez ajouter des articles de devises différentes à votre panier."
          />
        )}
        {typeError && (
          <Snackbar
            open={typeError}
            autoHideDuration={6000}
            onClose={() => setTypeError(false)}
            message="Vous ne pouvez pas mélanger différents types d'articles dans votre panier."
          />
        )}
        {userIdError && (
          <Snackbar
            open={userIdError}
            autoHideDuration={6000}
            onClose={() => setUserIdError(false)}
            message={userIdErrorMessage}
          />
        )}
      </Box>

      {/* Agrandir Média Modal */}
      <Modal
        open={isEnlargedModalVisible}
        onClose={handleCloseEnlargedModal}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ position: 'relative', maxWidth: '90%', maxHeight: '90%' }}>
          <IconButton
            onClick={handleCloseEnlargedModal}
            sx={{ position: 'absolute', top: '10px', right: '10px', color: 'white' }}
          >
            X {/* Remplacez par une icône de fermeture si souhaité */}
          </IconButton>
          {enlargedMedia && (
            <>
              {enlargedMedia.endsWith('.mp4') ? (
                <video src={enlargedMedia} controls style={{ maxWidth: '100%', maxHeight: '100%' }} />
              ) : (
                <img src={enlargedMedia} alt="Enlarged Media" style={{ maxWidth: '100%', maxHeight: '100%' }} />
              )}
            </>
          )}
        </Box>
      </Modal>
    </Container>
    </Box>
  );
};

export default DetailScreen;
export { SizeGuide };