import React, { useState, useContext, useRef, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, Grid, IconButton } from '@mui/material';
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from '../firebase/firebase';
import { AuthContext } from '../features/authContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircleArrowLeft } from 'lucide-react';

const MesureScreen = () => {
  const [measurements, setMeasurements] = useState({
    epaule: '',
    poitrine: '',
    taille: '',
    longueurEntiere: '',
    longueurManche: '',
    tourDeManche: '',
    masse: '',
    cou: '',
    longueurPantalon: '',
    ceinture: '',
    cuisse: '',
  });

  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { gender } = location.state || { gender: 'Femme' };

  const refs = {
    epaule: useRef(null),
    poitrine: useRef(null),
    taille: useRef(null),
    longueurEntiere: useRef(null),
    longueurManche: useRef(null),
    tourDeManche: useRef(null),
    masse: useRef(null),
    cou: useRef(null),
    longueurPantalon: useRef(null),
    ceinture: useRef(null),
    cuisse: useRef(null),
  };

  useEffect(() => {
    const fetchMeasurements = async () => {
      if (currentUser && currentUser.uid) {
        const docRef = doc(db, 'mesures', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setMeasurements(docSnap.data());
        }
      }
    };

    fetchMeasurements();
  }, [currentUser]);

  const handleSaveMeasurements = async () => {
    try {
      const mesureRef = doc(db, 'mesures', currentUser.uid);
      await setDoc(mesureRef, { ...measurements, userId: currentUser.uid });
      console.log('Measurements saved:', measurements);
      navigate('/client-main');
    } catch (error) {
      console.error('Erreur lors de la sauvegarde des mesures :', error);
    }
  };

  const handleNextField = (nextRef) => {
    if (nextRef.current) {
      nextRef.current.focus();
    }
  };

  return (
    <Container>
      <IconButton onClick={() => navigate(-1)} style={styles.backButton}>
        <CircleArrowLeft/>
      </IconButton>
      <Box my={4}>
        <Typography variant="h4" align="center">Veuillez renseigner vos mesures</Typography>
        <Grid container spacing={2}>
          {gender === 'Femme' ? (
            <>
              <Grid item xs={12}>
                <TextField
                  inputRef={refs.epaule}
                  label="Épaule (cm)"
                  variant="outlined"
                  fullWidth
                  value={measurements.epaule}
                  onChange={(e) => setMeasurements({ ...measurements, epaule: e.target.value })}
                  onKeyPress={(e) => e.key === 'Enter' && handleNextField(refs.poitrine)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={refs.poitrine}
                  label="Poitrine (cm)"
                  variant="outlined"
                  fullWidth
                  value={measurements.poitrine}
                  onChange={(e) => setMeasurements({ ...measurements, poitrine: e.target.value })}
                  onKeyPress={(e) => e.key === 'Enter' && handleNextField(refs.taille)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={refs.taille}
                  label="Taille (cm)"
                  variant="outlined"
                  fullWidth
                  value={measurements.taille}
                  onChange={(e) => setMeasurements({ ...measurements, taille: e.target.value })}
                  onKeyPress={(e) => e.key === 'Enter' && handleNextField(refs.longueurEntiere)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={refs.longueurEntiere}
                  label="Longueur entière (cm)"
                  variant="outlined"
                  fullWidth
                  value={measurements.longueurEntiere}
                  onChange={(e) => setMeasurements({ ...measurements, longueurEntiere: e.target.value })}
                  onKeyPress={(e) => e.key === 'Enter' && handleNextField(refs.longueurManche)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={refs.longueurManche}
                  label="Longueur de manche (cm)"
                  variant="outlined"
                  fullWidth
                  value={measurements.longueurManche}
                  onChange={(e) => setMeasurements({ ...measurements, longueurManche: e.target.value })}
                  onKeyPress={(e) => e.key === 'Enter' && handleNextField(refs.tourDeManche)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={refs.tourDeManche}
                  label="Tour de manche (cm)"
                  variant="outlined"
                  fullWidth
                  value={measurements.tourDeManche}
                  onChange={(e) => setMeasurements({ ...measurements, tourDeManche: e.target.value })}
                  onKeyPress={(e) => e.key === 'Enter' && handleSaveMeasurements()}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <TextField
                  inputRef={refs.epaule}
                  label="Épaule (cm)"
                  variant="outlined"
                  fullWidth
                  value={measurements.epaule}
                  onChange={(e) => setMeasurements({ ...measurements, epaule: e.target.value })}
                  onKeyPress={(e) => e.key === 'Enter' && handleNextField(refs.masse)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={refs.masse}
                  label="Masse (kg)"
                  variant="outlined"
                  fullWidth
                  value={measurements.masse}
                  onChange={(e) => setMeasurements({ ...measurements, masse: e.target.value })}
                  onKeyPress={(e) => e.key === 'Enter' && handleNextField(refs.cou)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={refs.cou}
                  label="Cou (cm)"
                  variant="outlined"
                  fullWidth
                  value={measurements.cou}
                  onChange={(e) => setMeasurements({ ...measurements, cou: e.target.value })}
                  onKeyPress={(e) => e.key === 'Enter' && handleNextField(refs.longueurManche)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={refs.longueurManche}
                  label="Longueur de manche (cm)"
                  variant="outlined"
                  fullWidth
                  value={measurements.longueurManche}
                  onChange={(e) => setMeasurements({ ...measurements, longueurManche: e.target.value })}
                  onKeyPress={(e) => e.key === 'Enter' && handleNextField(refs.longueurPantalon)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={refs.longueurPantalon}
                  label="Longueur de pantalon (cm)"
                  variant="outlined"
                  fullWidth
                  value={measurements.longueurPantalon}
                  onChange={(e) => setMeasurements({ ...measurements, longueurPantalon: e.target.value })}
                  onKeyPress={(e) => e.key === 'Enter' && handleNextField(refs.ceinture)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={refs.ceinture}
                  label="Ceinture (cm)"
                  variant="outlined"
                  fullWidth
                  value={measurements.ceinture}
                  onChange={(e) => setMeasurements({ ...measurements, ceinture: e.target.value })}
                  onKeyPress={(e) => e.key === 'Enter' && handleNextField(refs.cuisse)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={refs.cuisse}
                  label="Cuisse (cm)"
                  variant="outlined"
                  fullWidth
                  value={measurements.cuisse}
                  onChange={(e) => setMeasurements({ ...measurements, cuisse: e.target.value })}
                  onKeyPress={(e) => e.key === 'Enter' && handleSaveMeasurements()}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveMeasurements}
          style={{ marginTop: 24 }}
        >
          Sauvegarder les mesures
        </Button>
      </Box>
    </Container>
  );
};

const styles = {
  backButton: {
    position: 'absolute',
    left: 10,
    top: 10,
  }
}

export default MesureScreen;
