import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TrashIcon } from 'lucide-react';
import '../styles/OrderDetail.css';

const OrderDetail = () => {
  const { id } = useParams();
  // Vous devriez obtenir les détails de la commande en utilisant l'id. Cela pourrait être par un appel API.
  // Pour cet exemple, supposons que les détails sont passés via props ou contexte.

  const orderDetails = {
    images: [],
    title: "Sample Title",
    description: "Sample Description",
    fabricType: "Cotton",
  }; // Remplacez ceci par la récupération réelle des détails de la commande.

  const [imageList, setImageList] = useState(orderDetails.images);

  const handleDeleteImage = (index) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette image?')) {
      const updatedImages = imageList.filter((_, i) => i !== index);
      setImageList(updatedImages);
    }
  };

  return (
    <div className="container">
      <div className="content-container">
        <h1 className="title">{orderDetails.title}</h1>
        <h2 className="sub-title">{`Type de tissu: ${orderDetails.fabricType}`}</h2>
        <p className="description">{orderDetails.description}</p>
        <div className="image-container">
          {imageList.length > 0 ? (
            imageList.map((image, index) => (
              <div key={index} className="image-wrapper">
                <img src={image} alt={`Order image ${index + 1}`} className="image" />
                <div className="delete-icon" onClick={() => handleDeleteImage(index)}>
                  <TrashIcon size={24} color="red" />
                </div>
              </div>
            ))
          ) : (
            <p>Aucune image disponible</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
