import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, TextField, Button, Card, CardContent, MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import { CircleArrowLeft } from 'lucide-react';
import { AuthContext } from '../features/authContext';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase/firebase';
import { addDoc, collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const ProductListScreen = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([]);
  const [fabricType, setFabricType] = useState('getzner');

  const handleImageUpload = async (event) => {
    const files = event.target.files;
    if (files.length + images.length > 5) {
      alert('You can only select up to 5 images');
      return;
    }
    const newImages = [];
    for (let file of files) {
      const storageRef = ref(getStorage(), `users/${currentUser.uid}/pattern_images/${Date.now()}_${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      newImages.push(downloadURL);
    }
    setImages([...images, ...newImages]);
  };

  const publishPattern = async () => {
    if (!title || !description || images.length === 0) {
      alert('Veuillez remplir tous les champs et sélectionner au moins une image.');
      return;
    }
    try {
      const docRef = await addDoc(collection(db, 'commandes'), {
        title,
        description,
        fabricType,
        images,
        userId: currentUser.uid,
      });
      console.log('Pattern added with ID: ', docRef.id);
      alert('Votre produit a été publié avec succès ! Vous pouvez le visualiser dans l\'onglet Mes Publications.');
      setTitle('');
      setDescription('');
      setImages([]);
      navigate('/client-main');
    } catch (error) {
      console.error('Error adding pattern: ', error);
    }
  };

  return (
    <Container style={styles.container}>
      <IconButton onClick={() => navigate(-1)} style={styles.backButton}>
        <CircleArrowLeft/>
      </IconButton>
      <Typography variant="h5" style={styles.appBarText}>
        Ajouter votre modèle et nos couturiers se chargent du reste
      </Typography>
      <form style={styles.formContainer}>
        <Button variant="contained" component="label" style={styles.imagePicker}>
          + Ajouter jusqu'à 5 photos
          <input type="file" multiple hidden onChange={handleImageUpload} />
        </Button>
        {images.length > 0 && (
          <div style={styles.imageContainer}>
            {images.map((url, index) => (
              <img key={index} src={url} alt={`img-${index}`} style={styles.image} />
            ))}
          </div>
        )}
        <Card style={styles.card}>
          <CardContent>
            <TextField
              label="Titre"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              style={styles.textInput}
            />
          </CardContent>
        </Card>
        <Card style={styles.card}>
          <CardContent>
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              style={styles.textInput}
            />
          </CardContent>
        </Card>
        <Card style={styles.card}>
          <CardContent>
            <FormControl fullWidth style={styles.formControl}>
              <InputLabel>Type de tissu</InputLabel>
              <Select
                value={fabricType}
                onChange={(e) => setFabricType(e.target.value)}
              >
                <MenuItem value="getzner">Getzner</MenuItem>
                <MenuItem value="bazin">Bazin</MenuItem>
                <MenuItem value="brodé">Brodé</MenuItem>
                <MenuItem value="gagnila">Gagnila</MenuItem>
                <MenuItem value="super 100">Super 100</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
        </Card>
        <Button variant="contained" color="primary" onClick={publishPattern} style={styles.publishButton}>
          Publier
        </Button>
      </form>
    </Container>
  );
};

const styles = {
  container: {
    backgroundColor: '#b6e0ff',
    padding: '16px',
    marginTop: 80,

  },
  backButton: {
    position: 'absolute',
    left: 10,
    top: 10,
  },
  appBarText: {
    color: '#6200ee',
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 16,
    marginTop: 40,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imagePicker: {
    marginBottom: 16,
  },
  imageContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 16,
  },
  image: {
    width: 100,
    height: 100,
    margin: 4,
  },
  card: {
    marginBottom: 16,
    width: '100%',
  },
  textInput: {
    marginTop: 8,
  },
  formControl: {
    marginTop: 8,
  },
  publishButton: {
    marginTop: 16,
  },
};

export default ProductListScreen;
