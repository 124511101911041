import React, { useState, useContext } from 'react';
import axios from 'axios';
import { storage } from '../firebase/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { updateDoc, doc } from 'firebase/firestore';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { AuthContext } from '../features/authContext';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase/firebase';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'; // Icône de pièce

const FabricToDesign = () => {
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [clothingPrompt, setClothingPrompt] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState('');
  const [age, setAge] = useState('');
  const [designUrl, setDesignUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [openCreditsModal, setOpenCreditsModal] = useState(false);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);

  const { currentUser, updateCredits } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleCloseCreditsModal = () => {
    setOpenCreditsModal(false);
  };

  const handlePurchaseCreditsRedirect = () => {
    navigate('/purchase-credits');
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      setOpen(true);
      return;
    }

    const credits = parseFloat(currentUser.credits);

    if (isNaN(credits) || credits <= 0) {
      setError("Vous n'avez pas assez de crédits. Veuillez acheter des crédits pour continuer.");
      setOpenCreditsModal(true);
      return;
    }

    const newCredits = Math.max(credits - 1, 0);

    setLoading(true);
    setError(null);
    setDesignUrl(null);
    setOpenLoadingModal(true);

    if (!imageFile) {
      setError('Veuillez sélectionner une image.');
      setLoading(false);
      setOpenLoadingModal(false);
      return;
    }

    try {
      // Téléchargement de l'image vers Firebase Storage
      const storageRef = ref(storage, `fabric_images/${imageFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, imageFile);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Progression du téléchargement
        },
        (error) => {
          setError("Échec du téléchargement de l'image.");
          setLoading(false);
          setOpenLoadingModal(false);
          console.error(error);
        },
        async () => {
          const fabricImageUrl = await getDownloadURL(uploadTask.snapshot.ref);

          const formData = new FormData();
          formData.append('email', 'dioprassoul@gmail.com');
          formData.append('password', 'Mawahibou95*');
          formData.append('fabric_image', fabricImageUrl);
          formData.append('clothing_prompt', clothingPrompt);
          formData.append('gender', gender);
          formData.append('country', country);
          formData.append('age', age);

          const response = await axios.post('https://thenewblack.ai/api/1.1/wf/fabric_to_design', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          setDesignUrl(response.data);

          if (currentUser) {
            await updateCredits(newCredits);
          }
          setOpenLoadingModal(false);
        }
      );
    } catch (err) {
      console.error(err);
      setOpenLoadingModal(false);
      setError('Échec de la génération du design. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLoginRedirect = () => {
    navigate('/auth');
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url("https://www.transparenttextures.com/patterns/white-linen.png")',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        py: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px',
        }}
      >
        <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
          Retour
        </Button>

        {/* Icône de crédits et nombre de crédits de l'utilisateur */}
        {currentUser && currentUser.credits === 0 && (
          <Button
            onClick={handlePurchaseCreditsRedirect}
            color="primary"
            variant="contained"
            sx={{ mt: 2 }}
          >
            Acheter des crédits
          </Button>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <MonetizationOnIcon sx={{ color: '#FFD700', mr: 1 }} /> {/* Icône de pièce */}
          <Typography variant="h6">
            {currentUser ? currentUser.credits : 0} Crédits {/* Affichage des crédits */}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ maxWidth: 600, mx: 'auto', mt: 5, p: 2, borderRadius: 2, boxShadow: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Créer un design avec un motif de tissu
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box mb={3}>
            <Button variant="contained" component="label" fullWidth>
              Choisir une image de tissu
              <input type="file" hidden onChange={handleImageChange} required />
            </Button>
          </Box>

          {imagePreview && (
            <Box mb={3} textAlign="center">
              <img
                src={imagePreview}
                alt="Aperçu du tissu"
                style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }}
              />
            </Box>
          )}

          <Box mb={3}>
            <TextField
              fullWidth
              label="Description du vêtement"
              variant="outlined"
              value={clothingPrompt}
              onChange={(e) => setClothingPrompt(e.target.value)}
              placeholder="Ex: robe"
              required
            />
          </Box>

          <Box mb={3}>
            <FormControl fullWidth>
              <InputLabel id="gender-label">Sexe du modèle</InputLabel>
              <Select
                labelId="gender-label"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                label="Sexe du modèle"
                required
              >
                <MenuItem value="man">Homme</MenuItem>
                <MenuItem value="woman">Femme</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box mb={3}>
            <TextField
              fullWidth
              label="Pays"
              variant="outlined"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              placeholder="Ex: Mali"
              required
            />
          </Box>

          <Box mb={3}>
            <TextField
              fullWidth
              label="Âge du modèle"
              variant="outlined"
              type="number"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              placeholder="Ex: 30"
              required
              inputProps={{ min: 20, max: 70 }}
            />
          </Box>

          <Box display="flex" justifyContent="center" position="relative">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Créer un design
              </Button>
            )}
          </Box>
        </form>

        {error && (
          <Box mt={3}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}

        {designUrl && (
          <Box mt={3} textAlign="center">
            <Typography variant="h6">Design généré:</Typography>
            <img src={designUrl} alt="Design généré" style={{ maxWidth: '100%', height: 'auto', marginTop: '10px' }} />
          </Box>
        )}

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Connexion requise</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Vous devez être connecté pour générer un design. Veuillez vous connecter pour continuer.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Annuler
            </Button>
            <Button onClick={handleLoginRedirect} color="primary" variant="contained">
              Se connecter
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openCreditsModal} onClose={handleCloseCreditsModal}>
          <DialogTitle>Crédits insuffisants</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Vous n'avez pas assez de crédits pour générer un design. Veuillez acheter des crédits pour continuer.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCreditsModal} color="primary">
              Annuler
            </Button>
            <Button onClick={handlePurchaseCreditsRedirect} color="primary" variant="contained">
              Acheter des crédits
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openLoadingModal} onClose={() => {}} disableBackdropClick>
          <DialogTitle>Veuillez patienter</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Le design est en cours de génération. Merci de patienter un moment.
            </DialogContentText>
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default FabricToDesign;
