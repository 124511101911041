import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Box, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';
import { AuthContext } from '../features/authContext';
import { db } from '../firebase/firebase';
import { collection, query, where, getDocs, doc as firestoreDoc, getDoc } from 'firebase/firestore';

const OrderScreen = () => {
  const { currentUser } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (!currentUser) return;

    const fetchRequestedQuotes = async () => {
      try {
        // Fetch notifications where the user has requested quotes
        const notificationsRef = collection(db, 'notifications');
        const q = query(notificationsRef, where('senderId', '==', currentUser.uid));
        const querySnapshot = await getDocs(q);

        // Extract order/item details from the notifications
        const orderPromises = querySnapshot.docs.map(async (notificationDoc) => {
          const notificationData = notificationDoc.data();
          const orderRef = firestoreDoc(db, 'patterns', notificationData.itemId); // Using itemId stored in the notification
          const orderDoc = await getDoc(orderRef);
          return { id: orderDoc.id, ...orderDoc.data() };
        });

        const ordersData = await Promise.all(orderPromises);
        setOrders(ordersData);
      } catch (error) {
        console.error('Error fetching requested quotes:', error);
      }
    };

    fetchRequestedQuotes();
  }, [currentUser]);

  return (
    <Container style={styles.container}>
      <List>
        {orders.map((order) => (
          <ListItem key={order.id} alignItems="flex-start">
            <ListItemAvatar>
              <Avatar variant="square" src={order.images[0]} style={styles.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  <Typography variant="body1">
                    Prix: {order.currency} {order.price}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Statut de la commande: en cours
                  </Typography>
                </>
              }
              secondary={
                <Typography variant="body2" color="textSecondary">
                  {order.description}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

const styles = {
  container: {
    padding: '16px',
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: '16px',
  },
};

export default OrderScreen;
