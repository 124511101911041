import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  Box,
  FormControl,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { addDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { auth, db } from '../firebase/firebase';
import { AuthContext } from '../features/authContext';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const Shoes = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [priceType, setPriceType] = useState('Fixe');
  const [paymentType, setPaymentType] = useState('BeforeDelivery');
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState('');
  const [progress, setProgress] = useState(0);
  const [currency, setCurrency] = useState('XOF');
  const [minSize, setMinSize] = useState('');
  const [maxSize, setMaxSize] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const priceTypeOptions = [
    { label: 'Fixe', value: 'Fixe' },
    { label: 'Négociable', value: 'Négociable' },
  ];

  const paymentOptions = [
    { label: 'Avant livraison', value: 'BeforeDelivery' },
    { label: 'A la livraison', value: 'AtDelivery' },
    { label: 'Après essai', value: 'AfterDelivery' },
  ];

  const currencyOptions = [
    { label: 'XOF', value: 'XOF' },
    { label: 'EUR', value: 'EUR' },
    { label: 'USD', value: 'USD' }
  ];

  useEffect(() => {
    // Intégrer une bibliothèque de sélection d'images pour le web si nécessaire
  }, []);

  const pickImage = (event) => {
    const files = Array.from(event.target.files);
    if (files.length + images.length > 5) {
      alert('Vous ne pouvez sélectionner que jusqu\'à 5 images');
      return;
    }
    setLoading(true);
    const imageUrls = files.map(file => URL.createObjectURL(file));
    setImages([...images, ...imageUrls]);
    setLoading(false);
  };

  const pickVideo = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const videoUrl = URL.createObjectURL(file);
      setVideo(videoUrl);
      await uploadVideo(file);
    }
  };

  const uploadVideo = async (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, `videos/${Date.now()}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress.toFixed());
      },
      (error) => {
        console.error('Upload failed:', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setVideo(downloadURL);
        });
      }
    );
  };

  const publishPattern = async () => {
    const storage = getStorage();
    if (!title || !description || !price) {
      alert('Veuillez remplir tous les champs et sélectionner au moins une image.');
      return;
    }
    if (images.length === 0 && !video) {
      alert('Veuillez sélectionner au moins une image ou une vidéo.');
      return;
    }
    setIsLoading(true);
    try {
      const imageUrls = await Promise.all(
        images.map(async (image, index) => {
          const response = await fetch(image);
          const blob = await response.blob();
          const storageRef = ref(storage, `pattern_images/${currentUser.uid}/${Date.now()}_${index}`);
          await uploadBytes(storageRef, blob);
          return await getDownloadURL(storageRef);
        })
      );

      const docRef = await addDoc(collection(db, 'shoes'), {
        title,
        description,
        price,
        priceType,
        paymentType,
        currency,
        video,
        images: imageUrls,
        userId: currentUser.uid,
        allowedUsers: isPrivate ? selectedUsers : [],
        minSize,
        maxSize,
        isPrivate,
        timestamp: new Date(),
        type: 'shoes'
      });

      setTitle('');
      setDescription('');
      setPrice('');
      setImages([]);
      setVideo('');
      setIsPrivate(false);
      setSelectedUsers([]);
      setMinSize('');
      setMaxSize('');
      navigate(`/delivery-options/${docRef.id}?currency=${currency}`);
    } catch (error) {
      console.error('Error adding pattern: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchUsers = async (queryText) => {
    setSearchQuery(queryText);
    if (queryText.trim() === '') {
      setSearchResults([]);
      return;
    }

    const q = query(collection(db, 'users'), where('name', '>=', queryText.toLowerCase()), where('name', '<=', queryText.toLowerCase() + '\uf8ff'));
    const querySnapshot = await getDocs(q);
    const results = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setSearchResults(results);
  };

  const toggleUserSelection = (userId) => {
    setSelectedUsers(prevSelectedUsers => {
      if (prevSelectedUsers.includes(userId)) {
        return prevSelectedUsers.filter(id => id !== userId);
      } else {
        return [...prevSelectedUsers, userId];
      }
    });
  };

  const removeImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  // Fonction de validation pour le champ de prix
  const validatePriceInput = (event) => {
    const text = event.target.value;
    // Autoriser uniquement les chiffres et le point
    const regex = /^[0-9.]*$/;
    if (regex.test(text)) {
      setPrice(text);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url("https://www.transparenttextures.com/patterns/white-linen.png")',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        py: 4
      }}
    >
        <Button onClick={() => navigate(-1)} leftIcon={<ArrowLeft />}>Retour</Button>
    <Container>
       <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 3 }}>
        Ajouter une chaussure
      </Typography>

      <Button
        variant="contained"
        component="label"
        onChange={pickImage}
      >
        Ajouter jusqu'à 5 photos
        <input type="file" accept="image/*" multiple hidden />
      </Button>

      {images.length > 0 && (
        <Grid container spacing={2}>
          {images.map((uri, index) => (
            <Grid item key={index}>
              <img src={uri} alt={`Preview ${index}`} style={{ width: 100, height: 100, marginRight: 8 }} />
              <Button onClick={() => removeImage(index)}>Supprimer</Button>
            </Grid>
          ))}
        </Grid>
      )}

      <Button
        variant="contained"
        component="label"
        onChange={pickVideo}
      >
        Sélectionner une vidéo
        <input type="file" accept="video/*" hidden />
      </Button>
      {progress > 0 && progress < 100 && (
        <Typography>Upload Progress: {progress}%</Typography>
      )}
      {video && (
        <div>
          <video src={video} controls width="300" />
          <Button onClick={() => setVideo('')}>Supprimer</Button>
        </div>
      )}

      <Card style={{ marginTop: 16 }}>
        <CardContent>
          <Typography variant="h6">Titre</Typography>
          <TextField
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </CardContent>
      </Card>

      <Card style={{ marginTop: 16 }}>
        <CardContent>
          <Typography variant="h6">Description</Typography>
          <TextField
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </CardContent>
      </Card>

      <Card style={{ marginTop: 16 }}>
        <CardContent>
          <Typography variant="h6">Prix</Typography>
          <TextField
            fullWidth
            value={price}
            onChange={validatePriceInput}
          />
        </CardContent>
      </Card>

      <Card style={{ marginTop: 16 }}>
        <CardContent>
          <Typography variant="h6">Devise</Typography>
          <FormControl fullWidth>
            <InputLabel>Devise</InputLabel>
            <Select
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            >
              {currencyOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
      </Card>

      <Card style={{ marginTop: 16 }}>
        <CardContent>
          <Typography variant="h6">Type de prix</Typography>
          <FormControl fullWidth>
            <InputLabel>Type de prix</InputLabel>
            <Select
              value={priceType}
              onChange={(e) => setPriceType(e.target.value)}
            >
              {priceTypeOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
      </Card>

      <Card style={{ marginTop: 16 }}>
        <CardContent>
          <Typography variant="h6">Type de paiement</Typography>
          <FormControl fullWidth>
            <InputLabel>Type de paiement</InputLabel>
            <Select
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value)}
            >
              {paymentOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
      </Card>

      <TextField
        label="Pointure minimum"
        value={minSize}
        onChange={(e) => setMinSize(e.target.value)}
        type="number"
        fullWidth
        style={{ marginTop: 16 }}
      />
      <TextField
        label="Pointure maximum"
        value={maxSize}
        onChange={(e) => setMaxSize(e.target.value)}
        type="number"
        fullWidth
        style={{ marginTop: 16 }}
      />

      {/* <Button
        variant="outlined"
        onClick={() => setModalVisible(true)}
        style={{ marginTop: 16 }}
      >
        {isPrivate ? "Privé" : "Rendre Privé?"}
      </Button> */}

      {selectedUsers.length > 0 && (
        <div style={{ marginTop: 16 }}>
          <Typography>Utilisateurs sélectionnés :</Typography>
          {selectedUsers.map((userId, index) => {
            const user = searchResults.find(u => u.id === userId);
            return user ? (
              <Typography key={index} style={{ padding: 4, borderBottom: '1px solid gray' }}>{user.name}</Typography>
            ) : null;
          })}
        </div>
      )}

      <div style={{ textAlign: 'center', marginTop: 16 }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={publishPattern}
          >
            Publier
          </Button>
        )}
      </div>

      <Dialog
        open={modalVisible}
        onClose={() => setModalVisible(false)}
      >
        <DialogTitle>Sélectionner les utilisateurs autorisés</DialogTitle>
        <DialogContent>
          <TextField
            label="Rechercher un utilisateur"
            fullWidth
            value={searchQuery}
            onChange={(e) => searchUsers(e.target.value)}
          />
          {searchResults.length > 0 && (
            <div style={{ maxHeight: 200, overflowY: 'auto' }}>
              {searchResults.map((user) => (
                <Button
                  key={user.id}
                  onClick={() => toggleUserSelection(user.id)}
                  style={{
                    display: 'block',
                    padding: 10,
                    backgroundColor: selectedUsers.includes(user.id) ? 'lightgray' : 'white',
                    borderBottom: '1px solid gray',
                    width: '100%',
                    textAlign: 'left'
                  }}
                >
                  {user.name}
                </Button>
              ))}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsPrivate(true);
              setModalVisible(false);
            }}
            color="primary"
          >
            Sauvegarder
          </Button>
          <Button
            onClick={() => setModalVisible(false)}
            color="default"
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
    </Box>
  );
};

export default Shoes;
