import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from './authContext';
import { db } from '../firebase/firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCart = async () => {
      if (!currentUser) {
        // Load from local storage if no user is logged in
        const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        setCartItems(storedCartItems);
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCartItems(userData.cart || []);
        } else {
          setCartItems([]);
        }
      } catch (error) {
        console.error('Failed to fetch cart:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCart();
  }, [currentUser]);

  const addToCart = async (item) => {
    if (!currentUser) {
      // Add item to local storage if no user is logged in
      const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      const existingItemIndex = storedCartItems.findIndex((cartItem) => cartItem.id === item.id);

      if (existingItemIndex >= 0) {
        storedCartItems[existingItemIndex].quantity += 1;
      } else {
        storedCartItems.push({ ...item, quantity: 1 });
      }

      localStorage.setItem('cartItems', JSON.stringify(storedCartItems));
      setCartItems(storedCartItems);
      return;
    }
    
    const userDocRef = doc(db, 'users', currentUser.uid);
    try {
      const userDoc = await getDoc(userDocRef);
      let cart = [];
      if (userDoc.exists()) {
        const userData = userDoc.data();
        cart = userData.cart || [];
      }
      
      const existingItemIndex = cart.findIndex((cartItem) => cartItem.id === item.id);
      
      if (existingItemIndex >= 0) {
        cart[existingItemIndex].quantity += 1;
      } else {
        cart.push({ ...item, quantity: 1 });
      }
      
      await setDoc(userDocRef, { cart }, { merge: true });
      setCartItems(cart);
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  const removeFromCart = async (itemId) => {
    if (!currentUser) {
      // Remove item from local storage if no user is logged in
      const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      const updatedCart = storedCartItems.filter((item) => item.id !== itemId);
      localStorage.setItem('cartItems', JSON.stringify(updatedCart));
      setCartItems(updatedCart);
      return;
    }

    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const updatedCart = userData.cart.filter((item) => item.id !== itemId);
        await updateDoc(userDocRef, { cart: updatedCart });
        setCartItems(updatedCart);
      }
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

  const updateQuantity = async (itemId, quantity) => {
    if (!currentUser || quantity < 1) return;
    
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const updatedCart = userData.cart.map((item) =>
          item.id === itemId ? { ...item, quantity } : item
        );
        await updateDoc(userDocRef, { cart: updatedCart });
        setCartItems(updatedCart);
      }
    } catch (error) {
      console.error('Error updating item quantity:', error);
    }
  };

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, updateQuantity, loading }}>
      {children}
    </CartContext.Provider>
  );
};
