import React, { useState, useContext, useEffect } from 'react';
import { Container, TextField, Button, Typography, Card, CardContent, MenuItem, Select, InputLabel, Box, FormControl, CircularProgress, Modal, List, ListItem, ListItemText } from '@mui/material';
import { addDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../features/authContext';
import { db } from '../firebase/firebase';
import { ArrowLeft } from 'lucide-react';

const Accessories = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [priceType, setPriceType] = useState('Fixe');
  const [paymentType, setPaymentType] = useState('BeforeDelivery');
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState('');
  const [currency, setCurrency] = useState('XOF');
  const [progress, setProgress] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const priceTypeOptions = [
    { label: 'Fixe', value: 'Fixe' },
    { label: 'Négociable', value: 'Négociable' },
  ];

  const paymentOptions = [
    { label: 'Avant livraison', value: 'BeforeDelivery' },
    { label: 'A la livraison', value: 'AtDelivery' },
    { label: 'Après essai', value: 'AfterDelivery' },
  ];

  const currencyOptions = [
    { label: 'XOF', value: 'XOF' },
    { label: 'EUR', value: 'EUR' },
    { label: 'USD', value: 'USD' }
  ];

  const handleImageUpload = async (e) => {
    const files = e.target.files;
    if (images.length + files.length > 5) {
      alert('Vous ne pouvez sélectionner que jusqu\'à 5 images');
      return;
    }
    const storage = getStorage();
    setIsLoading(true);
    const newImages = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const storageRef = ref(storage, `pattern_images/${currentUser.uid}/${Date.now()}_${i}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      newImages.push(downloadURL);
    }

    setImages([...images, ...newImages]);
    setIsLoading(false);
  };

  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const storageRef = ref(getStorage(), `videos/${Date.now()}`);
    const uploadTask = uploadBytes(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress.toFixed());
      },
      (error) => {
        console.error(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setVideo(downloadURL);
      }
    );
  };

  const publishPattern = async () => {
    if (!title || !description || !price || (images.length === 0 && !video)) {
      alert('Veuillez remplir tous les champs et sélectionner au moins une image ou une vidéo.');
      return;
    }

    setIsLoading(true);
    try {
      const docRef = await addDoc(collection(db, 'accessories'), {
        title,
        description,
        price,
        priceType,
        paymentType,
        currency,
        video,
        images,
        userId: currentUser.uid,
        allowedUsers: isPrivate ? selectedUsers : [],
        isPrivate,
        timestamp: new Date(),
        type: 'accessoires'
      });

      setTitle('');
      setDescription('');
      setPrice('');
      setImages([]);
      setIsPrivate(false);
      setSelectedUsers([]);

      navigate('/delivery-options', { state: { articleId: docRef.id, currency: currency } });
    } catch (error) {
      console.error('Error adding accessory: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchUsers = async (queryText) => {
    setSearchQuery(queryText);
    if (queryText.trim() === '') {
      setSearchResults([]);
      return;
    }

    const q = query(collection(db, 'users'), where('name', '>=', queryText.toLowerCase()), where('name', '<=', queryText.toLowerCase() + '\uf8ff'));
    const querySnapshot = await getDocs(q);
    const results = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setSearchResults(results);
  };

  const toggleUserSelection = (userId) => {
    setSelectedUsers(prevSelectedUsers => {
      if (prevSelectedUsers.includes(userId)) {
        return prevSelectedUsers.filter(id => id !== userId);
      } else {
        return [...prevSelectedUsers, userId];
      }
    });
  };

  const removeImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const validatePriceInput = (text) => {
    const regex = /^[0-9.]*$/;
    if (regex.test(text)) {
      setPrice(text);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url("https://www.transparenttextures.com/patterns/white-linen.png")',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        py: 4
      }}
    >
      <Button onClick={() => navigate(-1)} leftIcon={<ArrowLeft />}>Retour</Button>
    <Container>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 3 }}>Ajouter un accessoire</Typography>
      <Card sx={{ marginBottom: 3 }}>
        <CardContent>
          <Button
            variant="outlined"
            component="label"
            sx={{ marginBottom: 2 }}
          >
            + Ajouter jusqu'à 5 photos
            <input
              type="file"
              accept="image/*"
              multiple
              hidden
              onChange={handleImageUpload}
            />
          </Button>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {images.map((url, index) => (
              <Box key={index} sx={{ position: 'relative', marginRight: 1, marginBottom: 1 }}>
                <img src={url} alt={`img-${index}`} style={{ width: 100, height: 100 }} />
                <Button
                  size="small"
                  color="error"
                  sx={{ position: 'absolute', top: 0, right: 0 }}
                  onClick={() => removeImage(index)}
                >
                  X
                </Button>
              </Box>
            ))}
          </Box>

          <Button
            variant="outlined"
            component="label"
            sx={{ marginBottom: 2 }}
          >
            Sélectionner une vidéo
            <input
              type="file"
              accept="video/*"
              hidden
              onChange={handleVideoUpload}
            />
          </Button>
          {progress > 0 && progress < 100 && (
            <Typography>Upload Progress: {progress}%</Typography>
          )}
          {video && (
            <Box sx={{ position: 'relative', marginTop: 2 }}>
              <video controls src={video} style={{ width: '100%', maxHeight: 300 }} />
              <Button
                size="small"
                color="error"
                sx={{ position: 'absolute', top: 0, right: 0 }}
                onClick={() => setVideo('')}
              >
                X
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>

      <Card sx={{ marginBottom: 3 }}>
        <CardContent>
          <TextField
            fullWidth
            label="Titre"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            multiline
            minRows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label="Prix"
            variant="outlined"
            value={price}
            onChange={(e) => validatePriceInput(e.target.value)}
            sx={{ marginBottom: 2 }}
          />

          <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
            <InputLabel>Devise</InputLabel>
            <Select
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              label="Devise"
            >
              {currencyOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
            <InputLabel>Type de prix</InputLabel>
            <Select
              value={priceType}
              onChange={(e) => setPriceType(e.target.value)}
              label="Type de prix"
            >
              {priceTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
            <InputLabel>Mode de paiement</InputLabel>
            <Select
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value)}
              label="Mode de paiement"
            >
              {paymentOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
      </Card>

      <Card sx={{ marginBottom: 3 }}>
        <CardContent>
          <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
            <TextField
              label="Rechercher un utilisateur"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => searchUsers(e.target.value)}
            />
          </FormControl>
          {searchResults.length > 0 && (
            <List>
              {searchResults.map((user) => (
                <ListItem key={user.id} button onClick={() => toggleUserSelection(user.id)}>
                  <ListItemText primary={user.name} />
                </ListItem>
              ))}
            </List>
          )}
        </CardContent>
      </Card>

      <Box sx={{ textAlign: 'center' }}>
        <Button variant="contained" color="primary" onClick={publishPattern} disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : 'Publier'}
        </Button>
      </Box>
    </Container>
    </Box>
  );
};

export default Accessories;
