import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  AppBar,
  Toolbar,
} from '@mui/material';
import { CheckCircle, XCircle, Delete } from 'lucide-react';
import { AuthContext } from '../features/authContext';
import { db } from '../firebase/firebase';
import { collection, query, where, onSnapshot, addDoc, updateDoc, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const NotificationsScreen = () => {
  const { currentUser } = useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [visible, setVisible] = useState(false);
  const [userMeasurements, setUserMeasurements] = useState(null);
  const [itemDetails, setItemDetails] = useState({});
  const navigate = useNavigate();

  // Utilisation de useEffect pour récupérer les notifications
  useEffect(() => {
    if (currentUser) {
      const q = query(collection(db, 'notifications'), where('userId', '==', currentUser.uid));
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const notificationsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setNotifications(notificationsData);

        const items = {};
        const itemPromises = notificationsData.map(async (notification) => {
          if (!items[notification.itemId] && notification.itemId) {
            const item = notification.type === 'commande'
              ? await fetchCommandDetails(notification.itemId)
              : await fetchPatternDetails(notification.itemId);
            items[notification.itemId] = item;
          }
        });

        await Promise.all(itemPromises);
        setItemDetails(items);
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  // Vérifiez si l'utilisateur est connecté
  if (!currentUser) {
    return (
      <Container maxWidth="md">
        <Box textAlign="center" mt={4}>
          <Typography variant="h6" color="textSecondary">
            Veuillez vous connecter ou créer un compte pour suivre vos commandes et rester en contact avec le vendeur.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/auth')}
            sx={{ mt: 2 }}
          >
            Se connecter / Créer un compte
          </Button>
        </Box>
      </Container>
    );
  }

  const fetchPatternDetails = async (itemId) => {
    if (!itemId) return null;
    try {
      const patternDoc = await getDoc(doc(db, 'patterns', itemId));
      if (patternDoc.exists()) {
        return patternDoc.data();
      } else {
        console.error('Pattern not found:', itemId);
        return null;
      }
    } catch (error) {
      console.error('Error fetching pattern details:', error);
      return null;
    }
  };

  const fetchCommandDetails = async (itemId) => {
    if (!itemId) return null;
    try {
      const commandDoc = await getDoc(doc(db, 'commandes', itemId));
      if (commandDoc.exists()) {
        return commandDoc.data();
      } else {
        console.error('Command not found:', itemId);
        return null;
      }
    } catch (error) {
      console.error('Error fetching command details:', error);
      return null;
    }
  };

  const fetchUserMeasurements = async (userId) => {
    try {
      const measurementDoc = await getDoc(doc(db, 'mesures', userId));
      if (measurementDoc.exists()) {
        setUserMeasurements(measurementDoc.data());
      } else {
        setUserMeasurements(null);
      }
    } catch (error) {
      console.error('Error fetching measurements:', error);
    }
  };

  const handleNotificationClick = async (notification) => {
    await updateDoc(doc(db, 'notifications', notification.id), { read: true });
    
    if (notification.status === 'paid' || notification.message.includes('Votre commande est en cours de confection.')) {
      navigate('/delivery');
    } else if (notification.message.includes('Cliquez pour procéder au paiement')) {
      const itemType = notification.type === 'commande' ? 'commandes' : 'patterns';
      navigate(`/payment/${notification.totalPrice}/${notification.itemId}/${notification.currency}/${itemType}`);
    } else if (notification.type === 'commande') {
      setSelectedNotification(notification);
      setVisible(true); // Ouvrir le dialogue
    } else {
      setSelectedNotification(notification);
      setVisible(true); // Ouvrir le dialogue
    }
  };

  const hideDialog = () => {
    setVisible(false); // Fermer le dialogue
    setSelectedNotification(null);
    setUserMeasurements(null);
  };

  const handleResponse = async (response) => {
    if (!selectedNotification) return;

    if (response === 'yes') {
      if (selectedNotification.type === 'commande') {
        const feePercentage = 0.02;
        const feeAmount = parseFloat(selectedNotification.commandPrice) * feePercentage;
        const totalPrice = parseFloat(selectedNotification.commandPrice) + feeAmount;

        navigate(`/payment/${totalPrice.toFixed(2)}/${selectedNotification.itemId}/${selectedNotification.commandCurrency}/commandes`);
      } else {
        const senderId = selectedNotification.senderId;
        const totalPrice = selectedNotification.totalPrice;
        const itemId = selectedNotification.itemId;

        if (!senderId) {
          console.error('Sender ID is undefined');
          return;
        }

        const item = itemDetails[itemId];
        const totalFormattedPrice = totalPrice ? totalPrice.toFixed(2) : '0.00';
        const message = `Votre demande a été acceptée par ${currentUser?.name || 'Inconnu'} pour l'article ${item?.title}. Le prix total est ${item?.currency} ${totalFormattedPrice}. Cliquez pour procéder au paiement de votre commande.`;

        const notificationData = {
          userId: senderId,
          message,
          read: false,
          status: 'accepted',
          timestamp: new Date(),
          totalPrice,
          itemId,
          currency: item?.currency,
          type: 'payment',
        };

        try {
          await addDoc(collection(db, 'notifications'), notificationData);

          await updateDoc(doc(db, 'notifications', selectedNotification.id), {
            status: 'accepted',
            read: true
          });

          console.log('Notification sent successfully');
        } catch (error) {
          console.error('Error sending notification:', error);
        }
      }
    }

    setVisible(false); // Fermer le dialogue après réponse
    setSelectedNotification(null);
  };

  const handleClearNotification = async (notificationId) => {
    try {
      await updateDoc(doc(db, 'notifications', notificationId), { read: true });
      setNotifications((prevNotifications) => prevNotifications.filter(notification => notification.id !== notificationId));
    } catch (error) {
      console.error('Error clearing notification:', error);
    }
  };

  const renderItem = (item) => {
    const itemDetail = itemDetails[item.itemId];
    const title = item.type === 'commande' ? `Commande: ${itemDetail?.title || 'Chargement...'}` : `Article: ${itemDetail?.title || 'Chargement...'}`;
  
    const getNotificationMessage = (item) => {
      if (item.type === 'payment') {
        return item.message;
      }
      else if (item.status === 'paid') {
        return `Vous avez déjà réglé le paiement pour l'article ${itemDetail?.title}. Votre commande est en cours de confection. Cliquez pour choisir une option de livraison.`;
      } 
      else if (item.message && item.message.includes('Cliquez pour procéder au paiement')) {
        return `${item.message}`;
      } else {
        return `${title} - ${item.message}`;
      }
    };
  
    return (
      <ListItem
        key={item.id}
        button
        onClick={() => handleNotificationClick(item)}
        style={item.status === 'paid' ? { backgroundColor: '#e0ffe0' } : {}}
      >
        <ListItemText
          primary={getNotificationMessage(item)}
          secondary={item.type === 'commande' ? `${itemDetail?.description || 'Description indisponible'}` : null}
          // Ajout de la condition pour le texte en gras
          primaryTypographyProps={{
            style: !item.read ? { fontWeight: 'bold' } : {},
          }}
        />
        <ListItemSecondaryAction>
          {item.status === 'accepted' && !item.message.includes('Cliquez pour procéder au paiement') && <CheckCircle style={{ color: 'green' }} />}
          {item.status === 'paid' && <CheckCircle style={{ color: 'green' }} />}
          {item.status === 'declined' && <XCircle style={{ color: 'red' }} />}
          <IconButton edge="end" aria-label="delete" onClick={() => handleClearNotification(item.id)}>
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };
  

  const renderMeasurements = (measurements) => {
    const fieldsToExclude = ['userId'];

    return Object.entries(measurements)
      .filter(([key]) => !fieldsToExclude.includes(key))
      .map(([key, value]) => (
        value && value.trim() !== '' && (
          <Typography key={key}>{`${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`}</Typography>
        )
      ));
  };

  return (
    <Box sx={{ flex: 1 }}>
      <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
        Retour
      </Button>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, borderBottom: '1px solid #ddd' }}>
        <Typography variant="h6">Notifications</Typography>
      </Box>
      <List>
        {notifications.length > 0 ? (
          notifications.map((item) => renderItem(item))
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
            <Typography variant="body1">Aucune notification</Typography>
          </Box>
        )}
      </List>
      <Dialog open={visible} onClose={hideDialog}>
        <DialogTitle>Répondre à la demande</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Voulez-vous accepter cette offre ?</Typography>
          {selectedNotification?.type !== 'commande' && (
            userMeasurements ? (
              <Box>
                {renderMeasurements(userMeasurements)}
              </Box>
            ) : (
              <Button onClick={() => fetchUserMeasurements(selectedNotification.senderId)}>Voir les mesures du client</Button>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleResponse('yes')} color="primary">Oui</Button>
          <Button onClick={() => handleResponse('no')} color="secondary">Non</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default NotificationsScreen;
