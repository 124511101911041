import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, Box, Card, CardContent } from '@mui/material';
import { AuthContext } from '../features/authContext'; // Importation du contexte utilisateur
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase'; // Importation de Firebase Firestore

const Delivery = ({ route }) => {
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const { itemId } = route.params; // Similaire à l'utilisation de useRoute en React Native
  const { currentUser } = useContext(AuthContext);
  const [userName, setUserName] = useState(null);
  const [isCouturierAddress, setIsCouturierAddress] = useState(false); 
  const [deliveryDuration, setDeliveryDuration] = useState(null);

  useEffect(() => {
    const fetchDeliveryAddress = async () => {
      try {
        const addressesCollection = collection(db, 'deliveryAddresses');
        const snapshot = await getDocs(addressesCollection);
        const addressesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Trouver l'adresse de livraison de l'utilisateur actuel
        const userAddress = addressesList.find(address => address.userId === currentUser.uid);

        // Récupérer les options de livraison pour vérifier si la méthode est 'Atelier'
        const deliveryOptionsCollection = collection(db, 'deliveryOptions');
        const deliveryOptionsSnapshot = await getDocs(deliveryOptionsCollection);
        const deliveryOptionsList = deliveryOptionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const selectedOption = deliveryOptionsList.find(option => option.articleId && option.articleId.includes(itemId));

        if (selectedOption && selectedOption.method === 'Atelier') {
          setDeliveryDuration(selectedOption.duration);
          // Récupérer l'adresse du couturier si la méthode est 'Atelier'
          fetchCouturierAddress(selectedOption.userId, itemId);
        } else {
          // Utiliser l'adresse de l'utilisateur pour d'autres méthodes
          if (userAddress) {
            const itemTitle = await fetchItemTitle(userAddress.itemId[0]);
            setDeliveryAddress({ ...userAddress, itemTitle });
            setIsCouturierAddress(false);
          } else {
            console.warn('No delivery address found for userId:', currentUser.uid);
          }
        }
      } catch (error) {
        console.error('Error fetching delivery addresses:', error);
      }
    };

    const fetchCouturierAddress = async (userId, itemId) => {
      try {
        const couturierDocRef = doc(db, 'CouturierAddress', userId);
        const couturierDocSnap = await getDoc(couturierDocRef);

        if (couturierDocSnap.exists()) {
          const couturierAddress = couturierDocSnap.data();
          const itemTitle = await fetchItemTitle(itemId);

          setDeliveryAddress({ ...couturierAddress, itemTitle });
          setIsCouturierAddress(true);

          const userDocRef = doc(db, 'users', userId);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserName(userData.name);
          } else {
            console.warn('No user found for userId:', userId);
          }
        } else {
          console.warn('No address found for userId in CouturierAddress:', userId);
        }
      } catch (error) {
        console.error('Error fetching couturier address:', error);
      }
    };

    const fetchItemTitle = async (itemId) => {
      const itemCollections = ['patterns', 'accessories', 'tissus', 'commandes'];
      for (const collectionName of itemCollections) {
        const itemDocRef = doc(db, collectionName, itemId);
        try {
          const itemDocSnap = await getDoc(itemDocRef);
          if (itemDocSnap.exists()) {
            const itemData = itemDocSnap.data();
            return itemData.title;
          }
        } catch (error) {
          console.error(`Error fetching item document from ${collectionName}:`, error);
        }
      }
      return 'Unknown Item';
    };

    fetchDeliveryAddress();
  }, [itemId, currentUser]);

  if (!deliveryAddress) {
    return (
      <Container>
        <Typography variant="h4" align="center">Chargement...</Typography>
      </Container>
    );
  }

  const userRole = currentUser?.role; // S'assurer que currentUser contient l'information du rôle

  let message;
  if (userRole === 'couturier') {
    message = `Vous devez livrer l'article ${deliveryAddress.itemTitle} à l'adresse suivante:`;
  } else if (isCouturierAddress) {
    message = `Votre article ${deliveryAddress.itemTitle} sera reçu à l'adresse suivante dans ${deliveryDuration} jours:`;
  } else {
    message = `Votre ${deliveryAddress.itemTitle} sera livré à l'adresse suivante dans ${deliveryDuration} jours:`;
  }

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        {message}
      </Typography>
      <Card>
        <CardContent>
          <Typography variant="body1"><strong>Pays:</strong> {deliveryAddress.country || deliveryAddress.relayCountry}</Typography>
          <Typography variant="body1"><strong>Région:</strong> {deliveryAddress.region || deliveryAddress.city}</Typography>
          <Typography variant="body1"><strong>Ville:</strong> {deliveryAddress.city || deliveryAddress.relayCity}</Typography>
          <Typography variant="body1"><strong>Adresse:</strong> {deliveryAddress.address}</Typography>
          <Typography variant="body1"><strong>Code Postal:</strong> {deliveryAddress.postalCode}</Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Delivery;
