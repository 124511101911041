import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../features/authContext'; 
import { db } from '../firebase/firebase';
import { useNavigate, useLocation } from 'react-router-dom'; // For navigation
import { getFirestore, doc, getDoc, addDoc, collection } from 'firebase/firestore'; // Firebase imports
import { Box, Button, Text, Alert, AlertIcon, useToast, Spinner, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure } from '@chakra-ui/react'; // Chakra UI components
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeApp from './StripeApp';
import logo from '../assets/logobis.png';
import { ArrowLeft } from 'lucide-react';
const stripePromise = loadStripe('pk_live_51OVgNXAdLeMTD97QAyg0aXIqFnoefXm2Z4J0gE1tQifmWyIDyD0xBtlMBDJ3rq4RkAqlPx2VLqx40TGxdsKSRtvm00rWTfvFnd');

const PaymentScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { totalPrice: initialTotalPrice, itemId, currency, itemType, couturierAmount, fee, serviceFee, commanduid } = location.state || {};
  const { currentUser } = useContext(AuthContext);
  const [paymentUrl, setPaymentUrl] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null); 
  const [isLoading, setIsLoading] = useState(false);
  const [apiKey] = useState("naboo-d3b43650-292c-4f9e-902c-f88d812a8644.13f735f6-a6cd-4afb-9fce-9b2461bd47d0");
  const [waitingMessage, setWaitingMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const parsedInitialTotalPrice = parseFloat(initialTotalPrice);
  const parsedFee = parseFloat(fee) || 0;
  const [totalPrice, setTotalPrice] = useState(parsedInitialTotalPrice);
  const [isPaymentAtDeliveryAvailable, setIsPaymentAtDeliveryAvailable] = useState(false); 
  const toast = useToast();
  
  // console.log("stripe app: ",totalPrice, itemId, currency, itemType,fee)

  useEffect(() => {

    if (fee !== undefined) {
      setTotalPrice(prevTotalPrice => prevTotalPrice + (fee/2));
    }

    checkPaymentType(itemType[0]);
  }, [currentUser, fee]);

  useEffect(() => {
    if (transactionId) {
      const interval = setInterval(() => {
        checkTransactionStatus(transactionId);
      }, 10000); 

      return () => clearInterval(interval);
    }
  }, [transactionId]);

  const fetchItemName = async (itemId, collectionName) => {
    try {
      const docRef = doc(getFirestore(), collectionName, itemId);
      const itemDoc = await getDoc(docRef);

      if (itemDoc.exists()) {
        const data = itemDoc.data();
        if (data && typeof data.title === 'string') {
          return data.title;
        } else {
          console.error('Title is not a string or is undefined', data);
          return 'No Title';
        }
      } else {
        console.error(`No document found with ID: ${itemId} in collection ${collectionName}`);
        return 'No Title';
      }
    } catch (error) {
      console.error(`Error fetching document from ${collectionName}:`, error);
      return 'Error fetching title';
    }
  };

  const handlePaymentAtDelivery = () => {
    navigate('/payment-at-delivery', { state: { serviceFee, itemId, currency, itemType } });
  };

  const closeModal = async () => {
    setShowModal(false);
    setWaitingMessage(true);
    toast({
      title: "Erreur",
      description: "Votre paiement n'a pas abouti. Veuillez réessayer.",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
    await checkTransactionStatus(transactionId);
  };

  const handlePayment = async () => {
    setIsLoading(true);
    try {
      const payload = {
        method_of_payment: ["WAVE", "ORANGE_MONEY", "FREE_MONEY"],
        products: [
          {
            name: "Article",
            category: itemType[0],
            amount: Math.round(totalPrice),
            quantity: 1,
            description: `Paiement`
          }
        ],
        is_escrow: false,
        is_merchant: false
      };
  
      const config = {
        method: 'put',
        url: 'https://api.naboopay.com/api/v1/transaction/create-transaction',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        data: payload
      };
  
      const response = await axios(config);
      console.log("data: ", response.data);
  
      if (response.data.transaction_status === "pending") {
        setPaymentUrl(response.data.checkout_url);
        setTransactionId(response.data.order_id);
        setShowModal(true);

        const webhookPayload = {
          order_id: response.data.order_id,
          amount: response.data.amount,
          amount_to_pay: response.data.amount_to_pay,
          currency: response.data.currency,
          created_at: response.data.created_at,
          transaction_status: response.data.transaction_status,
          checkout_url: response.data.checkout_url
        };
  
        console.log("Webhook Payload:", webhookPayload);

        try {
          const webhookResponse = await axios.post(
            `https://us-central1-couturia-auth.cloudfunctions.net/nabooWebhook`, 
            webhookPayload
          );
          console.log("Webhook Response:", webhookResponse.data);
        } catch (webhookError) {
          console.error("Webhook Error:", webhookError.response?.data || webhookError.message);
          toast({
            title: "Error",
            description: "Failed to register transaction. Please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        console.error("Transaction status not pending:", response.data.transaction_status);
        toast({
          title: "Payment failed",
          description: "Transaction not pending.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Axios Error:", error.response?.data || error.message);
      if (error.response && error.response.status === 422) {
        console.error("Validation Error Details:", error.response.data);
        toast({
          title: "Validation Error",
          description: JSON.stringify(error.response.data),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "An error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const checkTransactionStatus = async (transactionId) => {
    try {
      const transactionDoc = await getDoc(doc(getFirestore(), 'transactions', transactionId));

      if (!transactionDoc.exists()) {
        console.error("Transaction not found");
        toast({
          title: "Error",
          description: "Transaction not found. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const transactionData = transactionDoc.data();
      if (!transactionData || !transactionData.transactionStatus) {
        console.error("Invalid transaction data:", transactionData);
        toast({
          title: "Error",
          description: "Transaction data is invalid or missing.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      console.log("Transaction status:", transactionData.transactionStatus);

      if (transactionData.transactionStatus === 'part_paid') {
        setShowModal(false); 
        toast({
          title: "Succès",
          description: "Paiement réussi !",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        let itemName, couturierId;

        if (itemType[0] === "patterns" || itemType[0] === "commandes") {
          const result = await fetchItemDetailsForPatternsOrCommandes(itemId[0], itemType[0]);
          itemName = result.itemName;
          couturierId = result.couturierId;
        } else {
          const result = await updateNotifications(currentUser.uid, itemType[0], itemId[0], totalPrice, currency);
          if (result) {
            itemName = result.itemName;
            couturierId = result.couturierId;
          }
        }

        if (!itemName) {
          toast({
            title: "Erreur",
            description: "Erreur lors de la récupération du nom de l'article.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }

        const orderDetails = {
          userId: currentUser.uid,
          couturierId: couturierId,
          itemId: itemId[0],
          itemType: itemType[0],
          itemName: itemName,
          totalPrice: totalPrice,
          currency: currency,
          status: 'paid',
          timestamp: new Date(),
          type: 'mobile'
        };
        await saveOrder(orderDetails);

        setWaitingMessage(false);
        navigate('/client-notifications-tab');
      } else {
        setWaitingMessage(false);
        toast({
          title: "Erreur",
          description: "Votre paiement n'a pas abouti. Veuillez réessayer.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setWaitingMessage(false);
      console.error("Error checking transaction status:", error);
      toast({
        title: "Error",
        description: `An error occurred: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const saveOrder = async (orderDetails) => {
    try {
      await addDoc(collection(db, 'clientorders'), orderDetails);
      console.log('Order saved successfully');
    } catch (error) {
      console.error('Error saving order:', error);
    }
  };

  const fetchItemDetailsForPatternsOrCommandes = async (itemId, itemType) => {
    try {
      const docRef = doc(db, itemType, itemId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        return {
          itemName: data.name,
          couturierId: data.couturierId
        };
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching item details:", error);
      return null;
    }
  };

  const fetchItemOwnerId = async (itemId, collectionName) => {
    try {
      const docRef = doc(db, collectionName, itemId);
      const itemDoc = await getDoc(docRef);

      if (itemDoc.exists()) {
        const data = itemDoc.data();
        if (data && typeof data.userId === 'string') {
          return data.userId;
        } else {
          console.error('userId is not a string or is undefined', data);
          return null;
        }
      } else {
        console.error(`No document found with ID: ${itemId} in collection ${collectionName}`);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching document from ${collectionName}:`, error);
      return null;
    }
  };

  const checkPaymentType = async (itemType) => {
    try {
      const id = Array.isArray(itemId) && itemId.length === 1 ? itemId[0] : itemId;
      const collectionName = itemType === 'accessoires' ? 'accessories' : itemType;
      const docRef = doc(getFirestore(), collectionName, id);
      const itemDoc = await getDoc(docRef);

      if (itemDoc.exists()) {
        const data = itemDoc.data();
        console.log(data.paymentType);
        setIsPaymentAtDeliveryAvailable(data && data.paymentType === 'AtDelivery');
      } else {
        console.error(`No document found with ID: ${id}`);
        setIsPaymentAtDeliveryAvailable(false);
      }
    } catch (error) {
      console.error('Error fetching payment type:', error);
      setIsPaymentAtDeliveryAvailable(false);
    }
  };

  const updateNotifications = async (clientId, itemType, itemId, totalPrice, currency) => {
    try {
      let collectionName;
      switch (itemType) {
        case 'tissus':
          collectionName = 'tissus';
          break;
        case 'shoes':
          collectionName = 'shoes';
          break;
        case 'readytowear':
          collectionName = 'readytowear';
          break;
        default:
          collectionName = 'accessories';
      }
      
      const couturierId = await fetchItemOwnerId(itemId, collectionName);
      const itemName = await fetchItemName(itemId, collectionName);
      
      console.log(`Fetching from collection: ${collectionName}`);
      console.log(`ItemId: ${itemId}, CouturierId: ${couturierId}, ItemName: ${itemName}`);
  
      if (!couturierId || !itemName) {
        console.error('Failed to fetch couturierId or itemName');
        return null;
      }
  
      await addDoc(collection(db, 'notifications'), {
        userId: clientId,
        message: `Vous avez réglé le paiement de ${totalPrice} ${currency} 💵 pour l'article ${itemName}.`,
        status: 'paid',
        timestamp: new Date(),
        type: 'clientPay',
        itemId: itemId,
        name: 'Paiement mobile'
      });
  
      await addDoc(collection(db, 'notifications'), {
        userId: couturierId,
        message: `Vous avez reçu un paiement de ${totalPrice} ${currency} 💵 pour l'article ${itemName}. Cliquez pour voir l'adresse de livraison.`,
        status: 'paid',
        timestamp: new Date(),
        type: 'couturierPay',
        itemId: itemId,
        name: 'Paiement mobile'
      });
  
      return { itemName, couturierId };
    } catch (error) {
      console.error('Error adding payment notifications:', error);
      return null;
    }
  };
  console.log("Total price: ", totalPrice);

  return (
    <Elements stripe={stripePromise}>
       <Button onClick={() => navigate(-1)} leftIcon={<ArrowLeft />}>Retour</Button>
      <Box p={4} bg="#b8cacd" height="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box mb={8}>
          <img src={logo} alt="Logo" style={{ width: '150px' }} />
        </Box>
        
        <Box textAlign="center">
          <StripeApp totalPrice={totalPrice} itemId={itemId} currency={currency} itemType={itemType} serviceFee={serviceFee} commanduid={commanduid} />
          
          {isPaymentAtDeliveryAvailable && (
            <Button
              variant="contained"
              color="primary"
              mb={12}
              onClick={handlePaymentAtDelivery}
              style={styles.payButton}
            >
              Paiement à la livraison
            </Button>
          )}
          
          {currency === 'XOF' && (
            <Button
              variant="contained"
              color="primary"
              mb={8}
              onClick={handlePayment}
              style={styles.payButton}
            >
              Paiement Mobile (Wave, Orange, Free)
            </Button>
          )}
          <Modal isOpen={showModal} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Paiement</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <iframe
                  src={paymentUrl}
                  style={{ width: '100%', height: '500px', border: 'none' }}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
          {waitingMessage && (
            <Box textAlign="center" mt={4}>
              <Spinner size="xl" />
              <Text mt={2}>Veuillez patienter...</Text>
            </Box>
          )}
          {errorMessage && (
            <Box bg="red.500" color="white" p={3} mt={4} borderRadius="md">
              {errorMessage}
            </Box>
          )}
        </Box>
      </Box>
    </Elements>
  );
};

const styles = {
  payButton: {
    marginTop: '16px',
    marginBottom: '16px',
  }
};
export default PaymentScreen;