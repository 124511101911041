import React, { useContext, useEffect, useState } from 'react';
import { Container, TextField, Button, Typography, Avatar, MenuItem, Modal, Box, CircularProgress } from '@mui/material';
import { doc, setDoc, getDoc } from "firebase/firestore"; 
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { AuthContext } from '../features/authContext';
import { db } from '../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ParentComponent = ({ profile = {}, setProfile }) => {
  const [errors, setErrors] = useState({});
  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const cloudName = 'dih19xygi'; // Remplacez par votre cloud name
  const uploadPreset = 'j21h9lgn'; // Vous devez créer un preset dans les settings de Cloudinary

  const pickAndUploadImage = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', uploadPreset);

      try {
        const response = await axios.post(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, formData);
        setProfile({ ...profile, logo: response.data.url });
      } catch (err) {
        console.error('Error uploading image: ', err);
        alert('Failed to upload image.');
      }
    }
  };

  useEffect(() => {
    axios.get("https://api.countrystatecity.in/v1/countries", {
      headers: {
        'X-CSCAPI-KEY': "S09JY1RnWlBuaWV6U0pQTHd3QzVSV2RheE9EcENhWXN5RlpoQlBNRQ==",
      },
    })
    .then(response => {
      const countryArray = response.data.map(country => ({
        value: country.iso2,
        label: country.name,
      }));
      setCountryData(countryArray);
    })
    .catch(error => {
      console.log(error);
    });
  }, []);

  const handleCity = (countryCode) => {
    axios.get(`https://api.countrystatecity.in/v1/countries/${countryCode}/cities`, {
      headers: {
        'X-CSCAPI-KEY': "S09JY1RnWlBuaWV6U0pQTHd3QzVSV2RheE9EcENhWXN5RlpoQlBNRQ==",
      },
    })
    .then(response => {
      const cityArray = response.data.map(city => ({
        value: city.id,
        label: city.name,
      }));
      setCityData(cityArray);
    })
    .catch(error => {
      console.log(error);
    });
  };

  const validateFields = () => {
    let valid = true;
    let errors = {};

    if (!profile.phone) {
      valid = false;
      errors.phone = "Le numéro de téléphone est obligatoire.";
    }

    if (!profile.gender) {
      valid = false;
      errors.gender = "Le genre est obligatoire.";
    }

    setErrors(errors);
    return valid;
  };

  const handleSaveProfile = async () => {
    if (!currentUser || !currentUser.uid) {
      alert('Utilisateur non connecté');
      return;
    }

    if (validateFields()) {
      try {
        const profileData = {
          ...profile,
          uid: currentUser.uid,
        };
        await setDoc(doc(db, 'profiles', currentUser.uid), profileData);
        setModalVisible(true);  // Affiche le modal après une sauvegarde réussie
      } catch (error) {
        alert('Erreur lors de la sauvegarde du profil');
        console.error('Erreur lors de la sauvegarde du profil:', error);
      }
    }
  };

  const handleModalResponse = (response) => {
    setModalVisible(false);
    if (response === 'yes') {
      navigate('/info-screen');
    } else {
      navigate('/client-main');
    }
  };

  return (
    <Container style={styles.container}>
      <Typography variant="h5" style={styles.title}>Éditer le profil</Typography>

      <Typography style={{ marginLeft: '34%' }}>Photo de profil</Typography>
      <input type="file" onChange={pickAndUploadImage} style={styles.profilePictureButton} disabled={isLoading} />
      {profile.logo && (
        <Avatar src={profile.logo} style={styles.profilePicture} />
      )}

      <Box style={styles.fieldContainer}>
        <TextField
          select
          label="Genre"
          value={profile.gender || ''}
          onChange={(e) => setProfile({ ...profile, gender: e.target.value })}
          fullWidth
          variant="outlined"
          error={!!errors.gender}
          helperText={errors.gender}
        >
          <MenuItem value="Non Binaire">Non Binaire</MenuItem>
          <MenuItem value="Homme">Homme</MenuItem>
          <MenuItem value="Femme">Femme</MenuItem>
        </TextField>
      </Box>

      <Box style={styles.fieldContainer}>
        <TextField
          select
          label="Pays"
          value={country || ''}
          onChange={(e) => {
            setCountry(e.target.value);
            setProfile({ ...profile, country: e.target.options[e.target.selectedIndex].text });
            handleCity(e.target.value);
          }}
          fullWidth
          variant="outlined"
        >
          {countryData.map((country) => (
            <MenuItem key={country.value} value={country.value}>
              {country.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box style={styles.fieldContainer}>
        <TextField
          select
          label="Ville"
          value={city || ''}
          onChange={(e) => {
            setCity(e.target.value);
            setProfile({ ...profile, city: e.target.options[e.target.selectedIndex].text });
          }}
          fullWidth
          variant="outlined"
        >
          {cityData.map((city) => (
            <MenuItem key={city.value} value={city.value}>
              {city.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box style={styles.fieldContainer}>
        <TextField
          label="Téléphone"
          value={profile.phone || ''}
          onChange={(e) => setProfile({ ...profile, phone: e.target.value })}
          placeholder="Numéro de téléphone"
          fullWidth
          variant="outlined"
          error={!!errors.phone}
          helperText={errors.phone}
        />
      </Box>

      <Box style={styles.fieldContainer}>
        <TextField
          label="Biographie"
          value={profile.bio || ''}
          onChange={(e) => setProfile({ ...profile, bio: e.target.value })}
          placeholder="Biographie"
          fullWidth
          variant="outlined"
          multiline
          rows={4}
        />
      </Box>

      <Button variant="contained" color="primary" onClick={handleSaveProfile} style={styles.button}>
        Sauvegarder le profil
      </Button>

      <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
        <Box style={styles.modalContainer}>
          <Box style={styles.modalContent}>
            <Typography style={styles.modalTitle}>Connaissez-vous vos mesures?</Typography>
            <Box style={styles.modalButtonContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleModalResponse('no')}
                style={styles.modalButton}
              >
                Non
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleModalResponse('yes')}
                style={styles.modalButton}
              >
                Oui
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

const ProfileCompletion = () => {
  const [profile, setProfile] = useState({
    city: '',
    phone: '',
    bio: '',
    logo: '',
    gender: '',
    country: '',
  });

  return (
    <ParentComponent profile={profile} setProfile={setProfile} />
  );
};

const styles = {
  container: {
    padding: '16px',
    backgroundColor: '#f0f0f0',
    marginTop: '16px',
  },
  title: {
    textAlign: 'center',
    marginBottom: '16px',
  },
  profilePictureButton: {
    display: 'block',
    margin: '16px auto',
  },
  profilePicture: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    margin: '16px auto',
  },
  fieldContainer: {
    marginBottom: '16px',
  },
  button: {
    display: 'block',
    margin: '16px auto',
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: '16px',
    borderRadius: '4px',
    textAlign: 'center',
  },
  modalTitle: {
    marginBottom: '16px',
  },
  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  modalButton: {
    margin: '8px',
  },
};

export default ProfileCompletion;
