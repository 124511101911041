import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../features/authContext"; 
import { loginWithEmailAndPassword, registerWithEmailAndPassword, isValidEmail } from "../firebase/userAuth";
import logo from "../assets/COUTURIA.png"; 
import { useNavigate } from "react-router-dom"; 
import '../styles/Authentication.css';
import SignInwithGoogle from "./signInWithGoogle";
import { Link } from "react-router-dom";

const AuthenticationModal = () => {
  const [type, setType] = useState("login");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("client");
  const [loading, setLoading] = useState(false);
  const { currentUser, setCurrentUser, setIsLoggedIn, setIsProfileComplete } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    if (!isValidEmail(email) && email.indexOf('@') > -1) {
      alert("Veuillez entrer un email valide.");
      setLoading(false);
      return;
    }
  
    const res = await loginWithEmailAndPassword(email, password);
    if (res.success) {
      let user = res.user;
  
      setCurrentUser(user);
      setIsLoggedIn(true);
  
      if (user.role === "client") {
        navigate('/client-main'); // Redirect client to their main page
      } else if (user.role === "couturier") {
        navigate('/couturier-main'); // Redirect couturier to their specific main page
      }
  
    } else {
      alert(res.error); // Show login error if any
    }
    setLoading(false);
  };

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      alert("Les mots de passe ne correspondent pas.");
      return;
    }
  
    setLoading(true);
 
    const initialCredits = 1;
  
    const res = await registerWithEmailAndPassword(name.toLowerCase(), email, password, role, initialCredits);
    if (res.success) {
      alert("Un email de vérification a été envoyé à votre adresse. Veuillez vérifier votre email pour confirmer votre compte.");
      setCurrentUser(res.user);
      setIsLoggedIn(true);
      setIsProfileComplete(false);
  
      if (role === "client") {
        navigate('/client-main'); // Redirect user to client main page
      } else if (role === "couturier") {
        navigate('/couturier-profile'); // Redirect couturier to profile completion page
      }
    } else {
      alert(res.error); // Show registration error if any
    }
    setLoading(false);
  };
  

  useEffect(() => {
    if (currentUser) {
      setIsLoggedIn(true);
    }
  }, [currentUser, setIsLoggedIn]);

  return (
    <div className="container">
      <div className="logoContainer">
        <img src={logo} alt="Couturia Logo" className="logo" />
      </div>
      <div className="welcomeContainer">
        <h1 className="welcomeText">Bienvenue chez COUTURIA!</h1>
        <p className="subtitle">Adopte la couture sur mesure</p>
      </div>
      {type === "login" ? (
        <>
          <div className="fieldContainer">
            <label className="label">Email ou Nom d'utilisateur:</label>
            <input
              type="text"
              className="input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoCapitalize="none"
            />
          </div>
          <div className="fieldContainer">
            <label className="label">Mot de passe:</label>
            <input
              type="password"
              className="input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoCapitalize="none"
            />
          </div>
          <button
            className="loginButton"
            onClick={handleLogin}
          >
            Se connecter
          </button>
          <SignInwithGoogle/>
            

          <Link to="/password-reset">
            <span className="forgotPasswordText">Mot de passe oublié?</span>
          </Link>

          <div className="toggleContainer">
            <span className="toggleText">Besoin d'un compte ?</span>
            <button onClick={() => setType("register")} className="toggleLink">
              S'inscrire
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="fieldContainer">
            <label className="label">Nom d'utilisateur:</label>
            <input
              type="text"
              className="input"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="fieldContainer">
            <label className="label">Email:</label>
            <input
              type="email"
              className="input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoCapitalize="none"
            />
          </div>
          <div className="fieldContainer">
            <label className="label">Mot de passe:</label>
            <input
              type="password"
              className="input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoCapitalize="none"
            />
          </div>
          <div className="fieldContainer">
            <label className="label">Confirmer le mot de passe:</label>
            <input
              type="password"
              className="input"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              autoCapitalize="none"
            />
          </div>
          <div className="fieldContainer">
            <label className="label">Rôle:</label>
            <div className="roleContainer">
              <button
                className={`roleButton ${role === "client" ? "selectedRoleButton" : ""}`}
                onClick={() => setRole("client")}
              >
                Client
              </button>
            </div>
          </div>
          <button
            className="loginButton"
            onClick={handleRegister}
          >
            S’inscrire
          </button>
          <div className="toggleContainer">
            <span className="toggleText">Déjà inscrit ?</span>
            <button onClick={() => setType("login")} className="toggleLink">
              Se connecter
            </button>
          </div>
          <div className="privacyPolicyContainer">
            <span className="privacyText">
              En vous inscrivant, vous acceptez notre 
              <a href="https://www.privacypolicies.com/live/1fe18701-bf8d-450d-bae9-dc94e02193f1" className="privacyLink"> politique de confidentialité</a>.
            </span>
          </div>
        </>
      )}
      {loading && <div className="loadingSpinner">Loading...</div>}
    </div>
  );
};

export default AuthenticationModal;
