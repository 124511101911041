import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Typography, Alert, CircularProgress, TextField, IconButton } from '@mui/material';
import { AuthContext } from '../features/authContext';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import stripeLogo from '../assets/stripe-buttons.png';
import { CircleArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase/firebase';
import { updateDoc, getFirestore, doc, getDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';

const styles = {
  paymentContainer: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f7f7f7',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
    textAlign: 'center',
  },
  button: {
    display: 'block',
    width: '100%',
    padding: '12px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    textAlign: 'center',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
  },
  loadingSpinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
    marginTop: '20px',
    fontWeight: 'bold',
  },
  paymentModal: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '500px',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    zIndex: 1000,
  },
  modalContent: {
    marginBottom: '20px',
    fontSize: '16px',
    color: '#333',
  },
  modalClose: {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#dc3545',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  modalCloseHover: {
    backgroundColor: '#c82333',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
  successMessage: {
    color: 'green',
    textAlign: 'center',
    marginTop: '20px',
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Centrer horizontalement
    justifyContent: 'center', // Centrer verticalement
    width: '100%',
    maxWidth: '600px',
    height: '400px', // Prend toute la hauteur de la fenêtre visible
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '0 auto',
  },
  title: {
    fontSize: '24px',
    marginBottom: '16px',
    textAlign: "center",
  },
  amountText: {
    fontSize: '20px',
    marginBottom: '16px',
    textAlign: "center",
  },
  input: {
    marginBottom: '16px',
  },
  cardField: {
    style: {
        base: {
            fontSize: '16px',
            color: '#424770',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
},
  payButton: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '16px',
    textAlign: 'center',
  },
  closeButton: {
    marginTop: '16px',
  },
};

const PaymentComponent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const { currentUser, updateCredits } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [paymentUrl, setPaymentUrl] = useState("");
  const [transactionId, setTransactionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [waitingMessage, setWaitingMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [apiKey] = useState("naboo-d3b43650-292c-4f9e-902c-f88d812a8644.13f735f6-a6cd-4afb-9fce-9b2461bd47d0");

  useEffect(() => {
    if (transactionId) {
    const interval = setInterval(() => {
        checkTransactionStatus(transactionId);
    }, 5000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
    }
}, [transactionId]);

const closeModal = async () => {
  setShowModal(false);
  setWaitingMessage(true); // Show waiting message
  alert("Erreur: Votre paiement n'a pas abouti. Veuillez réessayer");
  await checkTransactionStatus(transactionId); // Check transaction status
};

  const handlePaymentMobile = async () => {
    setIsLoading(true);
    try {
    const payload = {
        amount: Math.round(655),
        amount_to_pay: Math.round(655),
        currency: 'XOF',
        method_of_payment: ["WAVE", "ORANGE_MONEY", "FREE_MONEY"],
        products: [
        {
        name: "Credits",
        category: "credits",
        amount: Math.round(655),
        quantity: 1,
        description: "Paiement"
        }
        ],
        is_escrow: false,
        is_merchant: false,
    };

    const config = {
        method: 'post',
        url: 'https://api.naboopay.com/api/v1/transaction/create-transaction',
        headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${apiKey}`
        },
        data: payload
    };

    const response = await axios(config);
    console.log("Transaction response:", response.data);

    if (response.data.transaction_status === "pending") {
        console.log('Response Checkout URL:', response.data.checkout_url);
        setPaymentUrl(response.data.checkout_url);
        setTransactionId(response.data.order_id);
        setShowModal(true);

        const webhookPayload = {
        order_id: response.data.order_id,
        amount: response.data.amount,
        amount_to_pay: response.data.amount_to_pay,
        currency: response.data.currency,
        created_at: response.data.created_at,
        transaction_status: response.data.transaction_status,
        checkout_url: response.data.checkout_url
        };

        console.log("Webhook Payload:", webhookPayload);

        try {
        const webhookResponse = await axios.post(
            `https://us-central1-couturia-auth.cloudfunctions.net/nabooWebhook`,
            webhookPayload
        );
        console.log("Webhook Response:", webhookResponse.data);
        } catch (webhookError) {
        console.error("Webhook Error:", webhookError.response?.data || webhookError.message);
        }
    } else {
        console.error("Transaction status not pending:", response.data.transaction_status);
        alert("Payment failed: Transaction not pending.");
    }
    } catch (error) {
    console.error("Axios Error:", error.response?.data || error.message);
    if (error.response && error.response.status === 422) {
        console.error("Validation Error Details:", error.response.data);
        alert("Validation Error", JSON.stringify(error.response.data));
    } else {
        alert("Error: An error occurred. Please try again.");
    }
    } finally {
    setIsLoading(false);
    }
};

  const checkTransactionStatus = async (transactionId) => {
    try {
    // Get Firestore document
    const transactionDoc = await getDoc(doc(getFirestore(), 'transactions', transactionId));

    // Check if document exists
    if (!transactionDoc.exists()) {
        console.error("Transaction not found");
        setErrorMessage("Error: Transaction not found. Please try again.");
        return;
    }

    // Get transaction data
    const transactionData = transactionDoc.data();

    // Ensure transactionData is defined and has required fields
    if (!transactionData || !transactionData.transactionStatus) {
        console.error("Invalid transaction data:", transactionData);
        setErrorMessage("Error: Transaction data is invalid or missing.");
        return;
    }

    console.log("Transaction status:", transactionData.transactionStatus);

    if (transactionData.transactionStatus === 'part_paid') {
       // Mettre à jour les crédits de l'utilisateur
       const newCredits = currentUser.credits + 10;

       // Mettre à jour directement dans Firestore
       const userRef = doc(db, 'users', currentUser.uid);
       await updateDoc(userRef, {
         credits: newCredits,
       });

       // Mettre à jour les crédits dans le contexte
       updateCredits(newCredits);
        setShowModal(false);
        alert("Succès: Paiement réussi !");
        navigate('/fabrictodesign');

    } else {
        setWaitingMessage(false);
        setErrorMessage("Erreur: Votre paiement n'a pas abouti. Veuillez réessayer");
    }
    } catch (error) {
    setWaitingMessage(false);
    if (error.response) {
        if (error.response.data.message === "Transaction non trouvée") {
        console.error("Transaction not found, prompting user to retry.");
        setErrorMessage("Error: Transaction not found. Please try again.");
        setTransactionId(null); // Reset the transaction ID to allow retrying
        } else {
        console.error("Error checking transaction status:", error.response.data);
        setErrorMessage(`Error: Votre paiement n'a pas abouti. Veuillez réessayer: ${error.response.data.message}`);
        }
    } else if (error.request) {
        console.error("Error checking transaction status: No response received", error.request);
        setErrorMessage("Error: No response received from server while checking transaction status.");
    } else {
        console.error("Error checking transaction status:", error.message);
        setErrorMessage(`Error: An error occurred: ${error.message}`);
    }
    }
};

  // console.log("mes credits: ",currentUser.credits);
  const handlePayment = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      // Créer un PaymentIntent via votre serveur
      const { data } = await axios.post('https://us-central1-couturia-auth.cloudfunctions.net/createPaymentIntent', {
        amount: 655,
        currency: "XOF",
        itemId: currentUser.uid,
        itemType: "credits"
      });

      const clientSecret = data.clientSecret;
      const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
        setError('Veuillez entrer les détails de votre carte.');
        setLoading(false);
        return;
        }

      const userName = currentUser?.name || "Guest User";

      // Step 2: Confirm the card payment
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: userName,
          },
        },
      });

      if (error) {
        setError(error.message);
      } else {
        if (paymentIntent && paymentIntent.status === 'succeeded') {
          // Mettre à jour les crédits de l'utilisateur
          const newCredits = currentUser.credits + 10;

          // Mettre à jour directement dans Firestore
          const userRef = doc(db, 'users', currentUser.uid);
          await updateDoc(userRef, {
            credits: newCredits,
          });

          // Mettre à jour les crédits dans le contexte
          updateCredits(newCredits);

          // Afficher un message de succès
          setSuccessMessage('Paiement réussi ! 10 crédits ajoutés à votre compte.');
          navigate('/fabrictodesign');
        }
      }
    } catch (err) {
        console.error('Stripe error:', error);
      setError('Une erreur est survenue lors du traitement du paiement.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url("https://www.transparenttextures.com/patterns/white-linen.png")',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        py: 4,
      }}
    >
      <IconButton onClick={() => navigate(-1)} style={styles.backButton}>
        <CircleArrowLeft />
      </IconButton>
      <Box sx={styles.container}>
        <img src={stripeLogo} style={{ width: '150px', marginTop: '15px' }} />
        <Typography variant="h6" sx={styles.title}>Acheter des crédits</Typography>
        <Typography variant="body1" sx={styles.amountText}>Prix : 655 XOF pour 10 crédits</Typography>

        
          <form onSubmit={handlePayment}>
            <TextField
              label="E-mail"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={styles.input}
            />
            <Box mt={2} mb={2} width="100%">
              <CardElement options={styles.cardField} />
            </Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading || !stripe}
              sx={styles.payButton}
            >
              {loading ? <CircularProgress size={24} /> : 'Payer 655 XOF'}
            </Button>
          </form>
      
          <div >
          {showModal && (
        <div style={styles.paymentModal}>
          <div style={styles.modalContent}>
            <button style={styles.modalClose} onClick={closeModal}>X</button>
            <p>
              Veuillez compléter votre paiement en cliquant{' '}
              <a href={paymentUrl} target="_blank" rel="noopener noreferrer">ici</a>.
            </p>
          </div>
        </div>
      )}
      {waitingMessage && <p style={styles.errorMessage}>Waiting for payment confirmation...</p>}
      <p style={styles.errorMessage}>{errorMessage}</p>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlePaymentMobile}
                      disabled={isLoading}
                      style={styles.payButton}
                    >
                      {isLoading ? 'Processing...' : 'Paiement mobile (WAVE, Orange Money, Free Money)'}
                    </Button>
                </div>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {successMessage && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {successMessage}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default PaymentComponent;
