import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, CircularProgress, Avatar, Button, Box } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { AuthContext } from '../features/authContext';
import { useNavigate } from 'react-router-dom';

const InfoScreen = () => {
  const { currentUser } = useContext(AuthContext);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser && currentUser.uid) {
        try {
          const docRef = doc(db, 'profiles', currentUser.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setProfile(docSnap.data());
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user profile: ", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserProfile();
  }, [currentUser]);

  if (loading) {
    return <CircularProgress size="large" color="primary" />;
  }

  if (!profile) {
    return (
      <Container style={styles.container}>
        <Typography style={styles.errorText}>No profile data found.</Typography>
      </Container>
    );
  }

  return (
    <Container style={styles.container}>
      <Box style={styles.profileImageContainer}>
        {profile.logo && (
          <Avatar src={profile.logo} style={styles.profileImage} />
        )}
      </Box>
      <Box style={styles.fieldContainer}>
        <Typography style={styles.fieldLabel}>Biographie:</Typography>
        <Typography style={styles.fieldValue}>{profile.bio}</Typography>
      </Box>
      
      <Box style={styles.fieldContainer}>
        <Typography style={styles.fieldLabel}>Sexe:</Typography>
        <Typography style={styles.fieldValue}>{profile.gender}</Typography>
      </Box>
      
      <Box style={styles.fieldContainer}>
        <Typography style={styles.fieldLabel}>Pays:</Typography>
        <Typography style={styles.fieldValue}>{profile.country}</Typography>
      </Box>
      
      <Box style={styles.fieldContainer}>
        <Typography style={styles.fieldLabel}>Ville:</Typography>
        <Typography style={styles.fieldValue}>{profile.city}</Typography>
      </Box>
      
      <Box style={styles.fieldContainer}>
        <Typography style={styles.fieldLabel}>Numéro de téléphone:</Typography>
        <Typography style={styles.fieldValue}>{profile.phone}</Typography>
      </Box>
      <Button variant="contained" color="primary" style={styles.button} onClick={() => navigate('/profile-completion')}>
        Modifier
      </Button>
    </Container>
  );
};

const styles = {
  container: {
    padding: '16px',
    backgroundColor: '#f0f0f0',
  },
  profileImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  profileImage: {
    width: '100px',
    height: '100px',
  },
  fieldContainer: {
    backgroundColor: '#b8cacd',
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  fieldLabel: {
    fontWeight: 'bold',
    fontSize: '16px',
    marginBottom: '4px',
  },
  fieldValue: {
    fontSize: '16px',
    marginBottom: '8px',
  },
  errorText: {
    fontSize: '16px',
    color: 'red',
    textAlign: 'center',
    marginTop: '20px',
  },
  button: {
    marginTop: '16px',
    alignSelf: 'center',
  },
};

export default InfoScreen;
