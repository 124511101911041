import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DefaultScreen from './DefaultScreen';
import AuthenticationModal from './components/Authentication';
import ConversationsScreen from './components/ConversationsScreen';
import { AuthProvider } from './features/authContext';
import { CartProvider } from './features/cartContext';
import ClientHomePage from './components/ClientHomePage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HomeClient from './components/HomeScreen';
import NotificationsScreen from './components/NotificationsScreen';
import ClientProfile from './components/ClientProfile';
import InfoScreen from './components/InfoScreen';
import ProfileCompletion from './components/ProfileCompletion';
import OrderScreen from './components/OrderScreen';
import ProductListScreen from './components/ProductListScreen';
import DetailScreen, { SizeGuide } from './components/DetailScreen';
import PaymentScreen from './components/PaymentScreen';
import StripeApp from './components/StripeApp';
import MesureScreen from './components/MesureScreen';
import AccountSettingsScreen from './components/AccountSettingsScreen';
import ClientMessagesScreen from './components/ClientMessagesScreen';
import CouturierRegistration from './components/CouturierRegistration';
import ProfileScreen from './components/ProfileScreen';
import CouturierHomePage from './components/CouturierHomePage';
import UserProfile from './components/UserProfile';
import CouturierProfile from './components/CouturierProfile';
import SettingsScreen from './components/SettingsScreen';
import WalletScreen from './components/WalletScreen';
import MyPostsScreen from './components/MyPostsScreen';
import CouturierTabNavigator from './components/CouturierHomePage';
import CommandeClient from './components/CommandeClient';
import PublishScreen from './components/PublishScreen';
import DeliveryScreen from './components/DeliveryScreen';
import PostDetailScreen from './components/PostDetailScreen';
import OrderDetail from './components/OrderDetail';
import ModifierBanniere from './components/ModifierBanniere';
import PasswordReset from './components/PasswordReset';
import PaymentAtDelivery from './components/PaymentAtDelivery';
import { ChakraProvider } from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Publish from './components/Publish';
import TissuScreen from './components/TissuScreen';
import Accessories from './components/Accessoires';
import DeliveryOptions from './components/DeliveryOptions';
import Shoes from './components/Shoes';
import Drift from './components/Drift';
import ReadyToWear from './components/ReadyToWear';
import FavoritesScreen from './components/FavoritesScreen';
import { FavoritesProvider } from './features/favoritesContext';
import Delivery from './components/Delivery';
import FabricToDesign from './components/FabricToDesign';
import PaymentComponent from './components/PaymentComponent';
import CategoryScreen from './components/CategoryScreen';

const stripePromise = loadStripe('pk_live_51OVgNXAdLeMTD97QAyg0aXIqFnoefXm2Z4J0gE1tQifmWyIDyD0xBtlMBDJ3rq4RkAqlPx2VLqx40TGxdsKSRtvm00rWTfvFnd');

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CartProvider>
          <FavoritesProvider>
          <Router>
            <Drift />
            <Routes>
                {/* Routes pour les clients */}
                <Route path="/" element={<DefaultScreen />} />
                <Route path="/auth" element={<AuthenticationModal />} />
                <Route path="/client-main" element={<HomeClient />} />
                <Route path="/cart" element={<ClientHomePage />} />
                <Route path="/profile" element={<ClientProfile />} />
                <Route path="/account-settings" element={<AccountSettingsScreen />} />
                <Route path="/conversation" element={<ClientMessagesScreen />} />
                <Route path="/ProductListScreen" element={<ProductListScreen />} />
                <Route path="/info" element={<InfoScreen />} />
                <Route path="/size-guide" element={<SizeGuide />} />
                <Route path="/mesures" element={<MesureScreen />} />
                <Route path="/shoes" element={<Shoes />} />
                <Route path="/readytowear" element={<ReadyToWear />} />
                <Route path="/payment" element={<PaymentScreen />} />
                <Route path="/orders" element={<OrderScreen />} />
                <Route path="/order/:id" element={<OrderDetail />} />
                <Route path="/notifications" element={<NotificationsScreen />} />
                <Route path="/messages" element={<ConversationsScreen setUnreadCount={() => {}} />} />
                <Route path="/delivery-options" element={<DeliveryOptions />} />
                <Route path="/wallet" element={<WalletScreen />} />
                <Route path="/favoris" element={<FavoritesScreen />} />
                <Route path="/user-profile/:userId" element={<UserProfile />} />
                <Route path="/password-reset" element={<PasswordReset />} />
                <Route path="/category/:category" element={<CategoryScreen />} />
                
                {/* Payment routes wrapped with Elements */}
                <Route path="/payment-at-delivery" element={<Elements stripe={stripePromise}><PaymentAtDelivery /></Elements>} />
                <Route path="/purchase-credits" element={<Elements stripe={stripePromise}><PaymentComponent /></Elements>} />
                
                {/* Routes pour les couturiers */}
                <Route path="/couturier-main/*" element={<CouturierTabNavigator />} />
                <Route path="/couturier-profile" element={<ProfileScreen />} />
                <Route path="/profile-couturier" element={<CouturierProfile />} />
                <Route path="/publish-screen" element={<PublishScreen />} />
                <Route path="/commandes" element={<CommandeClient />} />
                <Route path="/modifier-banniere" element={<ModifierBanniere />} />
                <Route path="/tissu" element={<TissuScreen />} />
                <Route path="/accessories" element={<Accessories />} />
                <Route path="/publish" element={<Publish />} />
                <Route path="/profile-completion" element={<ProfileCompletion />} />
                <Route path="/delivery-screen" element={<DeliveryScreen />} />
                <Route path="/delivery" element={<Delivery />} />
                <Route path="/fabrictodesign" element={<FabricToDesign />} />
                <Route path="/my-posts" element={<MyPostsScreen />} />
                <Route path="/couturier-registration" element={<CouturierRegistration />} />

                {/* Routes dynamiques pour les détails des produits */}
                <Route path="/post/:id/:type" element={<PostDetailScreen />} />
                <Route path="/detail/:id/:category" element={<DetailScreen />} />

                {/* Stripe App */}
                <Route path="/stripe-app" element={<StripeApp />} />
              </Routes>
          </Router>
          </FavoritesProvider>
        </CartProvider>
      </AuthProvider>
      
    </ThemeProvider>
  );
}

export default App;
