import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, CircularProgress, AppBar, Toolbar, IconButton, List, ListItem, ListItemText, Button } from '@mui/material';
import { AuthContext } from '../features/authContext';
import { useNavigate } from 'react-router-dom';
import { CircleUserRound } from 'lucide-react';
import { db } from '../firebase/firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { CircleArrowLeft } from 'lucide-react';

const ClientProfile = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (currentUser && currentUser.uid) {
        try {
          const docRef = doc(db, "profiles", currentUser.uid);
          const docSnap = await getDoc(docRef);
          setLoading(false);
        } catch (error) {
          console.error("Erreur lors de la récupération du profil:", error);
          setLoading(false);
        }
      }
    };

    fetchProfileData();
  }, [currentUser]);

  useEffect(() => {
    const fetchOrders = async () => {
      if (currentUser) {
        const q = query(collection(db, 'commandes'), where('userId', '==', currentUser.uid));
        const querySnapshot = await getDocs(q);
        const ordersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setOrders(ordersData);
      }
    };

    fetchOrders();
  }, [currentUser]);

  const handleLogout = () => {
    // handle logout logic
    navigate('/auth');
  };

  if (loading) {
    return (
      <Container style={styles.loadingContainer}>
        <CircularProgress size="large" color="primary" />
      </Container>
    );
  }

  return (
    <Container>
      <IconButton onClick={() => navigate(-1)} style={styles.backButton}>
        <CircleArrowLeft/>
      </IconButton>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Mon Profil
          </Typography>
          <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => navigate('/account-settings')}>
            <CircleUserRound />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container style={styles.container}>
        <List>
          <ListItem button onClick={() => navigate('/info')}>
            <ListItemText primary="Mes Informations" />
          </ListItem>
          <ListItem button onClick={() => navigate('/orders')}>
            <ListItemText primary="Mes Commandes" />
          </ListItem>
          <ListItem button onClick={() => navigate('/mesures')}>
            <ListItemText primary="Mes Mesures" />
          </ListItem>
          <ListItem button onClick={() => navigate('/my-posts')}>
            <ListItemText primary="Mes Publications" />
          </ListItem>
          <ListItem button onClick={() => navigate('/favoris')}>
            <ListItemText primary="Mes Favoris" />
          </ListItem>
          <ListItem button onClick={() => navigate('/account-settings')}>
            <ListItemText primary="Paramètres du Compte" />
          </ListItem>
        </List>

        <Button onClick={handleLogout} variant="contained" color="primary" style={styles.logoutButton}>
          Se Déconnecter
        </Button>
      </Container>
    </Container>
  );
};

const styles = {
  backButton: {
    position: 'absolute',
    left: 10,
    top: 10,
  },
  container: {
    padding: '16px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  logoutButton: {
    marginTop: '16px',
  },
};

export default ClientProfile;
