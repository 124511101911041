import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Card, CardContent, CardMedia, Button } from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const CategoryScreen = () => {
    const { category } = useParams();
    const [items, setItems] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchItems = async () => {
            const querySnapshot = await getDocs(collection(db, category));
            const itemsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setItems(itemsData);
        };

        fetchItems();
    }, [category]);

    const formatPrice = (price, discount) => {
        const originalPrice = parseFloat(price);
        const discountValue = parseFloat(discount);
        if (isNaN(originalPrice) || isNaN(discountValue)) {
          return { originalPrice: 'N/A', discountedPrice: 'N/A' };
        }
        const newPrice = originalPrice - (originalPrice * discountValue / 100);
        return { originalPrice: originalPrice.toFixed(2), newPrice: newPrice.toFixed(2) };
    };

    const handleCardClick = (id, category, newPrice, post) => {
        if (newPrice === undefined) {
          console.error('newPrice is undefined');
          return;
        }
      
        navigate(`/detail/${id}/${category}`, { state: { newPrice, post } });
      };

    const getFirstThreeWords = (description) => {
        const words = description.split(' ');
        return words.slice(0, 3).join(' ') + (words.length > 3 ? '...' : '');
    };

    const getFirstWords = (description) => {
        const words = description.split(' ');
        return words.slice(0, 2).join(' ') + (words.length > 2 ? '...' : '');
    };

    const getCategoryDisplayName = (category) => {
        switch (category.toLowerCase()) {
            case 'readytowear':
                return 'Prêt à porter';
            case 'patterns':
                return 'Patrons de couture';
            case 'accessories':
                return 'Sac et accessoires';
            case 'shoes':
                return 'Chaussures';
            default:
                return category;
        }
    };
    

    return (
        <Box sx={{ padding: '2rem' }}>
        <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
        Retour
        </Button>
        <Typography variant="h4" sx={{ marginBottom: '1rem', textTransform: 'capitalize' }}>
            {getCategoryDisplayName(category)}
        </Typography>

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {items.map((item) => {
                    const hasPromotion = item.promotion && item.promotion > 0;
                    const price = parseFloat(item.price) || 0;
                    const promotion = parseFloat(item.promotion) || 0;
                    const { originalPrice, newPrice } = formatPrice(price, promotion);
                    return (
                        <Box key={item.id} sx={{ minWidth: 250, display: 'inline-block', margin: 1, position: 'relative' }}>
                            <Typography variant="body2" color="text.secondary">
                                {item.userName} a publié
                            </Typography>
                            <Card style={{ margin: 8 }}>
                                <CardMedia
                                    component={item.video ? 'video' : 'img'}
                                    height="140"
                                    image={item.images && Array.isArray(item.images) && item.images.length > 0 ? item.images[0] : undefined}
                                    src={item.video}
                                    controls={!!item.video}
                                    alt={item.title}
                                    style={item.availability === 'Epuisée' ? { filter: 'blur(5px)' } : {}}
                                />
                                 {item.availability === 'Epuisée' && (
                                <Typography variant="caption" color="error" sx={{ position: 'absolute', top: 8, left: 8, backgroundColor: 'rgba(255, 255, 255, 0.7)', padding: '2px 4px', borderRadius: 2 }}>
                                    Épuisé
                                </Typography>
                                )}
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {getFirstWords(item.title)}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {getFirstThreeWords(item.description)}
                                    </Typography>
                                    {hasPromotion ? (
                                        <Typography variant="body2" color="text.secondary">
                                            Prix: <span style={{ textDecoration: 'line-through' }}>{originalPrice} {item.currency}</span>{' '}
                                            <span style={{ color: 'red' }}>{newPrice} {item.currency}</span>
                                        </Typography>
                                    ) : (
                                        <Typography variant="body2" color="text.secondary">
                                            Prix: {price} {item.currency}
                                        </Typography>
                                    )}
                                    <Button 
                                        variant="contained" 
                                        onClick={() => handleCardClick(item.id, category, newPrice, item)}
                                        style={{ marginTop: 8 }}
                                    >
                                        Voir les détails
                                    </Button>
                                </CardContent>
                            </Card>
                        </Box>
                    );
                })}
            </div>
        </Box>
    );
};

export default CategoryScreen;
