import React, { createContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase/firebase';
import { getDoc, doc, updateDoc } from 'firebase/firestore';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [role, setRole] = useState(null);
  const [isProfileComplete, setIsProfileComplete] = useState(false);

  const updateCredits = async (newCredits) => {
    // Assurez-vous que newCredits est un nombre valide et non négatif
    const validCredits = Math.max(parseFloat(newCredits), 0);
  
    if (currentUser && currentUser.uid) {
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        
        // Mettre à jour les crédits dans Firestore
        await updateDoc(userRef, { credits: validCredits });
        
        // Mettre à jour les crédits dans l'état local
        setCurrentUser(prevState => ({
          ...prevState,
          credits: validCredits,
        }));
      } catch (error) {
        console.error('Erreur lors de la mise à jour des crédits:', error);
      }
    }
  };
  

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const userData = userDoc.data();
          if (userData) {
            setCurrentUser({
              ...user,
              credits: userData.credits || 0, // Assurez-vous que credits est initialisé
              role: userData.role,
              isProfileComplete: userData.isProfileComplete || false
            });
            setIsLoggedIn(true);
          } else {
            // Gérer le cas où les données de l'utilisateur ne sont pas trouvées
            setCurrentUser(null);
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des données utilisateur:', error);
          setCurrentUser(null);
          setIsLoggedIn(false);
        }
      } else {
        setCurrentUser(null);
        setIsLoggedIn(false);
      }
    });
  
    return () => unsubscribe();
  }, []);
  

  return (
    <AuthContext.Provider value={{
      currentUser, 
      isLoggedIn, 
      role, 
      isProfileComplete, 
      setRole, 
      setIsProfileComplete, 
      setCurrentUser, 
      setIsLoggedIn, 
      updateCredits 
    }}>
      {children}
    </AuthContext.Provider>
  );
};
