import React, { useEffect } from 'react';

const Drift = () => {
  useEffect(() => {
    // Initialisez Drift dès que le composant est monté
    initializeDrift();

    const driftOpenTimeout = setTimeout(() => {
      window.drift.on('ready', function() {
        window.drift.api.sidebar.open(); // Ouvre automatiquement le widget après 2 minutes
      });
    }, 120000); 

    // Nettoyage du timeout au cas où le composant serait démonté avant l'exécution
    return () => clearTimeout(driftOpenTimeout);
  }, []);

  const initializeDrift = () => {
    (function() {
      var t = (window.driftt = window.drift = window.driftt || []);
      if (!t.init) {
        if (t.invoked) {
          window.console && console.error && console.error("Drift snippet included twice.");
        } else {
          t.invoked = true;
          t.methods = [
            "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on"
          ];
          t.factory = function(e) {
            return function() {
              var n = Array.prototype.slice.call(arguments);
              n.unshift(e);
              t.push(n);
            };
          };
          t.methods.forEach(function(e) {
            t[e] = t.factory(e);
          });
          t.load = function(t) {
            var e = 3e5,
                n = Math.ceil(new Date() / e) * e,
                o = document.createElement("script");
            o.type = "text/javascript";
            o.async = true;
            o.crossOrigin = "anonymous";
            o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
            var i = document.getElementsByTagName("script")[0];
            i.parentNode.insertBefore(o, i);
          };
        }
      }
    })();
    window.drift.SNIPPET_VERSION = "0.3.1";
    window.drift.load("kf8t3w7sg9yz");
  };

  return null; // Pas de bouton, juste le widget Drift
};

export default Drift;
