import { auth, db } from "./firebase";
import { getDoc, doc, setDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendEmailVerification } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { sendPasswordResetEmail } from "firebase/auth";

export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return { success: true };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};


const getUserByEmailOrUsername = async (identifier) => {
  if (!identifier || typeof identifier !== 'string' || identifier.trim() === '') {
    console.error("Invalid identifier provided:", identifier);
    return null;
  }

  const normalizedIdentifier = identifier.toLowerCase().trim();
  const userRef = collection(db, "users");

  try {
    // First, check if the identifier matches a username
    const q = query(userRef, where("name", "==", normalizedIdentifier));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      return userDoc.data().email;
    } else {
      // If no username match is found, assume the identifier is an email
      return normalizedIdentifier;
    }
  } catch (error) {
    console.error("Error fetching user by email or username:", error);
    return null;
  }
};


const registerWithEmailAndPassword = async (name, email, password, role, credits) => {
  console.log(email, password, name, role, credits);
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const userDocRef = doc(db, "users", user.uid);
    await setDoc(userDocRef, {
      uid: user.uid,
      name: name.toLowerCase(),
      email,
      role,
      credits
    });
    await sendEmailVerification(user);
    const userDoc = await getDoc(userDocRef);
    return { success: true, user: userDoc.data() };  // Retourne les données utilisateur
  } catch (error) {
    console.error(error);
    return { success: false, error };
  }
};


const loginWithEmailOrUsername = async (identifier, password) => {
  try {
    let email = identifier;
    if (!isValidEmail(identifier)) {
      email = await getUserByEmailOrUsername(identifier);
    }

    if (!email) {
      throw new Error("Invalid email or username.");
    }

    const res = await signInWithEmailAndPassword(auth, email, password);
    const userId = res.user.uid;
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);

    if (!res.user.emailVerified) {
      await signOut(auth);
      return {
        success: false,
        error: "Votre email n'a pas été vérifié. Veuillez vérifier votre email avant de vous connecter.",
      };
    }
    return {
      success: true,
      user: userDoc.data(),
    };
  } catch (err) {
    console.error(err);
    return { success: false, error: err.message };
  }
};


const logout = async () => {
  await signOut(auth);
  return { success: true };
};

export { loginWithEmailOrUsername as loginWithEmailAndPassword, logout, registerWithEmailAndPassword, isValidEmail };