import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  Box,
  FormControl,
} from '@mui/material';
import { addDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { useDropzone } from 'react-dropzone';
import { auth, db } from '../firebase/firebase';
import { AuthContext } from '../features/authContext';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const TissuScreen = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [priceType, setPriceType] = useState('Fixe');
  const [paymentType, setPaymentType] = useState('BeforeDelivery');
  const [gender, setGender] = useState('Mixte');
  const [longueur, setLongueur] = useState('');
  const [ageGroup, setAgeGroup] = useState('Adultes');
  const [images, setImages] = useState([]);
  const [hasPermission, setHasPermission] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTissus, setSelectedTissus] = useState([]);
  const [isPricePerYardEnabled, setIsPricePerYardEnabled] = useState(true);
  const [totalPrice, setTotalPrice] = useState('');
  const [video, setVideo] = useState('');
  const [progress, setProgress] = useState(0);
  const [currency, setCurrency] = useState('XOF');

  const priceTypeOptions = [
    { label: 'Fixe', value: 'Fixe' },
    { label: 'Négociable', value: 'Négociable' },
  ];

  const paymentOptions = [
    { label: 'Avant livraison', value: 'BeforeDelivery' },
    { label: 'A la livraison', value: 'AtDelivery' },
    { label: 'Après essai', value: 'AfterDelivery' },
  ];

  const tissusOptions = [
    { label: 'En coupe', value: 'InCut' },
    { label: 'En une seule pièce', value: 'OnePiece' },
  ];

  const currencyOptions = [
    { label: 'XOF', value: 'XOF' },
    { label: 'EUR', value: 'EUR' },
    { label: 'USD', value: 'USD' },
  ];

  const pickVideo = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'video/*';
    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        await uploadVideo(file);
      }
    };
    input.click();
  };

  const uploadVideo = async (file) => {
    const storageRef = ref(getStorage(), 'videos/' + new Date().getTime());
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress.toFixed());
      },
      (error) => {
        console.error(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setVideo(downloadURL);
        });
      }
    );
  };

  const uploadImages = async () => {
    const storage = getStorage();
    const uploadedImages = [];
    const userId = auth.currentUser?.uid;

    if (!userId) {
      throw new Error('User is not authenticated');
    }

    for (const uri of images) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storageRef = ref(storage, `users/${userId}/pattern_images/${new Date().getTime()}_${uri.split('/').pop()}`);
      await uploadBytes(storageRef, blob);
      const downloadURL = await getDownloadURL(storageRef);
      uploadedImages.push(downloadURL);
    }

    return uploadedImages;
  };

  const publishPattern = async () => {
    const storage = getStorage();
    if (!title || !description || (!price && isPricePerYardEnabled) || (!totalPrice && !isPricePerYardEnabled)) {
      alert('Veuillez remplir tous les champs et sélectionner au moins une image.');
      return;
    }

    if (images.length === 0 && !video) {
      alert('Veuillez sélectionner au moins une image ou une vidéo.');
      return;
    }

    setIsLoading(true);

    try {
      const downloadURLs = await Promise.all(
        images.map(async (image, index) => {
          const response = await fetch(image);
          const blob = await response.blob();
          const storageRef = ref(storage, `pattern_images/${currentUser.uid}/${Date.now()}_${index}`);
          await uploadBytes(storageRef, blob);
          return await getDownloadURL(storageRef);
        })
      );

      const docRef = await addDoc(collection(db, 'tissus'), {
        title,
        description,
        price: isPricePerYardEnabled ? price : totalPrice,
        priceType,
        paymentType,
        gender,
        longueur,
        currency,
        images: downloadURLs,
        video,
        userId: currentUser.uid,
        allowedUsers: isPrivate ? selectedUsers : [],
        isPrivate,
        timestamp: new Date(),
        type: 'tissus',
        tissusTypes: selectedTissus,
      });

      console.log('Pattern added with ID: ', docRef.id);

      setTitle('');
      setDescription('');
      setPrice('');
      setImages([]);
      setIsPrivate(false);
      setSelectedUsers([]);

      navigate('/delivery-options', { state: { articleId: docRef.id, currency: currency } });

    } catch (error) {
      console.error('Error adding pattern: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchUsers = async (queryText) => {
    setSearchQuery(queryText);
    if (queryText.trim() === '') {
      setSearchResults([]);
      return;
    }

    const q = query(
      collection(db, 'users'),
      where('name', '>=', queryText.toLowerCase()),
      where('name', '<=', queryText.toLowerCase() + '\uf8ff')
    );
    const querySnapshot = await getDocs(q);
    const results = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setSearchResults(results);
  };

  const validateTotalPriceInput = (text) => {
    const regex = /^[0-9.]*$/;
    if (regex.test(text)) {
      setTotalPrice(text);
    }
  };

  const toggleUserSelection = (userId) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.includes(userId)) {
        return prevSelectedUsers.filter((id) => id !== userId);
      } else {
        return [...prevSelectedUsers, userId];
      }
    });
  };

  const removeImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleTissusChange = (selectedItems) => {
    setSelectedTissus(selectedItems);

    const isOnePieceSelected = selectedItems.includes('OnePiece');
    const isInCutSelected = selectedItems.includes('InCut');

    const shouldDisablePricePerYard = isOnePieceSelected && !isInCutSelected;

    setIsPricePerYardEnabled(!shouldDisablePricePerYard);
  };

  const validatePriceInput = (text) => {
    const regex = /^[0-9.]*$/;
    if (regex.test(text)) {
      setPrice(text);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const newImages = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setImages([...images, ...newImages]);
    },
  });

  return (
    <Box
      sx={{
        backgroundImage: 'url("https://www.transparenttextures.com/patterns/white-linen.png")',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        py: 4
      }}
    >
      <Button onClick={() => navigate(-1)} leftIcon={<ArrowLeft />}>Retour</Button>
    <Container maxWidth="md">
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 3 }}>
            Ajouter un tissu
          </Typography>

          <Box {...getRootProps()} mb={2}>
            <input {...getInputProps()} />
            <Button variant="outlined">Sélectionner des images</Button>
          </Box>

          <Box mb={2}>
            <Button variant="outlined" onClick={pickVideo}>
              Sélectionner une vidéo
            </Button>
            <Typography variant="body2">{video ? 'Vidéo sélectionnée' : 'Aucune vidéo sélectionnée'}</Typography>
          </Box>

          <TextField
            label="Titre"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            margin="normal"
            required
          />

          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            rows={4}
            margin="normal"
            required
          />

          <Box mb={2}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Type de tissus</InputLabel>
              <Select multiple value={selectedTissus} onChange={(e) => handleTissusChange(e.target.value)} required>
                {tissusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </Box>

          <Box mb={2}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Type de prix</InputLabel>
              <Select value={priceType} onChange={(e) => setPriceType(e.target.value)} required>
                {priceTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Prix"
              value={price}
              onChange={(e) => validatePriceInput(e.target.value)}
              fullWidth
              margin="normal"
              required={isPricePerYardEnabled}
              disabled={!isPricePerYardEnabled}
            />

            <TextField
              label="Prix Total"
              value={totalPrice}
              onChange={(e) => validateTotalPriceInput(e.target.value)}
              fullWidth
              margin="normal"
              required={!isPricePerYardEnabled}
              disabled={isPricePerYardEnabled}
            />
          </Box>

          <FormControl fullWidth margin="normal">
              <InputLabel>Devise</InputLabel>
              <Select value={currency} onChange={(e) => setCurrency(e.target.value)} required>
                {currencyOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          <Box mb={2}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Type de paiement</InputLabel>
              <Select value={paymentType} onChange={(e) => setPaymentType(e.target.value)} required>
                {paymentOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </Box>

          {images.length > 0 && (
            <Box display="flex" flexWrap="wrap" mb={2}>
              {images.map((image, index) => (
                <Box key={index} position="relative" mr={2} mb={2}>
                  <img src={image.preview} alt={`Preview ${index}`} style={{ width: '100px', height: '100px' }} />
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => removeImage(index)}
                    style={{ position: 'absolute', top: 0, right: 0 }}
                  >
                    X
                  </Button>
                </Box>
              ))}
            </Box>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={publishPattern}
            disabled={isLoading}
          >
            Publier
          </Button>
        </CardContent>
      </Card>
    </Container>
    </Box>
  );
};

export default TissuScreen;
