import React, { useContext, useEffect, useState } from 'react';
import { Container, TextField, Button, Typography, Avatar, CircularProgress, Box, IconButton } from '@mui/material';
import { AuthContext } from '../features/authContext';
import { db } from '../firebase/firebase';
import { collection, query, orderBy, onSnapshot, addDoc, doc, getDoc, where, getDocs, updateDoc, startAfter, limit } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import { CircleArrowLeft } from 'lucide-react';
import {useNavigate} from 'react-router-dom';

const ClientMessagesScreen = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const location = useLocation();
  const { recipient } = location.state;
  const { currentUser } = useContext(AuthContext);
  const [lastVisible, setLastVisible] = useState(null);
  const [senderName, setSenderName] = useState('');
  const navigate = useNavigate();

  const loadMoreMessages = async () => {
    const { messages, lastVisibleMessage } = await fetchMessages(lastVisible);
    if (messages.length > 0) {
      setMessages(prevMessages => [...prevMessages, ...messages]);
      setLastVisible(lastVisibleMessage);
    }
  };

  useEffect(() => {
    const fetchSenderName = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setSenderName(userDoc.data().name);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching sender name:', error);
      }
    };

    fetchSenderName();

    const q = query(collection(db, 'messages'), orderBy('timestamp'));
    const unsubscribeMessages = onSnapshot(q, async (querySnapshot) => {
      const messagesData = [];
      for (const docSnapshot of querySnapshot.docs) {
        const data = docSnapshot.data();
        if ((data.recipientId === currentUser.uid && data.userId === recipient.uid) ||
            (data.recipientId === recipient.uid && data.userId === currentUser.uid)) {

          // Fetch profile pictures for sender and recipient
          const senderProfileDoc = await getDoc(doc(db, 'profiles', data.userId));
          const senderProfileData = senderProfileDoc.exists() ? senderProfileDoc.data() : {};
          const senderProfilePicture = senderProfileData.logo || null;

          messagesData.push({ 
            id: docSnapshot.id, 
            ...data, 
            senderProfilePicture 
          });
        }
      }
      setMessages(messagesData);
    });

    return () => {
      unsubscribeMessages();
    };
  }, [currentUser, recipient]);

  const createConversation = async (userId1, userId2) => {
    const newConversation = await addDoc(collection(db, 'conversations'), {
      participants: [userId1, userId2],
      lastMessage: "",
      lastMessageTimestamp: new Date(),
      unreadCounts: {
        [userId1]: 0,
        [userId2]: 0
      }
    });
  
    return newConversation.id;
  };
  
  const handleSendMessage = async () => {
    if (newMessage.trim() === '') {
      console.log('Message is empty');
      return;
    }
  
    if (!senderName) {
      console.log('Sender name is not available');
      return;
    }
  
    try {
      const conversationsQuery = query(collection(db, 'conversations'), where('participants', 'array-contains', currentUser.uid));
      const conversationSnapshot = await getDocs(conversationsQuery);
      let conversationExists = false;
      let conversationId = '';
  
      conversationSnapshot.forEach((doc) => {
        if (doc.data().participants.includes(recipient.uid)) {
          conversationExists = true;
          conversationId = doc.id;
        }
      });
  
      if (!conversationExists) {
        conversationId = await createConversation(currentUser.uid, recipient.uid);
      }
  
      await addDoc(collection(db, 'messages'), {
        userId: currentUser.uid,
        senderName: senderName,
        text: newMessage,
        timestamp: new Date(),
        recipientId: recipient.uid,
        read: false,
        conversationId: conversationId
      });
  
      await updateDoc(doc(db, 'conversations', conversationId), {
        lastMessage: newMessage,
        lastMessageTimestamp: new Date(),
      });
  
      console.log('Message sent:', newMessage);
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };
  
  const fetchMessages = async (lastVisible) => {
    let q = query(collection(db, 'messages'), orderBy('timestamp', 'desc'), limit(20));
  
    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }
  
    const documentSnapshots = await getDocs(q);
  
    const messages = documentSnapshots.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
    const lastVisibleMessage = documentSnapshots.docs[documentSnapshots.docs.length - 1];
  
    return { messages, lastVisibleMessage };
  };

  return (
    <Container style={styles.container}>
      <IconButton onClick={() => navigate(-1)} style={styles.backButton}>
        <CircleArrowLeft/>
      </IconButton>
      <Box style={{ flex: 1, overflowY: 'auto', marginBottom: '16px' }}>
        {messages.length === 0 ? (
          <CircularProgress />
        ) : (
          messages.map(item => (
            <Box
              key={item.id}
              style={{
                ...styles.messageContainer,
                alignSelf: item.userId === currentUser.uid ? 'flex-end' : 'flex-start',
                backgroundColor: item.userId === currentUser.uid ? '#DCF8C6' : '#ECECEC',
              }}
            >
              <Typography style={styles.messageText}>{item.text}</Typography>
              <Typography style={styles.messageDetails}>From: {item.senderName} at {new Date(item.timestamp.seconds * 1000).toLocaleString()}</Typography>
              {item.senderProfilePicture && (
                <Avatar src={item.senderProfilePicture} style={styles.avatar} />
              )}
            </Box>
          ))
        )}
      </Box>

      <Box style={styles.inputContainer}>
        <TextField
          variant="outlined"
          placeholder="Type a message..."
          fullWidth
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          multiline
        />
        <Button variant="contained" onClick={handleSendMessage} style={styles.sendButton}>
          Send
        </Button>
      </Box>
    </Container>
  );  
};

const styles = {
  backButton: {
    position: 'absolute',
    left: 10,
    top: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: 'white',
    padding: '16px',
  },
  messageContainer: {
    padding: '10px',
    borderRadius: '10px',
    marginBottom: '10px',
  },
  messageText: {
    fontSize: '16px',
    color: '#000',
  },
  messageDetails: {
    fontSize: '12px',
    color: '#666',
    marginTop: '5px',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderTop: '1px solid #ccc',
    padding: '8px',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    marginTop: '5px',
  },
  sendButton: {
    marginLeft: '8px',
  },
};

export default ClientMessagesScreen;
