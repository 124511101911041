import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { getFirestore, doc, getDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { AuthContext } from '../features/authContext';
import { useStripe, CardElement, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, IconButton } from "@mui/material";
import { CircleArrowLeft } from 'lucide-react';
import '../styles/PaymentAtDelivery.css'; 
import { db } from '../firebase/firebase';
import stripeLogo from '../assets/stripe-buttons.png';

const PaymentAtDelivery = () => {
    const location = useLocation();
    const state = location.state || {};
    const { serviceFee , currency = 'XOF', itemId = [], itemType = [] } = state;
    const elements = useElements();
    const [email, setEmail] = useState('');
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const stripe = useStripe();
    const [waitingMessage, setWaitingMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState("");
    const [transactionId, setTransactionId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState('');
    const [apiKey] = useState("naboo-d3b43650-292c-4f9e-902c-f88d812a8644.13f735f6-a6cd-4afb-9fce-9b2461bd47d0");
    const [isModalVisible, setModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        if (!location.state) {
        alert("Détails de la transaction manquants. Redirection...");
        navigate('/payment');  // Redirige vers la page d'accueil ou une autre page
        }
    }, [location, navigate]);

    useEffect(() => {
        if (transactionId) {
        const interval = setInterval(() => {
            checkTransactionStatus(transactionId);
        }, 5000);

        return () => clearInterval(interval); // Cleanup interval on component unmount
        }
    }, [transactionId]);

    const closeModal = async () => {
        setShowModal(false);
        setWaitingMessage(true); // Show waiting message
        alert("Erreur: Votre paiement n'a pas abouti. Veuillez réessayer");
        await checkTransactionStatus(transactionId); // Check transaction status
    };

    const handlePayment = async () => {
        setIsLoading(true);
        try {
        const payload = {
            amount: Math.round(serviceFee),
            amount_to_pay: Math.round(serviceFee),
            currency: 'XOF',
            method_of_payment: ["WAVE", "ORANGE_MONEY", "FREE_MONEY"],
            products: [
            {
            name: "Article",
            category: itemType[0],
            amount: Math.round(serviceFee),
            quantity: 1,
            description: "Paiement"
            }
            ],
            is_escrow: false,
            is_merchant: false,
        };

        const config = {
            method: 'post',
            url: 'https://api.naboopay.com/api/v1/transaction/create-transaction',
            headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${apiKey}`
            },
            data: payload
        };

        const response = await axios(config);
        console.log("Transaction response:", response.data);

        if (response.data.transaction_status === "pending") {
            console.log('Response Checkout URL:', response.data.checkout_url);
            setPaymentUrl(response.data.checkout_url);
            setTransactionId(response.data.order_id);
            setShowModal(true);

            const webhookPayload = {
            order_id: response.data.order_id,
            amount: response.data.amount,
            amount_to_pay: response.data.amount_to_pay,
            currency: response.data.currency,
            created_at: response.data.created_at,
            transaction_status: response.data.transaction_status,
            checkout_url: response.data.checkout_url
            };

            console.log("Webhook Payload:", webhookPayload);

            try {
            const webhookResponse = await axios.post(
                `https://us-central1-couturia-auth.cloudfunctions.net/nabooWebhook`,
                webhookPayload
            );
            console.log("Webhook Response:", webhookResponse.data);
            } catch (webhookError) {
            console.error("Webhook Error:", webhookError.response?.data || webhookError.message);
            }
        } else {
            console.error("Transaction status not pending:", response.data.transaction_status);
            alert("Payment failed: Transaction not pending.");
        }
        } catch (error) {
        console.error("Axios Error:", error.response?.data || error.message);
        if (error.response && error.response.status === 422) {
            console.error("Validation Error Details:", error.response.data);
            alert("Validation Error", JSON.stringify(error.response.data));
        } else {
            alert("Error: An error occurred. Please try again.");
        }
        } finally {
        setIsLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
      
        if (!stripe || !elements) {
          return;
        }
      
        setIsProcessing(true);
        const cardElement = elements.getElement(CardElement);
        const userName = currentUser?.name || "Guest User";
        const userId = currentUser?.uid || "guest";
      
        try {
          // Step 1: Create a PaymentIntent on your backend
          const { data: { clientSecret } } = await axios.post('https://us-central1-couturia-auth.cloudfunctions.net/createPaymentIntent', {
            amount: Math.round(serviceFee),
            currency: currency,
            itemId,
            itemType
          });
      
          // Step 2: Confirm the card payment
          const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: userName,
              },
            },
          });
      
          if (error) {
            setError(error.message);
          } else if (paymentIntent && paymentIntent.status === 'succeeded') {
            //alert('Paiement Réussi!');
            setIsSuccess(true);
            setModalMessage("Votre paiement pour la commande a été effectué avec succès !");
            // Fetch couturierId and itemName
            const couturierId = await fetchItemOwnerId(itemId[0], itemType[0]); 
            const itemName = await fetchItemName(itemId[0], itemType[0]); 

            // Add notification for the buyer
            await addDoc(collection(getFirestore(), 'notifications'), {
            message: 'Le paiement à la livraison a été accepté. Cliquez pour voir les détails de votre livraison',
            userId: currentUser.uid,
            itemId: itemId[0],
            type: itemType[0],
            status: 'pending',
            couturiaPayment: 'couturiaPayment',
            timestamp: serverTimestamp(),
            });

            // Save the order in Firestore
            const orderDetails = {
                userId: currentUser.uid,
                couturierId: couturierId,
                itemId: itemId[0],
                itemType: itemType[0],
                itemName: itemName,
                currency: currency,
                status: 'pending',
                timestamp: new Date(),
                couturiaPayment: 'couturiaPayment',
            };
            await saveOrder(orderDetails);

            // Add notification for the couturier
            await addDoc(collection(getFirestore(), 'notifications'), {
            message: `Vous avez reçu une commande de ${currentUser.name || 'un client'} pour le produit ${itemName}. Le paiement se fera à la livraison.`,
            userId: couturierId,
            itemId: itemId[0],
            type: itemType[0],
            status: 'pending',
            couturiaPayment: 'couturiaPayment',
            timestamp: serverTimestamp(),
            });
      
            // Step 3: Save the order to Firestore
            const clientOrdersCollection = collection(db, 'clientorders');
            await addDoc(clientOrdersCollection, {
              userId: userId,
              itemId,
              itemType,
              amount: serviceFee,
              status: 'pending',
              timestamp: new Date(),
            });
      
            // Step 4: Send an invoice email using Firebase Cloud Function
            /* await axios.post('https://us-central1-couturia-auth.cloudfunctions.net/sendInvoiceEmail', {
              email: email,
              amount: serviceFee,
              currency: currency,
              paymentIntentId: paymentIntent.id // Optionally include this for reference
            });
      
            alert('L\'email de facturation a été envoyé avec succès! Retrouvez votre facture dans votre adresse email.'); */
            setModalVisible(true);
            setIsLoading(false);
            navigate('/notifications');
          }
        } catch (err) {
          setError('Payment failed. Please try again.');
          console.error(err);
        } finally {
          setIsProcessing(false);
        }
      };
      
      

    const checkTransactionStatus = async (transactionId) => {
        try {
        // Get Firestore document
        const transactionDoc = await getDoc(doc(getFirestore(), 'transactions', transactionId));

        // Check if document exists
        if (!transactionDoc.exists()) {
            console.error("Transaction not found");
            setErrorMessage("Error: Transaction not found. Please try again.");
            return;
        }

        // Get transaction data
        const transactionData = transactionDoc.data();

        // Ensure transactionData is defined and has required fields
        if (!transactionData || !transactionData.transactionStatus) {
            console.error("Invalid transaction data:", transactionData);
            setErrorMessage("Error: Transaction data is invalid or missing.");
            return;
        }

        console.log("Transaction status:", transactionData.transactionStatus);

        if (transactionData.transactionStatus === 'part_paid') {
            setShowModal(false);
            alert("Succès: Paiement réussi !");
            navigate('/notifications');

            // Fetch couturierId and itemName
            const couturierId = await fetchItemOwnerId(itemId[0], itemType[0]);
            const itemName = await fetchItemName(itemId[0], itemType[0]);

            // Add notification for the buyer
            await addDoc(collection(getFirestore(), 'notifications'), {
            message: 'Le paiement à la livraison a été accepté. Cliquez pour voir les détails de votre livraison',
            userId: currentUser.uid,
            itemId: itemId[0],
            type: itemType[0],
            status: 'pending',
            couturiaPayment: 'couturiaPayment',
            timestamp: serverTimestamp(),
            });

            // Save the order in Firestore
            const orderDetails = {
            userId: currentUser.uid,
            couturierId: couturierId,
            itemId: itemId[0],
            itemType: itemType[0],
            itemName: itemName,
            currency: transactionData.currency,
            status: 'pending',
            timestamp: new Date(),
            couturiaPayment: 'couturiaPayment',
            };
            await saveOrder(orderDetails);

            // Add notification for the couturier
            await addDoc(collection(getFirestore(), 'notifications'), {
            message: `Vous avez reçu une commande de ${currentUser.name || 'un client'} pour le produit ${itemName}. Le paiement se fera à la livraison.`,
            userId: couturierId,
            itemId: itemId[0],
            type: itemType[0],
            status: 'pending',
            couturiaPayment: 'couturiaPayment',
            timestamp: serverTimestamp(),
            });
        } else {
            setWaitingMessage(false);
            setErrorMessage("Erreur: Votre paiement n'a pas abouti. Veuillez réessayer");
        }
        } catch (error) {
        setWaitingMessage(false);
        if (error.response) {
            if (error.response.data.message === "Transaction non trouvée") {
            console.error("Transaction not found, prompting user to retry.");
            setErrorMessage("Error: Transaction not found. Please try again.");
            setTransactionId(null); // Reset the transaction ID to allow retrying
            } else {
            console.error("Error checking transaction status:", error.response.data);
            setErrorMessage(`Error: Votre paiement n'a pas abouti. Veuillez réessayer: ${error.response.data.message}`);
            }
        } else if (error.request) {
            console.error("Error checking transaction status: No response received", error.request);
            setErrorMessage("Error: No response received from server while checking transaction status.");
        } else {
            console.error("Error checking transaction status:", error.message);
            setErrorMessage(`Error: An error occurred: ${error.message}`);
        }
        }
    };

    const fetchItemOwnerId = async (itemId, collectionName) => {
        try {
        
        const docRef = doc(db, collectionName, itemId);
        const itemDoc = await getDoc(docRef);
        if (itemDoc.exists()) {
            const data = itemDoc.data();
            if (data && typeof data.userId === 'string') {
            return data.userId;
            } else {
            console.error('userId is not a string or does not exist:', data.userId);
            }
        } else {
            console.error('No such document!');
        }
        } catch (error) {
        console.error('Error fetching item owner ID:', error);
        }
        return null;
    };

    const fetchItemName = async (itemId, collectionName) => {
        try {
        
        const docRef = doc(db, collectionName, itemId);
        const itemDoc = await getDoc(docRef);
        if (itemDoc.exists()) {
            const data = itemDoc.data();
            if (data && typeof data.title === 'string') {
            return data.title;
            } else {
            console.error('name is not a string or does not exist:', data.title);
            }
        } else {
            console.error('No such document!');
        }
        } catch (error) {
        console.error('Error fetching item name:', error);
        }
        return null;
    };

    const saveOrder = async (orderDetails) => {
        try {
      
        await addDoc(collection(db, 'orders'), orderDetails);
        console.log('Order saved successfully');
        } catch (error) {
        console.error('Error saving order:', error);
        }
    };

    return (
        <Box
      sx={{
        backgroundImage: 'url("https://www.transparenttextures.com/patterns/white-linen.png")',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        py: 4
      }}
    >
        <Typography variant="body2" sx={{ color: 'red', textAlign: 'center' }}>
        Attention des frais supplémentaires peuvent s'appliquer par notre partenaire si vous choisissez le paiement mobile.
        </Typography>

        <div >
            <IconButton onClick={() => navigate(-1)} style={styles.backButton}>
                <CircleArrowLeft />
            </IconButton>
            <div style={styles.contentContainer}>
            <img src={stripeLogo} style={{ width: '150px', marginBottom: '20px' }} />

                <form onSubmit={handleSubmit} style={styles.form}>
                    <h2 style={styles.heading}>Payer les frais de service</h2>
                    <Typography variant="h6" style={styles.amountText}>
                        Total: {currency} {Math.round(serviceFee)}
                    </Typography>
                    <TextField
                        label="E-mail"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={styles.input}
                    />
                    <Box mt={2} mb={2} width="100%">
                        <CardElement options={styles.cardField} />
                    </Box>
                    <button type="submit" disabled={isProcessing} style={styles.button}>
                        {isProcessing ? 'Processing...' : 'Payer'}
                    </button>
                    {error && <div style={styles.error}>{error}</div>}
                </form>
                <div className="payment-at-delivery-container">
          {showModal && (
            <div style={styles.modal}>
              <div style={styles.modalContent}>
                <button style={styles.closeButton} onClick={closeModal}>X</button>
                <p>
                  Veuillez compléter votre paiement en cliquant{' '}
                  <a href={paymentUrl} target="_blank" rel="noopener noreferrer">ici</a>.
                </p>
              </div>
            </div>
          )}
          {waitingMessage && <p style={styles.waitingMessage}>Waiting for payment confirmation...</p>}
          <p style={styles.errorMessage}>{errorMessage}</p>
          <button onClick={handlePayment} disabled={isLoading} style={styles.payButton}>
            {isLoading ? 'Processing...' : 'Paiement mobile (WAVE, Orange Money, Free Money)'}
          </button>
        </div>
      </div>
    </div>
        </Box>
    );
};

const styles = {
    pageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Centrer horizontalement
        justifyContent: 'center', // Centrer verticalement
        width: '100%',
        maxWidth: '600px',
        height: '100vh', // Prend toute la hauteur de la fenêtre visible
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: '#bcdcf1',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        margin: '0 auto', // Centre horizontalement dans son parent
    },
    
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '400px',
        width: '100%',
    },
    heading: {
        marginBottom: '20px',
        fontSize: '24px',
        color: '#333',
    },
    amountText: {
        marginBottom: '20px',
        fontSize: '18px',
        color: '#333',
    },
    input: {
        marginBottom: '20px',
    },
    cardField: {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#9e2146',
            },
        },
    },
    button: {
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '20px',
    },
    payButton: {
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '20px',
    },
    error: {
        color: 'red',
        marginTop: '10px',
    },
    backButton: {
        marginLeft: '16px',
    },
    waitingMessage: {
        textAlign: 'center',
        marginTop: '20px',
        fontSize: '16px',
      },
      errorMessage: {
        color: 'red',
        textAlign: 'center',
        marginTop: '10px',
      },
      modal: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '20px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
      },
      modalContent: {
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: '500',
      },
      closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '18px',
        cursor: 'pointer',
      },
      warningText: {
        color: 'red',
        textAlign: 'center',
      },
};

export default PaymentAtDelivery;
