import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { CartContext } from '../features/cartContext';
import { AuthContext } from '../features/authContext';
import { collection, addDoc, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { Box, Slider, IconButton } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';

const Container = styled.div`
  flex: 1;
`;

const CenteredContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CartItemCard = styled.div`
  margin: 8px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const CartItemContent = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
`;

const CartItemDetails = styled.div`
  margin-left: 16px;
  flex: 1;
`;

const OrderSummaryCard = styled.div`
  margin: 8px;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

const MessageBox = styled.div`
  background-color: #fff3f3;
  border: 1px solid red;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AppBar = styled.div`
  background-color: #1976d2;
  color: white;
  padding: 8px;
  text-align: center;
  font-size: 1.5rem;
`;

const Dialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 300px;
  padding: 16px;
  z-index: 1000;
`;

const DialogTitle = styled.h2`
  margin: 0;
`;

const DialogContent = styled.div`
  margin: 16px 0;
`;

const DialogActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const Button = styled.button`
  background-color: ${props => props.color === 'primary' ? '#1976d2' : '#f50057'};
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const Typography = styled.p`
  margin: 0;
  font-size: ${props => props.$variant === 'h6' ? '1.25rem' : '1rem'};
  font-weight: ${props => props.$variant === 'h6' ? 'bold' : 'normal'};
`;

const Video = styled.video`
  width: 40%;
  border-radius: 8px;
`;

const ClientHomePage = () => {
  const { cartItems, removeFromCart, updateQuantity, loading } = useContext(CartContext) || {};
  const { currentUser } = useContext(AuthContext) || {};
  const navigate = useNavigate();
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [userGender, setUserGender] = useState('');
  const [showQuoteMessage, setShowQuoteMessage] = useState(true);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [isEnlargedModalVisible, setEnlargedModalVisible] = useState(false);
  const [enlargedMedia, setEnlargedMedia] = useState(null);
  const [volume, setVolume] = useState(1.0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoHovered, setIsVideoHovered] = useState(false);
  const videoRef = useRef(null);

  const onVideoPlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Utilisation d'un hook pour vérifier si le panier est vide
  useEffect(() => {
    if (!currentUser && cartItems?.length === 0) {
      console.log('Aucun utilisateur connecté, mais le panier est vide.');
    }
  }, [currentUser, cartItems]);

  useEffect(() => {
    const checkUserProfile = async () => {
      if (currentUser && currentUser.uid) {
        const userProfile = await getUserProfile(currentUser.uid);
        if (!userProfile || !userProfile.gender) {
          setModalMessage('Vous devez renseigner votre profil avant de demander un devis. Souhaitez-vous le faire maintenant?');
          setModalVisible(true);
        } else {
          setUserGender(userProfile.gender);
        }
      }
    };

    checkUserProfile();
  }, [currentUser]);

  const calculateTotalPrice = () => {
    return cartItems?.reduce((total, item) =>
      total + (formatPrice(item) * item.selectedYards * item.quantity), 0
    ) || 0;
  };

  const calculateServiceFee = (totalPrice) => {
    return totalPrice * 0.02;
  };

  const formatPrice = (item) => {
    const price = item.currentPrice ?? item.price;
    const parsedPrice = parseFloat(price);
    return isNaN(parsedPrice) ? 0 : parsedPrice;
  };

  const totalPrice = calculateTotalPrice();
  const serviceFee = calculateServiceFee(totalPrice);
  const finalTotalPrice = totalPrice + serviceFee;

  const getUserMeasurements = async (userId) => {
    const measurementsRef = collection(db, 'mesures');
    const q = query(measurementsRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const measurements = [];
    querySnapshot.forEach((doc) => {
      measurements.push(doc.data());
    });
    return measurements.length > 0 ? measurements[0] : null;
  };

  const getUserProfile = async (userId) => {
    const profileRef = doc(db, 'profiles', userId);
    const profileDoc = await getDoc(profileRef);
    if (profileDoc.exists()) {
      return profileDoc.data();
    }
    return null;
  };

  const getCouturierName = async (userId) => {
    const userDocRef = doc(db, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      return userDocSnap.data().name ?? null;
    }
    return null;
  };

  const isMeasurementsComplete = (measurements, gender) => {
    const requiredFields = gender === 'Homme'
      ? ['epaule', 'masse', 'cou', 'longueurManche', 'longueurPantalon', 'ceinture', 'cuisse']
      : ['epaule', 'poitrine', 'taille', 'longueurEntiere', 'longueurManche', 'tourDeManche'];

    return requiredFields.every(field => measurements[field] && measurements[field].trim() !== '');
  };
  const handleVolumeChange = (event, newValue) => {
    const newVolume = newValue / 100;
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  const handleRequestQuote = async () => {
    if (!currentUser) {
      setShowLoginPrompt(true);
      return;
    }
  
    try {
      const userId = currentUser.uid;
      const userMeasurements = await getUserMeasurements(userId);
      const userProfile = await getUserProfile(userId);
      const userName = await getCouturierName(userId);
  
      if (!userMeasurements || !isMeasurementsComplete(userMeasurements, userProfile.gender)) {
        setUserGender(userProfile.gender);
        setModalMessage('Vous devez renseigner vos mesures avant de poursuivre. Souhaitez-vous le faire maintenant?');
        setModalVisible(true);
        return;
      }
  
      let allNewQuotes = true;
  
      const quotePromises = cartItems.map(async (item) => {
        const couturierId = item.userId;
        const couturierName = await getCouturierName(couturierId);
  
        const q = query(
          collection(db, 'notifications'),
          where('userId', '==', couturierId),
          where('itemId', '==', item.id),
        );
  
        const querySnapshot = await getDocs(q);
  
        if (querySnapshot.empty) {
          allNewQuotes = false;
          await addDoc(collection(db, 'notifications'), {
            userId: couturierId,
            itemId: item.id,
            createdAt: new Date(),
            read: false,
          });
        }
      });
  
      await Promise.all(quotePromises);
  
      const uniqueCouturierNames = [...new Set(cartItems.map(item => getCouturierName(item.userId)))];
  
      const message = uniqueCouturierNames.length === 1
        ? `Demande de devis envoyée à ${uniqueCouturierNames[0]}.`
        : `Demande de devis envoyée à ${uniqueCouturierNames.join(', ')}.`;
        
      alert(message);
    } catch (error) {
      console.error('Error sending notifications:', error);
      alert('Une erreur est survenue lors de l\'envoi des demandes de devis.');
    }
  };
  

  const handleOrder = () => {
    console.log(finalTotalPrice,cartItems.map(item => item.id),cartItems[0]?.currency)
    const notification = {
      totalPrice: finalTotalPrice,
      itemId: cartItems.map(item => item.id),
      currency: cartItems[0]?.currency,
      itemType: cartItems.map(item => item.type),
      serviceFee: serviceFee,
    };

    cartItems.forEach(item => {
      removeFromCart(item.id);
    });

    navigate('/delivery-screen', {
      state: {
        totalPrice: notification.totalPrice,
        itemId: notification.itemId,
        currency: notification.currency,
        itemType: notification.itemType,
        serviceFee: notification.serviceFee,
      }
    });
  };

  const handleModalResponse = (response) => {
    setModalVisible(false);
    if (response === 'yes') {
      if (modalMessage.includes('profil')) {
        navigate('/auth');
      } else {
        navigate('/mesures', { state: { gender: userGender } });
      }
    }
  };

  const handleMediaClick = (media) => {
    setEnlargedMedia(media);
    setEnlargedModalVisible(true);
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url("https://www.transparenttextures.com/patterns/white-linen.png")',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        py: 4
      }}
    >
    <Container>
    <AppBar>
        Panier
      </AppBar>
      {showLoginPrompt && (
        <Dialog>
          <DialogTitle>Connexion requise</DialogTitle>
          <DialogContent>
            Vous devez être connecté pour demander un devis. Voulez-vous vous connecter maintenant ?
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => navigate('/auth')}>Se connecter</Button>
            <Button color="secondary" onClick={() => setShowLoginPrompt(false)}>Annuler</Button>
          </DialogActions>
        </Dialog>
      )}
  
      {isModalVisible && (
        <Dialog>
          <DialogTitle>Attention</DialogTitle>
          <DialogContent>
            {modalMessage}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => handleModalResponse('yes')}>Oui</Button>
            <Button color="secondary" onClick={() => setModalVisible(false)}>Non</Button>
          </DialogActions>
        </Dialog>
      )}
  
      {loading ? (
        <CenteredContainer>
          <div>Loading...</div>
        </CenteredContainer>
      ) : cartItems?.length === 0 ? (
        <CenteredContainer>
          <Typography $variant="body1">Votre panier est vide.</Typography>
        </CenteredContainer>
      ) : (
        <>
          {cartItems.map(item => (
            <CartItemCard key={item.id}>
              <CartItemContent>
                {/* Images Section */}
        {item.images && item.images.length > 0 && (
          <Box>
            <img
            src={item.images[0]} // Display only the first image
            alt={`Product Image`}
            style={{ width: '30%', height: '30%', marginBottom: '10px', cursor: 'pointer' }}
            onClick={() => handleMediaClick(item.images[0])}
          />
          </Box>
        )} : {/* Video Section */}
        {item.video && (
          <Box onMouseEnter={() => setIsVideoHovered(true)} onMouseLeave={() => setIsVideoHovered(false)} sx={{ position: 'relative', cursor: 'pointer' }}>
            <video
              ref={videoRef}
              src={item.video}
              style={{ width: '30%', height: '30%', marginBottom: '10px', cursor: 'pointer' }}
              onClick={() => handleMediaClick(item.video)}
              controls
            />
            {isVideoHovered && (
              <IconButton
                onClick={onVideoPlayPause}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: 'white',
                }}
              >
                {isPlaying ? <PlayArrow fontSize="large" /> : <PlayArrow fontSize="large" />}
              </IconButton>
            )}
            <Slider
              value={volume * 100}
              onChange={handleVolumeChange}
              aria-labelledby="volume-slider"
              sx={{ width: '100px', position: 'absolute', bottom: '10px', left: '10px' }}
            />
          </Box>
        )}
                <CartItemDetails>
                  <Typography $variant="h6">{item.title || 'Nom de l\'article non disponible'}</Typography>
                  <Typography>{`${item.currency} ${formatPrice(item) * item.selectedYards}`}</Typography>
                </CartItemDetails>
              </CartItemContent>
              <div style={{ padding: '8px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button onClick={() => updateQuantity(item.id, item.quantity - 1)} disabled={item.quantity === 1}>-</Button>
                    <Typography style={{ margin: '0 8px' }}>{item.quantity}</Typography>
                    <Button onClick={() => updateQuantity(item.id, item.quantity + 1)}>+</Button>
                  </div>
                  <Typography>{`${item.currency} ${(formatPrice(item) * item.selectedYards * item.quantity).toFixed(2)}`}</Typography>
                  <Button onClick={() => removeFromCart(item.id)}>Supprimer</Button>
                </div>
              </div>
            </CartItemCard>
          ))}
  
          <OrderSummaryCard>
            <div style={{ padding: '8px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography $variant="h6">Commande:</Typography>
                  <Typography>{`${cartItems[0]?.currency} ${totalPrice.toFixed(2)}`}</Typography>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography $variant="h6">Frais acheteurs:</Typography>
                  <Typography>{`${cartItems[0]?.currency} ${serviceFee.toFixed(2)}`}</Typography>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography $variant="h6">Total:</Typography>
                  <Typography>{`${cartItems[0]?.currency} ${finalTotalPrice.toFixed(2)}`}</Typography>
                </div>
              </div>
            </div>
          </OrderSummaryCard>
  
          {cartItems.every(item => item.type === 'tissus' || item.type === 'accessoires' || item.type === 'shoes' || item.type === 'readytowear') ? (
            <Button variant="contained" color="primary" onClick={handleOrder} style={{ margin: '16px' }}>
              Commander
            </Button>
          ) : (
            <>
              {showQuoteMessage && (
                <MessageBox>
                  <Typography style={{ color: 'red', fontWeight: 'bold' }}>La demande de devis est gratuite.</Typography>
                  <Button onClick={() => setShowQuoteMessage(false)}>Fermer</Button>
                </MessageBox>
              )}
              <Button variant="contained" color="primary" onClick={handleRequestQuote} style={{ margin: '16px' }}>
                Demandez un devis
              </Button>
            </>
          )}
        </>
      )}
    </Container>
    </Box>
  );
  
};

export default ClientHomePage;

