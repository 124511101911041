import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FavoritesContext } from '../features/favoritesContext';
import { Box, Typography, Card, CardMedia, CardContent, Button, Grid } from '@mui/material';

const FavoritesScreen = () => {
  const { favorites, removeFromFavorites } = useContext(FavoritesContext);
  const navigate = useNavigate();

  const handleNavigateToDetail = (item) => {
    navigate(`/detail/${item.id}`, { state: { post: item } });
  };

  const renderFavoriteItem = (item) => (
    <Grid item xs={12} sm={6} md={4} key={item.id}>
      <Card 
        sx={{ maxWidth: 345, position: 'relative', cursor: 'pointer' }}
        onClick={() => handleNavigateToDetail(item)}
      >
        <CardMedia
          component="img"
          height="200"
          image={item.images[0]}
          alt={item.title}
          sx={{
            filter: item.availability === 'Epuisée' ? 'blur(5px)' : 'none'
          }}
        />
        <CardContent>
          <Typography variant="h6" component="div">
            {item.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {item.description}
          </Typography>
          <Button
            size="small"
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();  
              removeFromFavorites(item.id);
            }}
            sx={{ mt: 2 }}
          >
            Supprimer
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <Box sx={{ p: 3 }}>
        <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
            Retour
          </Button>
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Produits favoris
      </Typography>
      <Grid container spacing={3}>
        {favorites.length > 0 ? (
          favorites.map((item) => renderFavoriteItem(item))
        ) : (
          <Typography variant="h6" align="center" sx={{ width: '100%', mt: 4 }}>
            Aucun produit favori pour le moment.
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default FavoritesScreen;
