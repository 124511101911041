import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Avatar,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
} from '@mui/material';
import { doc, setDoc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { AuthContext } from '../features/authContext';
import { db } from '../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ParentComponent = ({ profile = {}, setProfile }) => {
  const [errors, setErrors] = useState({});
  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [country, setCountry] = useState(profile.country || '');
  const [city, setCity] = useState(profile.city || '');
  const { currentUser } = useContext(AuthContext);
  const cloudName = 'dih19xygi';
  const navigate = useNavigate();

  useEffect(() => {
    axios.get("https://api.countrystatecity.in/v1/countries", {
      headers: { 'X-CSCAPI-KEY': "S09JY1RnWlBuaWV6U0pQTHd3QzVSV2RheE9EcENhWXN5RlpoQlBNRQ==" },
    }).then(response => {
      const countryArray = response.data.map(country => ({
        value: country.iso2,
        label: country.name,
      }));
      setCountryData(countryArray);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  const handleCity = (countryCode) => {
    axios.get(`https://api.countrystatecity.in/v1/countries/${countryCode}/cities`, {
      headers: { 'X-CSCAPI-KEY': "S09JY1RnWlBuaWV6U0pQTHd3QzVSV2RheE9EcENhWXN5RlpoQlBNRQ==" },
    }).then(response => {
      const cityArray = response.data.map(city => ({
        value: city.id,
        label: city.name,
      }));
      setCityData(cityArray);
    }).catch(error => {
      console.log(error);
    });
  };

  const validateFields = () => {
    let valid = true;
    let errors = {};

    if (!profile.phone) {
      valid = false;
      errors.phone = "Le numéro de téléphone est obligatoire.";
    }

    if (!profile.gender) {
      valid = false;
      errors.gender = "Le genre est obligatoire.";
    }

    setErrors(errors);
    return valid;
  };

  const handleSaveProfile = async () => {
    if (!currentUser || !currentUser.uid) {
      alert('Erreur', 'Utilisateur non connecté');
      return;
    }

    if (validateFields()) {
      try {
        const profileData = {
          ...profile,
          uid: currentUser.uid,
        };
        await setDoc(doc(db, 'profiles', currentUser.uid), profileData);
        navigate("/couturier-main");
      } catch (error) {
        alert('Erreur', 'Erreur lors de la sauvegarde du profil');
        console.error('Erreur lors de la sauvegarde du profil:', error);
      }
    }
  };

  const pickImage = async (event) => {
    if (!currentUser || !currentUser.uid) {
      alert('Erreur', 'Utilisateur non connecté');
      return;
    }

    const file = event.target.files[0];
    if (file) {
      try {
        const storage = getStorage();
        const storageRef = ref(storage, `profile_pictures/${currentUser.uid}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        setProfile({ ...profile, logo: downloadURL });
      } catch (error) {
        alert('Erreur', 'Erreur lors du chargement de l\'image');
        console.error('Erreur lors du chargement de l\'image:', error);
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Éditer le profil
      </Typography>

      <Box textAlign="center" mb={2}>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="profile-image-upload"
          type="file"
          onChange={pickImage}
        />
        <label htmlFor="profile-image-upload">
          <Button variant="contained" color="primary" component="span">
            Upload Photo
          </Button>
        </label>
        <Box mt={2}>
          {profile.logo ? (
            <Avatar src={profile.logo} sx={{ width: 120, height: 120, margin: '0 auto' }} />
          ) : (
            <Avatar sx={{ width: 120, height: 120, margin: '0 auto', bgcolor: 'gray' }}>
              <Typography variant="h6">+</Typography>
            </Avatar>
          )}
        </Box>
      </Box>

      <FormControl fullWidth margin="normal">
        <InputLabel>Genre</InputLabel>
        <Select
          value={profile.gender || ''}
          onChange={(e) => setProfile({ ...profile, gender: e.target.value })}
        >
          <MenuItem value="Non Binaire">Non Binaire</MenuItem>
          <MenuItem value="Homme">Homme</MenuItem>
          <MenuItem value="Femme">Femme</MenuItem>
        </Select>
        {errors.gender && <Typography color="error">{errors.gender}</Typography>}
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel>Pays</InputLabel>
        <Select
          value={country || ''}
          onChange={(e) => {
            setCountry(e.target.value);
            setProfile({ ...profile, country: e.target.options[e.target.selectedIndex].text });
            handleCity(e.target.value);
          }}
        >
          {countryData.map((country) => (
            <MenuItem key={country.value} value={country.value}>
              {country.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel>Ville</InputLabel>
        <Select
          value={city || ''}
          onChange={(e) => {
            setCity(e.target.value);
            setProfile({ ...profile, city: e.target.options[e.target.selectedIndex].text });
          }}
        >
          {cityData.map((city) => (
            <MenuItem key={city.value} value={city.value}>
              {city.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        margin="normal"
        label="Téléphone"
        value={profile.phone || ''}
        onChange={(e) => setProfile({ ...profile, phone: e.target.value })}
        error={!!errors.phone}
        helperText={errors.phone}
      />

      <TextField
        fullWidth
        margin="normal"
        label="Biographie"
        value={profile.bio || ''}
        onChange={(e) => setProfile({ ...profile, bio: e.target.value })}
        multiline
        rows={4}
      />

      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
        onClick={handleSaveProfile}
      >
        Sauvegarder le profil
      </Button>
    </Container>
  );
};

const CouturierProfile = () => {
  const [profile, setProfile] = useState({
    city: '',
    phone: '',
    bio: '',
    logo: '',
    gender: '',
    country: '',
  });

  return <ParentComponent profile={profile} setProfile={setProfile} />;
};

export default CouturierProfile;
