import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Typography,
  Container,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  TextField,
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Modal
} from '@mui/material';
import { Mail, ShoppingCart, CircleUserRound, Bell } from 'lucide-react';
import { CartContext } from '../features/cartContext';
import { collection, getDocs, doc, getDoc, query, orderBy, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../features/authContext';
import OfferCard from './OfferCard';
import { Favorite, FavoriteBorder } from '@mui/icons-material';

const newArrivalsContainer = {
  padding: '20px',
  backgroundColor: '#f9f9f9',
  alignItems: 'center',
  borderBottom: '1px solid #ddd',
  marginBottom: '2rem', 
};

const newArrivalsTitle = {
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#333',
  textTransform: 'uppercase',
  letterSpacing: '1.2px',
  marginBottom: '1rem',
  marginTop: '2rem',
};
const HomeClient = () => {
  const [posts, setPosts] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [shoes, setShoes] = useState([]);
  const { addToCart, cartItems } = useContext(CartContext);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [unreadCount, setUnreadCount] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const [readytowear, setReadytowear] = useState([]);
  const readytowearContainerRef = useRef(null);
  const postsContainerRef = useRef(null);
  const fabricsContainerRef = useRef(null);
  const accessoriesContainerRef = useRef(null);
  const shoesContainerRef = useRef(null);
  const [outOfStockModal, setOutOfStockModal] = useState(false);
  const cartItemCount = cartItems.length;
  // Ajout d'un état pour les favoris
  const [favorites, setFavorites] = useState([]);
  
  const toggleFavorite = (id) => {
    setFavorites((prevFavorites) =>
      prevFavorites.includes(id)
        ? prevFavorites.filter((favId) => favId !== id)
        : [...prevFavorites, id]
    );
  };

  const SectionWithButton = ({ title, items, renderItem, category, containerRef }) => {
    const navigate = useNavigate();

    const handleViewAll = () => {
        navigate(`/category/${category}`);
    };

    return (
        <Box sx={newArrivalsContainer}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5" sx={newArrivalsTitle}>
                    {title}
                </Typography>
                <Button variant="outlined" onClick={handleViewAll}>
                    Voir tout
                </Button>
            </Box>
            <div ref={containerRef} style={{ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap' }}>
                {items.map((item) => renderItem({ item, category }))}
            </div>
        </Box>
    );
};

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        let q;
        if (searchText.length > 0 && selectedGender) {
          q = query(
            collection(db, 'patterns'),
            where('title', '>=', searchText),
            where('title', '<=', searchText + '\uf8ff'),
            where('gender', '==', selectedGender)
          );
        } else if (searchText.length > 0) {
          q = query(
            collection(db, 'patterns'),
            where('title', '>=', searchText),
            where('title', '<=', searchText + '\uf8ff')
          );
        } else if (selectedGender) {
          q = query(collection(db, 'patterns'), where('gender', '==', selectedGender));
        } else {
          q = collection(db, 'patterns');
        }

        const querySnapshot = await getDocs(q);
        const results = await Promise.all(
          querySnapshot.docs.map(async docSnapshot => {
            const postData = docSnapshot.data();
            const userDocRef = doc(db, 'users', postData.userId);
            const userDocSnap = await getDoc(userDocRef);
            const userData = userDocSnap.exists() ? userDocSnap.data() : {};
            return { id: docSnapshot.id, ...postData, userName: userData.name };
          })
        );
        setSearchResults(results);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    fetchSearchResults();
  }, [searchText, selectedGender]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const qPatterns = query(collection(db, 'patterns'), orderBy('timestamp', 'desc'));
        const qFabrics = query(collection(db, 'tissus'), orderBy('timestamp', 'desc'));
        const qAccessories = query(collection(db, 'accessories'), orderBy('timestamp', 'desc'));
        const qShoes = query(collection(db, 'shoes'), orderBy('timestamp', 'desc'));
        const qReadytowear = query(collection(db,'readytowear'), orderBy('timestamp', 'desc'));


        const [patternsSnapshot, fabricsSnapshot, accessoriesSnapshot, shoesSnapshot, readytowearSnapshot] = await Promise.all([
          getDocs(qPatterns),
          getDocs(qFabrics),
          getDocs(qAccessories),
          getDocs(qShoes),
          getDocs(qReadytowear)
        ]);

        const patternsData = await Promise.all(
          patternsSnapshot.docs.map(async docSnapshot => {
            const postData = docSnapshot.data();
            const userDocRef = doc(db, 'users', postData.userId);
            const userDocSnap = await getDoc(userDocRef);
            const userData = userDocSnap.exists() ? userDocSnap.data() : {};
            return { id: docSnapshot.id, ...postData, userName: userData.name };
          })
        );

        const fabricsData = await Promise.all(
          fabricsSnapshot.docs.map(async docSnapshot => {
            const postData = docSnapshot.data();
            const userDocRef = doc(db, 'users', postData.userId);
            const userDocSnap = await getDoc(userDocRef);
            const userData = userDocSnap.exists() ? userDocSnap.data() : {};
            return { id: docSnapshot.id, ...postData, userName: userData.name };
          })
        );

        const readytowearData = await Promise.all(
          readytowearSnapshot.docs.map(async docSnapshot => {
            const postData = docSnapshot.data();
            const userDocRef = doc(db, 'users', postData.userId);
            const userDocSnap = await getDoc(userDocRef);
            const userData = userDocSnap.exists() ? userDocSnap.data() : {};
            return { id: docSnapshot.id, ...postData, userName: userData.name };
          })
        );

        const accessoriesData = await Promise.all(
          accessoriesSnapshot.docs.map(async docSnapshot => {
            const postData = docSnapshot.data();
            const userDocRef = doc(db, 'users', postData.userId);
            const userDocSnap = await getDoc(userDocRef);
            const userData = userDocSnap.exists() ? userDocSnap.data() : {};
            return { id: docSnapshot.id, ...postData, userName: userData.name };
          })
        );

        const shoesData = await Promise.all(
          shoesSnapshot.docs.map(async docSnapshot => {
            const postData = docSnapshot.data();
            const userDocRef = doc(db, 'users', postData.userId);
            const userDocSnap = await getDoc(userDocRef);
            const userData = userDocSnap.exists() ? userDocSnap.data() : {};
            return { id: docSnapshot.id, ...postData, userName: userData.name };
          })
        );

        setPosts(patternsData);
        setFabrics(fabricsData);
        setAccessories(accessoriesData);
        setShoes(shoesData);
        setReadytowear(readytowearData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    if (!currentUser) return;

    const unsubscribe = onSnapshot(
      query(collection(db, 'notifications'), where('userId', '==', currentUser.uid)),
      (querySnapshot) => {
        const unreadNotifications = querySnapshot.docs.filter(doc => !doc.data().read).length;
        setNotificationCount(unreadNotifications);
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  const handleCardClick = (id, category, newPrice, post) => {
    if (newPrice === undefined) {
      console.error('newPrice is undefined');
      return;
    }
  
    navigate(`/detail/${id}/${category}`, { state: { newPrice, post } });
  };

  const getFirstThreeWords = (description) => {
    const words = description.split(' ');
    return words.slice(0, 3).join(' ') + (words.length > 3 ? '...' : '');
  };

  const formatPrice = (price, discount) => {
    // Convertir les entrées en nombres
    const originalPrice = parseFloat(price);
    const discountValue = parseFloat(discount);

    // Vérifiez que les valeurs sont des nombres valides
    if (isNaN(originalPrice) || isNaN(discountValue)) {
      return { originalPrice: 'N/A', discountedPrice: 'N/A' };
    }

    const newPrice = originalPrice - (originalPrice * discountValue / 100);
    return { originalPrice: originalPrice.toFixed(2), newPrice: newPrice.toFixed(2) };
  };

  const showOutOfStockModal = () => {
    setOutOfStockModal(true);
  };

  const renderItem = ({ item, category }) => {
    const hasPromotion = item.promotion && item.promotion > 0;
    const price = parseFloat(item.price) || 0;
    const promotion = parseFloat(item.promotion) || 0;
    const { originalPrice, newPrice } = formatPrice(price, promotion);
    const isFavorite = favorites.includes(item.id);

    return (
      <Box key={item.id} sx={{ minWidth: 250, display: 'inline-block', margin: 1, position: 'relative' }}>
        <Typography variant="body2" color="text.secondary">
          {item.userName} a publié
        </Typography>
        <Card style={{ margin: 8 }}>
          <CardMedia
            component={item.video ? 'video' : 'img'}
            height="140"
            image={item.images && Array.isArray(item.images) && item.images.length > 0 ? item.images[0] : undefined}
            src={item.video}
            controls={!!item.video}
            alt={item.title}
            style={item.availability === 'Epuisée' ? { filter: 'blur(5px)' } : {}}
          />
          {item.availability === 'Epuisée' && (
            <Typography
              variant="caption"
              color="error"
              sx={{ position: 'absolute', top: 8, left: 8, backgroundColor: 'rgba(255, 255, 255, 0.7)', padding: '2px 4px', borderRadius: 2 }}
            >
              Épuisé
            </Typography>
          )}
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography gutterBottom variant="h5" component="div">
                {item.title}
              </Typography>
              <IconButton
                onClick={() => toggleFavorite(item.id)}
                color={isFavorite ? 'error' : 'default'}
              >
                {isFavorite ? <Favorite /> : <FavoriteBorder />}
              </IconButton>
            </Box>
            <Typography variant="body2" color="text.secondary">
              {getFirstThreeWords(item.description)}
            </Typography>
            {hasPromotion ? (
              <Typography variant="body2" color="text.secondary">
                Prix: <span style={{ textDecoration: 'line-through' }}>{originalPrice} {item.currency}</span>{' '}
                <span style={{ color: 'red' }}>{newPrice} {item.currency}</span>
              </Typography>
            ) : (
              <Typography variant="body2" color="text.secondary">
                Prix: {price} {item.currency}
              </Typography>
            )}
            <Button 
              variant="contained" 
              onClick={() => {
                if (item.availability === 'Epuisée') {
                  showOutOfStockModal();
                } else {
                  handleCardClick(item.id, category, newPrice, item);
                }
              }} 
              disabled={item.availability === 'Epuisée'}
              style={{ marginTop: 8 }}
            >
              Voir les détails
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  };

return (
  <Box
    sx={{
      backgroundImage: 'url("https://www.transparenttextures.com/patterns/45-degree-fabric-light.png")',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      minHeight: '100vh',
      py: 4
    }}
  >
    <Container maxWidth="lg">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Tableau de bord
          </Typography>
          <IconButton color="inherit" onClick={() => navigate('/notifications')}>
            <Badge badgeContent={notificationCount} color="secondary">
              <Bell />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={() => navigate('/messages')}>
            <Badge badgeContent={unreadCount} color="secondary">
              <Mail />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={() => navigate('/cart')}>
            <Badge badgeContent={cartItemCount} color="secondary">
              <ShoppingCart />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={() => navigate('/profile')}>
            <CircleUserRound />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box mt={4}>
        <Typography variant="h4" align="center" gutterBottom>
          Bienvenue, {currentUser?.name}
        </Typography>
        <TextField
          label="Rechercher des modèles..."
          variant="outlined"
          fullWidth
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ margin: 8 }}
        />

        {/* Boutons de filtre pour les catégories */}
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            variant={selectedCategory === '' ? "contained" : "outlined"}
            onClick={() => setSelectedCategory('')}
            style={{ margin: 4 }}
          >
            Tous
          </Button>
          <Button
            variant={selectedCategory === 'readytowear' ? "contained" : "outlined"}
            onClick={() => setSelectedCategory('readytowear')}
            style={{ margin: 4 }}
          >
            Prêt à porter
          </Button>
          <Button
            variant={selectedCategory === 'tissus' ? "contained" : "outlined"}
            onClick={() => setSelectedCategory('tissus')}
            style={{ margin: 4 }}
          >
            Tissus
          </Button>
          <Button
            variant={selectedCategory === 'patterns' ? "contained" : "outlined"}
            onClick={() => setSelectedCategory('patterns')}
            style={{ margin: 4 }}
          >
            Patrons de couture
          </Button>
          <Button
            variant={selectedCategory === 'shoes' ? "contained" : "outlined"}
            onClick={() => setSelectedCategory('shoes')}
            style={{ margin: 4 }}
          >
            Chaussures
          </Button>
          <Button
            variant={selectedCategory === 'accessories' ? "contained" : "outlined"}
            onClick={() => setSelectedCategory('accessories')}
            style={{ margin: 4 }}
          >
            Accessoires
          </Button>
        </Box>

        {/* Résultats de recherche ou affichage des sections */}
        <Box mt={4}>
          {searchText.length > 0 || selectedGender ? (
            <Box display="flex" overflow="auto" whiteSpace="nowrap">
              {searchResults.map(item => renderItem({ item }))}
            </Box>
          ) : (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/ProductListScreen')}
          sx={{ marginBottom: '1rem', width: '300px' }} // Set a fixed width for the button
        >
          Créez une commande
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigate('/fabrictodesign')}
          sx={{ width: '400px' }} // Set a fixed width for the button
        >
          Créez un modèle de couture avec notre IA
        </Button>
      </Box>

              {outOfStockModal && (
                <Modal severity="warning" onClose={() => setOutOfStockModal(false)}>
                  Cet article est actuellement en rupture de stock.
                </Modal>
              )}

              {/* Sections avec filtres appliqués */}
              {selectedCategory === '' || selectedCategory === 'readytowear' ? (
                <SectionWithButton
                    title="Prêt à porter"
                    items={readytowear}
                    renderItem={renderItem}
                    category="readytowear"
                    containerRef={readytowearContainerRef}
                />
            ) : null}

            {selectedCategory === '' || selectedCategory === 'tissus' ? (
                <SectionWithButton
                    title="Tissus"
                    items={fabrics}
                    renderItem={renderItem}
                    category="tissus"
                    containerRef={fabricsContainerRef}
                />
            ) : null}

            {selectedCategory === '' || selectedCategory === 'patterns' ? (
                <SectionWithButton
                    title="Patrons de couture"
                    items={posts}
                    renderItem={renderItem}
                    category="patterns"
                    containerRef={postsContainerRef}
                />
            ) : null}

            {selectedCategory === '' || selectedCategory === 'shoes' ? (
                <SectionWithButton
                    title="Chaussures"
                    items={shoes}
                    renderItem={renderItem}
                    category="shoes"
                    containerRef={shoesContainerRef}
                />
            ) : null}

            {selectedCategory === '' || selectedCategory === 'accessories' ? (
                <SectionWithButton
                    title="Accessoires"
                    items={accessories}
                    renderItem={renderItem}
                    category="accessories"
                    containerRef={accessoriesContainerRef}
                />
            ) : null}

              <Box mt={4}>
                <OfferCard />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Container>
  </Box>
);

  
};

export default HomeClient;
