import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from "../firebase/firebase";
import { toast } from "react-toastify";
import { setDoc, doc } from "firebase/firestore";
import '../styles/Authentication.css';

function SignInwithGoogle() {
  function googleLogin() {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then(async (result) => {
      console.log(result);
      const user = result.user;
      if (result.user) {
        // Normalize the displayName to lowercase
        const normalizedDisplayName = user.displayName ? user.displayName.toLowerCase() : '';

        // Set the user document in Firestore with normalized name
        await setDoc(doc(db, "users", user.uid), {
          email: user.email,
          uid: user.uid,
          name: normalizedDisplayName,  // Ensure name is lowercase
          role: "client",
          logo: user.photoURL,
          credits: 1,
        });
        toast.success("User logged in Successfully", {
          position: "top-center",
        });
        window.location.href = "/client-main";
      }
    }).catch((error) => {
      toast.error("Login failed. Please try again.", {
        position: "top-center",
      });
      console.error("Google login error: ", error);
    });
  }

  return (
    <div className="google-login-container">
      <button className="google-login-button" onClick={googleLogin}>
        <img src={require("../assets/google.png")} alt="Google logo" className="google-logo"  />
        <span>Se connecter avec Google</span>
      </button>
    </div>
  );
}

export default SignInwithGoogle;
