import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../features/authContext';
import { db } from '../firebase/firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Button, CircularProgress, Container, Typography, Box, styled } from '@mui/material';

// Utilisation de styled() pour le style
const useStyles = styled({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  imagePreview: {
    width: '300px',
    height: '200px',
    marginBottom: '20px',
    objectFit: 'cover',
    borderRadius: '10px',
  },
  button: {
    backgroundColor: '#5f5f5f',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '5px',
    margin: '10px 0',
    '&:hover': {
      backgroundColor: '#4e4e4e',
    },
  },
  saveButton: {
    backgroundColor: '#3498db',
    '&:hover': {
      backgroundColor: '#2980b9',
    },
  },
})();

const ModifierBanniere = () => {
  const classes = useStyles;
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const pickImage = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setImage(file);
    }
  };

  const uploadImage = async (file) => {
    if (!currentUser) return;

    const storage = getStorage();
    const storageRef = ref(storage, `banners/${currentUser.uid}.jpg`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };

  const saveBanner = async () => {
    if (!image) return;

    setLoading(true);

    try {
      const imageUrl = await uploadImage(image);
      const profileDocRef = doc(db, 'profiles', currentUser.uid);
      const docSnapshot = await getDoc(profileDocRef);

      if (docSnapshot.exists()) {
        await updateDoc(profileDocRef, { banner: imageUrl });
      } else {
        await setDoc(profileDocRef, { banner: imageUrl });
      }

      navigate('/couturier-main');
    } catch (error) {
      console.error('Error saving banner:', error);
      alert('Une erreur s\'est produite lors de la sauvegarde de la bannière.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container sx={classes.container}>
      <Typography variant="h4" sx={classes.title}>Modifier la Bannière</Typography>
      {image && <img src={image} alt="Preview" style={classes.imagePreview} />}
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="contained-button-file"
        type="file"
        onChange={pickImage}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" component="span" sx={classes.button}>
          Choisir une Image
        </Button>
      </label>
      <Button
        variant="contained"
        sx={{ ...classes.button, ...classes.saveButton }}
        onClick={saveBanner}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Enregistrer'}
      </Button>
    </Container>
  );
};

export default ModifierBanniere;

