import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../features/authContext';
import { db } from '../firebase/firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { Button, Typography, Container, Box, CircularProgress, Paper } from '@mui/material';
import { Plus } from 'lucide-react'; 
import { ArrowLeft } from 'lucide-react';

const Publish = () => {
    const navigate = useNavigate();
    const { currentUser } = useContext(AuthContext);
    const [gender, setGender] = useState(null);
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchProfileData = async () => {
            if (currentUser && currentUser.uid) {
                try {
                    const docRef = doc(db, "profiles", currentUser.uid);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        setGender(docSnap.data().gender);
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération du profil:", error);
                }
            }
            setLoading(false);
        };

        fetchProfileData();
    }, [currentUser]);

    useEffect(() => {
        const fetchOrders = async () => {
            if (currentUser) {
                const q = query(collection(db, 'commandes'), where('userId', '==', currentUser.uid));
                const querySnapshot = await getDocs(q);
                const ordersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setOrders(ordersData);
            }
        };

        fetchOrders();
    }, [currentUser]);

    const handleNavigation = (path) => {
        navigate(path);
    };

    if (loading) {
        return (
            <Container style={styles.loadingContainer}>
                <CircularProgress size={60} />
            </Container>
        );
    }

    return (
        <Box
      sx={{
        backgroundImage: 'url("https://www.transparenttextures.com/patterns/white-linen.png")',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        py: 4
      }}
    >
        <Button onClick={() => navigate(-1)} leftIcon={<ArrowLeft />}>Retour</Button>
        <Container style={styles.container}>
            <Typography variant="h4" style={styles.header}>Vendre</Typography>
            <Box style={styles.optionContainer} onClick={() => handleNavigation('/publish-screen')}>
                <Typography variant="h6" style={styles.optionText}>Ajouter un modèle</Typography>
                <Plus style={styles.icon} />
            </Box>
            <Box style={styles.optionContainer} onClick={() => handleNavigation('/tissu')}>
                <Typography variant="h6" style={styles.optionText}>Ajouter un tissu</Typography>
                <Plus style={styles.icon} />
            </Box>
            <Box style={styles.optionContainer} onClick={() => handleNavigation('/accessories')}>
                <Typography variant="h6" style={styles.optionText}>Ajouter des accessoires</Typography>
                <Plus style={styles.icon} />
            </Box>
            <Box style={styles.optionContainer} onClick={() => handleNavigation('/shoes')}>
                <Typography variant="h6" style={styles.optionText}>Ajouter des chaussures</Typography>
                <Plus style={styles.icon} />
            </Box>
            <Box style={styles.optionContainer} onClick={() => handleNavigation('/readytowear')}>
                <Typography variant="h6" style={styles.optionText}>Ajouter Prêt à porter</Typography>
                <Plus style={styles.icon} />
            </Box>
        </Container>
        </Box>
    );
};

const styles = {
    container: {
        padding: '16px',
    },
    header: {
        marginBottom: '16px',
    },
    optionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: '#dde',
        marginBottom: '16px',
        borderRadius: '8px',
        cursor: 'pointer',
    },
    optionText: {
        fontSize: '18px',
        color: '#333',
    },
    icon: {
        fontSize: '24px',
        color: '#333',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
};

export default Publish;
