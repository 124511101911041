// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD7zCdpfNLGpLEl9VH1aclhbaiQ35rpWv0",
  authDomain: "couturia-auth.firebaseapp.com",
  projectId: "couturia-auth",
  storageBucket: "couturia-auth.appspot.com",
  messagingSenderId: "1006664046310",
  appId: "1:1006664046310:web:6c26cc9c678641b6828d27",
  measurementId: "G-9HL2QF1W7E"
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// Initialize services
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const googleAuthProvider = new GoogleAuthProvider();
const storage = getStorage(app);

export { auth, db, storage, googleAuthProvider, functions };
