import React, { useContext, useEffect, useState, useRef } from 'react';
import { Alert, Modal, TextField, Button, Container, Typography, Accordion, AccordionSummary, AccordionDetails, FormControlLabel,Checkbox, InputLabel, Select, MenuItem, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AuthContext } from '../features/authContext';
import { db } from '../firebase/firebase';
import { addDoc, collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';

const DeliveryOptions = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { articleId, currency: defaultCurrency } = location.state || {};
    const { currentUser } = useContext(AuthContext);
  
    const [expandedHome, setExpandedHome] = useState(false);
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
  
    const [confirmAddressVisible, setConfirmAddressVisible] = useState(false);
  
    const [deliveryMethods, setDeliveryMethods] = useState([
      { label: 'En Atelier', value: 'Atelier', selected: false },
      { label: 'Dans votre région', value: 'Region', selected: false },
      { label: 'National', value: 'National', selected: false },
      { label: 'International', value: 'International', selected: false },
    ]);
  
    const [deliveryFees, setDeliveryFees] = useState({
      'Region': { fee: '', currency: defaultCurrency },
      'National': { fee: '', currency: defaultCurrency },
      'International': { fee: '', currency: defaultCurrency },
    });
  
    const [deliveryDurations, setDeliveryDurations] = useState({
      'Region': '',
      'National': '',
      'International': ''
    });
  
    useEffect(() => {
      const fetchCouturierAddress = async () => {
        if (currentUser) {
          try {
            const docRef = doc(db, 'CouturierAddress', currentUser.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              const data = docSnap.data();
              setAddress(data.address || '');
              setAddress2(data.address2 || '');
              setPostalCode(data.postalCode || '');
              setCity(data.city || '');
              setCountry(data.country || '');
            } else {
              console.log('No such document!');
            }
          } catch (error) {
            console.error('Error fetching address:', error);
          }
        }
      };
  
      fetchCouturierAddress();
    }, [currentUser]);
  
    const handleChange = (key, value) => {
      switch (key) {
        case 'address':
          setAddress(value);
          break;
        case 'address2':
          setAddress2(value);
          break;
        case 'postalCode':
          setPostalCode(value);
          break;
        case 'city':
          setCity(value);
          break;
        case 'country':
          setCountry(value);
          break;
        default:
          break;
      }
    };
  
    const handleSubmitHome = async () => {
      if (!address || !city || !country) {
        alert('Veuillez remplir tous les champs obligatoires.');
        return;
      }
  
      try {
        await setDoc(doc(db, 'CouturierAddress', currentUser.uid), {
          address,
          address2,
          postalCode,
          city,
          country,
        });
        setConfirmAddressVisible(true);
      } catch (error) {
        console.error("Erreur lors de l'enregistrement de l'adresse:", error);
      }
    };
  
    const toggleDeliveryMethod = (index) => {
      const updatedMethods = [...deliveryMethods];
      updatedMethods[index].selected = !updatedMethods[index].selected;
      setDeliveryMethods(updatedMethods);
    };
  
    const handleDeliveryFeeChange = (method, fee) => {
      setDeliveryFees((prev) => ({
        ...prev,
        [method]: { ...prev[method], fee },
      }));
    };
  
    const handleCurrencyChange = (method, currency) => {
      setDeliveryFees((prev) => ({
        ...prev,
        [method]: { ...prev[method], currency },
      }));
    };
  
    const handleDeliveryDurationChange = (method, duration) => {
      setDeliveryDurations((prev) => ({
        ...prev,
        [method]: duration,
      }));
    };
  
    const handleSubmit = async () => {
      const selectedMethods = deliveryMethods.filter(method => method.selected);
      if (selectedMethods.length === 0) {
        alert('Erreur, veuillez sélectionner au moins une option de livraison.');
        return;
      }
  
      try {
        for (const method of selectedMethods) {
          let fee = '0';
          let currency = '';
          let duration = 0;
  
          if (method.value !== 'Atelier') {
            if (deliveryFees[method.value]) {
              fee = deliveryFees[method.value].fee;
              currency = deliveryFees[method.value].currency;
              duration = Number(deliveryDurations[method.value]);
            } else {
              console.error(`Delivery fee not found for ${method.label}`);
              continue;
            }
          }
  
          await setDoc(doc(db, 'deliveryOptions', `${articleId}_${method.value}`), {
            articleId,
            method: method.value,
            fee: fee,
            currency: currency,
            userId: currentUser.uid,
            duration: duration,
          });
        }
  
        navigate('/my-posts');
      } catch (error) {
        console.error("Erreur lors de l'enregistrement des options de livraison:", error);
      }
    };
  
    const handleConfirmAddress = () => {
      setConfirmAddressVisible(false);
    };
  
    return (
      <Container maxWidth="sm" sx={{ bgcolor: 'background.paper', padding: 4, borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom>
          Veuillez sélectionner les options de livraison pour l'article:
        </Typography>
  
        <Accordion expanded={expandedHome} onChange={() => setExpandedHome(!expandedHome)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Atelier du vendeur</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              label="Adresse *"
              value={address}
              onChange={(e) => handleChange('address', e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Complément d'adresse"
              value={address2}
              onChange={(e) => handleChange('address2', e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Code postal"
              value={postalCode}
              onChange={(e) => handleChange('postalCode', e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Ville *"
              value={city}
              onChange={(e) => handleChange('city', e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Pays *"
              value={country}
              onChange={(e) => handleChange('country', e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button variant="contained" onClick={handleSubmitHome} sx={{ mt: 2 }}>
              Continuer
            </Button>
          </AccordionDetails>
        </Accordion>
  
        {deliveryMethods.map((method, index) => (
          <Box key={method.value} sx={{ my: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={method.selected}
                  onChange={() => toggleDeliveryMethod(index)}
                />
              }
              label={method.label}
            />
            {method.selected && method.value !== 'Atelier' && (
              <>
                <TextField
                  label={`Frais de livraison pour ${method.label}`}
                  value={deliveryFees[method.value].fee}
                  onChange={(e) => handleDeliveryFeeChange(method.value, e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {['XOF', 'EUR', 'USD'].map((currency) => (
                    <Button
                      key={currency}
                      variant={deliveryFees[method.value].currency === currency ? 'contained' : 'outlined'}
                      onClick={() => handleCurrencyChange(method.value, currency)}
                    >
                      {currency}
                    </Button>
                  ))}
                </Box>
                <TextField
                  label={`Durée de livraison pour ${method.label} (en jours)`}
                  value={deliveryDurations[method.value]}
                  onChange={(e) => handleDeliveryDurationChange(method.value, e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </>
            )}
            {method.selected && method.value === 'Atelier' && (
              <Typography variant="body2" color="success.main">
                Livraison gratuite
              </Typography>
            )}
          </Box>
        ))}
  
        <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2 }}>
          Soumettre
        </Button>
  
        {confirmAddressVisible && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              Adresse confirmée
            </Typography>
            <Alert severity="success">Votre adresse a été confirmée avec succès!</Alert>
            <Button variant="contained" onClick={handleConfirmAddress} sx={{ mt: 2 }}>
              Ok
            </Button>
          </Box>
        )}
      </Container>
    );
  };
  
  export default DeliveryOptions;
