import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Menu,
  MenuItem,
  Button,
  Avatar,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton
} from '@mui/material';
import { AuthContext } from '../features/authContext';
import { db } from '../firebase/firebase';
import {
  collection,
  query,
  where,
  onSnapshot,
  getDoc,
  doc as firestoreDoc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { CircleUserRound, CircleArrowLeft } from 'lucide-react';

const MessagesScreen = ({ setUnreadCount }) => {
  const { currentUser } = useContext(AuthContext); // Ensure this is not null
  const [users, setUsers] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const fetchUsersWithNotifications = async () => {
      try {
        const notificationsQuery = query(collection(db, 'notifications'), where('senderId', '==', currentUser.uid));
        const notificationsSnapshot = await getDocs(notificationsQuery);

        const notifiedUserIds = new Set();
        notificationsSnapshot.forEach((notificationDoc) => {
          const data = notificationDoc.data();
          notifiedUserIds.add(data.userId);
        });

        const usersQuery = collection(db, 'users');
        const usersSnapshot = await getDocs(usersQuery);

        const usersData = [];
        usersSnapshot.forEach((userDoc) => {
          if (notifiedUserIds.has(userDoc.id) && userDoc.id !== currentUser.uid) {
            usersData.push({ uid: userDoc.id, ...userDoc.data() });
          }
        });

        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users with notifications:', error);
      }
    };

    fetchUsersWithNotifications();

    const q = query(collection(db, 'messages'), where('recipientId', '==', currentUser.uid));
    const unsubscribeConversations = onSnapshot(q, async (querySnapshot) => {
      try {
        const conversationsData = [];
        const seenUsers = new Set();
        let unreadCount = 0;

        for (const docSnapshot of querySnapshot.docs) {
          const data = docSnapshot.data();
          const otherUserId = data.userId === currentUser.uid ? data.recipientId : data.userId;
          const otherUserName = data.userId === currentUser.uid ? data.recipientName : data.senderName;

          if (!seenUsers.has(otherUserId)) {
            seenUsers.add(otherUserId);

            const profileDoc = await getDoc(firestoreDoc(db, 'profiles', otherUserId));
            const profileData = profileDoc.exists() ? profileDoc.data() : {};
            const profilePicture = profileData.logo || null;

            if (!data.read) {
              unreadCount += 1;
            }

            conversationsData.push({
              ...data,
              id: docSnapshot.id,
              otherUserId,
              otherUserName,
              profilePicture,
              unread: !data.read,
            });
          }
        }

        setUnreadCount(unreadCount);
        setConversations(conversationsData);
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    });

    return () => {
      unsubscribeConversations();
    };
  }, [currentUser, setUnreadCount]);

  const handleStartConversation = async (recipient) => {
    try {
      const q = query(collection(db, 'messages'), where('recipientId', '==', currentUser.uid), where('userId', '==', recipient.uid));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((docSnapshot) => {
        updateDoc(firestoreDoc(db, 'messages', docSnapshot.id), { read: true });
      });

      navigate('/conversation', {
        state: {
          recipient: {
            uid: recipient.uid,
            name: recipient.name,
          },
        },
      });
    } catch (error) {
      console.error('Error starting conversation:', error);
    }
  };

  if (!currentUser) {
    return (
      <Container style={styles.centeredContainer}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <IconButton onClick={() => navigate(-1)} style={styles.backButton}>
        <CircleArrowLeft/>
      </IconButton>
      <AppBar position="static" style={{ backgroundColor: '#b8cacd' }}>
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Ecris aux clients
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <CircleUserRound />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {users.map((user) => (
              <MenuItem
                key={user.uid}
                onClick={() => {
                  handleStartConversation(user);
                  setAnchorEl(null);
                }}
              >
                {user.name}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>

      <List>
        {conversations.map((item) => (
          <ListItem
            key={item.id}
            button
            onClick={() =>
              handleStartConversation({
                uid: item.otherUserId,
                name: item.otherUserName,
              })
            }
          >
            <ListItemAvatar>
              {item.profilePicture ? (
                <Avatar alt={item.otherUserName} src={item.profilePicture} />
              ) : (
                <Avatar>
                  <CircleUserRound />
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={item.otherUserName || 'Utilisateur inconnu'}
              secondary={item.lastMessage || 'Aucun message'}
              style={item.unread ? { fontWeight: 'bold' } : {}}
            />
          </ListItem>
        ))}
      </List>
      {conversations.length === 0 && (
        <Typography style={styles.emptyText}>
          Aucun message, veuillez passer une commande pour contacter votre couturier.
        </Typography>
      )}
      {refreshing && <CircularProgress />}
    </Container>
  );
};

const styles = {
  backButton: {
    position: 'absolute',
    left: 10,
    top: 10,
  },
  centeredContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  emptyText: {
    textAlign: 'center',
    marginTop: '20px',
  },
};

export default MessagesScreen;
