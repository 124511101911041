import React from 'react';
import { Box, Typography, Button, Card, CardContent, CardMedia } from '@mui/material';
import Suit from '../assets/suit.jpg';

const OfferCard = () => {
  return (
    <Card style={styles.cardContainer}>
      <CardContent style={styles.textContainer}>
        <Typography variant="h5" style={styles.discountText}>Spécial Tabaski</Typography>
        <Typography variant="body1" style={styles.descriptionText}>Livraison gratuite</Typography>
        <Typography variant="body2" style={styles.codeText}>Pour toute commande avec plus de 5 articles</Typography>
        {/* 
        <Button variant="contained" color="primary" style={styles.button}>
          Obtenir maintenant
        </Button> 
        */}
      </CardContent>
      <CardMedia
        component="img"
        image={Suit}
        style={styles.image}
        alt="Suit"
      />
    </Card>
  );
};

const styles = {
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 20px',
    marginRight: 24,
    backgroundColor: '#c7c7c7',
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  textContainer: {
    flex: 1,
    paddingRight: 10,
  },
  discountText: {
    fontWeight: '800',
    fontSize: 24,
  },
  descriptionText: {
    fontSize: 18,
  },
  codeText: {
    fontSize: 12,
    margin: '8px 0',
  },
  button: {
    backgroundColor: 'black',
    borderRadius: 20,
    padding: '4px 12px',
    alignSelf: 'flex-start',
  },
  buttonText: {
    color: 'white',
    fontSize: 12,
    fontWeight: '600',
  },
  image: {
    width: 60,
    height: 60,
    objectFit: 'contain',
  },
};

export default OfferCard;
