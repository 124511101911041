import React, { useState } from "react";
import { resetPassword } from "../firebase/userAuth";

const PasswordReset = ({ history }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePasswordReset = async () => {
    if (email === "") {
      alert("Please enter your email");
      return;
    }

    setLoading(true);
    const res = await resetPassword(email);
    setLoading(false);

    if (res.success) {
      alert("Password reset email sent. Please check your inbox.");
      history.goBack();
    } else {
      alert(res.error);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <div style={{ width: "300px" }}>
        <label style={{ display: "block", marginBottom: "5px", fontSize: "16px", color: "#333" }}>
          Enter your email address:
        </label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ width: "100%", padding: "10px", border: "1px solid #ccc", borderRadius: "5px", marginBottom: "10px" }}
        />
        <button
          onClick={handlePasswordReset}
          disabled={loading}
          style={{ width: "100%", backgroundColor: "#4285F4", padding: "10px", borderRadius: "5px", color: "white", fontSize: "16px", cursor: "pointer" }}
        >
          {loading ? "Loading..." : "Reset Password"}
        </button>
      </div>
    </div>
  );
};

export default PasswordReset;
