import React, { useEffect, useState, useRef } from 'react';
import { AppBar, Toolbar, Typography, Card, CardContent, CardMedia, Button, TextField, Container, Box, Alert } from '@mui/material';
import { collection, getDocs, doc, getDoc, query, where } from 'firebase/firestore';
import { db } from './firebase/firebase';
import logo from './assets/logobis.png';
import { useNavigate } from 'react-router-dom';
import appStoreLogo from './assets/app_store_logo.png';
import moment from 'moment';

const styles = {
  genderContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 8,
  },
  genderButton: {
    padding: 10,
    marginHorizontal: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'grey',
  },
  genderButtonSelected: {
    backgroundColor: 'violet',
  },
  genderButtonText: {
    color: 'black',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column', // Display buttons in a column
    alignItems: 'center', // Center elements horizontally
    marginVertical: 8,
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row', // Display filter buttons in a row
    justifyContent: 'center', // Center elements horizontally
    marginTop: 8, // Top spacing
  },
  filterButton: {
    padding: 10,
    margin: '0 5px', // Horizontal margin
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'grey',
    cursor: 'pointer',
  },
  filterButtonSelected: {
    backgroundColor: 'violet',
  },
  filterButtonText: {
    color: 'black',
  },
};

const FilterButtons = ({ selectedCategory, setSelectedCategory }) => (
  <div style={styles.filterContainer}>
    <div style={styles.filterRow}>
      <button
        style={{ ...styles.filterButton, ...(selectedCategory === 'tissus' ? styles.filterButtonSelected : {}) }}
        onClick={() => setSelectedCategory(selectedCategory === 'tissus' ? '' : 'tissus')}
      >
        <span style={styles.filterButtonText}>Tissus</span>
      </button>
      <button
        style={{ ...styles.filterButton, ...(selectedCategory === 'accessories' ? styles.filterButtonSelected : {}) }}
        onClick={() => setSelectedCategory(selectedCategory === 'accessories' ? '' : 'accessories')}
      >
        <span style={styles.filterButtonText}>Sac et Accessoires</span>
      </button>
      <button
        style={{ ...styles.filterButton, ...(selectedCategory === 'shoes' ? styles.filterButtonSelected : {}) }}
        onClick={() => setSelectedCategory(selectedCategory === 'shoes' ? '' : 'shoes')}
      >
        <span style={styles.filterButtonText}>Chaussures</span>
      </button>
      <button
        style={{ ...styles.filterButton, ...(selectedCategory === 'readytowear' ? styles.filterButtonSelected : {}) }}
        onClick={() => setSelectedCategory(selectedCategory === 'readytowear' ? '' : 'readytowear')}
      >
        <span style={styles.filterButtonText}>Prêt à porter</span>
      </button>
      <button
        style={{ ...styles.filterButton, ...(selectedCategory === 'patterns' ? styles.filterButtonSelected : {}) }}
        onClick={() => setSelectedCategory(selectedCategory === 'patterns' ? '' : 'patterns')}
      >
        <span style={styles.filterButtonText}>Modèles</span>
      </button>
    </div>
  </div>
);


const newArrivalsContainer = {
  padding: '20px',
  backgroundColor: '#f9f9f9',
  alignItems: 'center',
  borderBottom: '1px solid #ddd',
  marginBottom: '2rem', 
};

const newArrivalsTitle = {
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#333',
  textTransform: 'uppercase',
  letterSpacing: '1.2px',
  marginBottom: '1rem',
  marginTop: '2rem',
};

const DefaultScreen = () => {
    const [posts, setPosts] = useState([]);
    const [fabrics, setFabrics] = useState([]);
    const [readytowear, setReadytowear] = useState([]);
    const [accessories, setAccessories] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const postsContainerRef = useRef(null);
    const fabricsContainerRef = useRef(null);
    const readytowearContainerRef = useRef(null);
    const accessoriesContainerRef = useRef(null);
    const navigate = useNavigate();
    const shoesContainerRef = useRef(null);
    const [shoes, setShoes] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        const fetchShoes = async () => {
            const querySnapshot = await getDocs(collection(db, 'shoes'));
            const shoesData = await Promise.all(
                querySnapshot.docs.map(async docSnapshot => {
                    const shoeData = docSnapshot.data();
                    const userDocRef = doc(db, 'users', shoeData.userId);
                    const userDocSnap = await getDoc(userDocRef);
                    const userData = userDocSnap.exists() ? userDocSnap.data() : {};
                    return { id: docSnapshot.id, ...shoeData, userName: userData.name };
                })
            );
            setShoes(shoesData);
        };

        fetchShoes();
    }, []);


    useEffect(() => {
        const fetchSearchResults = async () => {
            const q = query(collection(db, 'patterns'), where('title', '>=', searchText), where('title', '<=', searchText + '\uf8ff'));
            const querySnapshot = await getDocs(q);
            const results = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setSearchResults(results);
        };

        if (searchText.length > 0) {
            fetchSearchResults();
        } else {
            setSearchResults([]);
        }
    }, [searchText]);

    useEffect(() => {
        const fetchPosts = async () => {
            const querySnapshot = await getDocs(collection(db, 'patterns'));
            const postsData = await Promise.all(
                querySnapshot.docs.map(async docSnapshot => {
                    const postData = docSnapshot.data();
                    const userDocRef = doc(db, 'users', postData.userId);
                    const userDocSnap = await getDoc(userDocRef);
                    const userData = userDocSnap.exists() ? userDocSnap.data() : {};
                    return { id: docSnapshot.id, ...postData, userName: userData.name };
                })
            );
            setPosts(postsData);
        };

        fetchPosts();
    }, []);

    useEffect(() => {
        const fetchFabrics = async () => {
            const querySnapshot = await getDocs(collection(db, 'tissus'));
            const fabricsData = await Promise.all(
                querySnapshot.docs.map(async docSnapshot => {
                    const fabricData = docSnapshot.data();
                    const userDocRef = doc(db, 'users', fabricData.userId);
                    const userDocSnap = await getDoc(userDocRef);
                    const userData = userDocSnap.exists() ? userDocSnap.data() : {};
                    return { id: docSnapshot.id, ...fabricData, userName: userData.name };
                })
            );
            setFabrics(fabricsData);
        };

        fetchFabrics();
    }, []);

    useEffect(() => {
      const fetchReadytowear = async () => {
          const querySnapshot = await getDocs(collection(db, 'readytowear'));
          const fabricsData = await Promise.all(
              querySnapshot.docs.map(async docSnapshot => {
                  const fabricData = docSnapshot.data();
                  const userDocRef = doc(db, 'users', fabricData.userId);
                  const userDocSnap = await getDoc(userDocRef);
                  const userData = userDocSnap.exists() ? userDocSnap.data() : {};
                  return { id: docSnapshot.id, ...fabricData, userName: userData.name };
              })
          );
          setReadytowear(fabricsData);
      };

      fetchReadytowear();
  }, []);

    useEffect(() => {
        const fetchAccessories = async () => {
            const querySnapshot = await getDocs(collection(db, 'accessories'));
            const accessoriesData = await Promise.all(
                querySnapshot.docs.map(async docSnapshot => {
                    const accessoryData = docSnapshot.data();
                    const userDocRef = doc(db, 'users', accessoryData.userId);
                    const userDocSnap = await getDoc(userDocRef);
                    const userData = userDocSnap.exists() ? userDocSnap.data() : {};
                    return { id: docSnapshot.id, ...accessoryData, userName: userData.name };
                })
            );
            setAccessories(accessoriesData);
        };

        fetchAccessories();
    }, []);

    const handleNavigateToCouturierRegistration = () => {
        navigate('/couturier-registration');
    };

    const formatPrice = (price, discount) => {
        // Convertir les entrées en nombres
        const originalPrice = parseFloat(price);
        const discountValue = parseFloat(discount);
      
        // Vérifiez que les valeurs sont des nombres valides
        if (isNaN(originalPrice) || isNaN(discountValue)) {
          return { originalPrice: 'N/A', discountedPrice: 'N/A' };
        }
      
        const newPrice = originalPrice - (originalPrice * discountValue / 100);
        return { originalPrice: originalPrice.toFixed(2), newPrice: newPrice.toFixed(2) };
      };

    const handleCardClick = (id, category, newPrice, post) => {
        if (newPrice === undefined) {
          console.error('newPrice is undefined');
          return;
        }
      
        navigate(`/detail/${id}/${category}`, { state: { newPrice, post } });
      };
    
      const getFirstThreeWords = (description) => {
        const words = description.split(' ');
        return words.slice(0, 3).join(' ') + (words.length > 3 ? '...' : '');
      };

      const getFirstWords = (description) => {
        const words = description.split(' ');
        return words.slice(0, 2).join(' ') + (words.length > 2 ? '...' : '');
      };
       // Fonction pour afficher une alerte si l'article est en rupture de stock
       const showOutOfStockAlert = () => {
        Alert.alert(
        'Article en rupture de stock',
        'Cet article est actuellement en rupture de stock.',
        [{ text: 'OK' }],
        { cancelable: false }
        );
    };
  
    // Fonction pour trier les articles par date de publication (du plus récent au plus ancien)
    const sortByDateDesc = (arr) => {
      return arr.sort((a, b) => {
        const dateA = moment(a.timestamp.toDate ? a.timestamp.toDate() : a.timestamp);
        const dateB = moment(b.timestamp.toDate ? b.timestamp.toDate() : b.timestamp);
        return dateB - dateA; // Trie du plus récent au plus ancien
      });
    };

    const renderItem = ({ item, category }) => {
      const hasPromotion = item.promotion && item.promotion > 0;
      const price = parseFloat(item.price) || 0;
      const promotion = parseFloat(item.promotion) || 0;
      const { originalPrice, newPrice } = formatPrice(price, promotion);

      return (
        <Box key={item.id} sx={{ minWidth: 250, display: 'inline-block', margin: 1, position: 'relative' }}>
          <Typography variant="body2" color="text.secondary">
            {item.userName} a publié
          </Typography>
          <Card style={{ margin: 8 }}>
            <CardMedia
              component={item.video ? 'video' : 'img'}
              height="140"
              image={item.images && Array.isArray(item.images) && item.images.length > 0 ? item.images[0] : undefined}
              src={item.video}
              controls={!!item.video}
              alt={item.title}
              style={item.availability === 'Epuisée' ? { filter: 'blur(5px)' } : {}}
            />
            {item.availability === 'Epuisée' && (
              <Typography variant="caption" color="error" sx={{ position: 'absolute', top: 8, left: 8, backgroundColor: 'rgba(255, 255, 255, 0.7)', padding: '2px 4px', borderRadius: 2 }}>
                Épuisé
              </Typography>
            )}
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {getFirstWords(item.title)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {getFirstThreeWords(item.description)}
              </Typography>
              {hasPromotion ? (
                <Typography variant="body2" color="text.secondary">
                  Prix: <span style={{ textDecoration: 'line-through' }}>{originalPrice} {item.currency}</span>{' '}
                  <span style={{ color: 'red' }}>{newPrice} {item.currency}</span>
                </Typography>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  Prix: {price} {item.currency}
                </Typography>
              )}
              <Button 
                variant="contained" 
                onClick={() => {
                  if (item.availability === 'Epuisée') {
                    showOutOfStockAlert();
                  } else {
                    handleCardClick(item.id, category, newPrice, item);
                  }
                }} 
                disabled={item.availability === 'Epuisée'}
                style={{ marginTop: 8 }}
              >
                Voir les détails
              </Button>
            </CardContent>
          </Card>
        </Box>
      );
    };

    // Utiliser la fonction de tri sur les différentes catégories d'articles
    const Section = ({ title, items, category, containerRef, navigate }) => (
      <Box sx={newArrivalsContainer}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" sx={newArrivalsTitle}>
            {title}
          </Typography>
          <Button
            variant="text"
            onClick={() => navigate(`/category/${category}`)}
          >
            Voir tout
          </Button>
        </Box>
        <div ref={containerRef} style={{ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap' }}>
          {sortByDateDesc(items).map((item) => renderItem({ item, category }))}
        </div>
      </Box>
    );
    
    return (
      <Box
    sx={{
      backgroundColor: '#b8cacd',
      backgroundImage: 'url("https://www.transparenttextures.com/patterns/45-degree-fabric-light.png")',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      minHeight: '100vh',
      py: 4
    }}
  >
        <Container sx={{ padding: '2rem' }}>
            <AppBar position="static" sx={{ marginBottom: '1rem' }}>
                <Toolbar>
                    <img src={logo} alt="Couturia Logo" style={{ height: '50px', marginRight: '1rem' }} />
                    <Typography variant="h6">Couturia</Typography>
                </Toolbar>
            </AppBar>
            <Button variant="contained" color="primary" sx={{ marginBottom: '1rem' }} onClick={handleNavigateToCouturierRegistration}>
                Vous êtes e-commerçant/couturier professionnel? Rejoignez la communauté Couturia!
            </Button>
            <TextField
                label="Rechercher des modèles..."
                variant="outlined"
                fullWidth
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                sx={{ marginBottom: '1rem' }}
            />
            {searchText.length > 0 ? (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {searchResults.map((item) => renderItem({ item, category: 'patterns' }))}
                </div>
            ) : (
                <div>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        sx={{ marginBottom: '1rem' }}
                        onClick={() => navigate('/auth')} 
                    >
                        Se Connecter / S'inscrire
                    </Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        sx={{ marginBottom: '1rem', marginLeft: '1rem' }}
                        onClick={() => navigate('/fabrictodesign')} 
                    >
                        Crée ton modèle de couture avec notre IA
                    </Button>
                    <FilterButtons selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />

                    {selectedCategory === 'tissus' && (
        <Box>
          <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
            Tissus
          </Typography>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {fabrics.map((item) => renderItem({ item, category: 'tissus' }))}
          </div>
        </Box>
      )}

      {selectedCategory === 'accessories' && (
        <Box>
          <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
            Sac et Accessoires
          </Typography>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {accessories.map((item) => renderItem({ item, category: 'accessories' }))}
          </div>
        </Box>
      )}

      {selectedCategory === 'shoes' && (
        <Box>
          <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
            Chaussures
          </Typography>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {shoes.map((item) => renderItem({ item, category: 'shoes' }))}
          </div>
        </Box>
      )}

{selectedCategory === 'patterns' && (
        <Box>
          <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
            Modèles
          </Typography>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {posts.map((item) => renderItem({ item, category: 'patterns' }))}
          </div>
        </Box>
      )}

{selectedCategory === 'readytowear' && (
        <Box>
          <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
            Prêt à porter
          </Typography>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {readytowear.map((item) => renderItem({ item, category: 'readytowear' }))}
          </div>
        </Box>
      )}

<>
    <Section title="Prêt à porter" items={readytowear} category="readytowear" containerRef={readytowearContainerRef} navigate={navigate} />
    <Section title="Tissus" items={fabrics} category="tissus" containerRef={fabricsContainerRef} navigate={navigate} />
    <Section title="Patrons de couture" items={posts} category="patterns" containerRef={postsContainerRef} navigate={navigate} />
    <Section title="Chaussures" items={shoes} category="shoes" containerRef={shoesContainerRef} navigate={navigate} />
    <Section title="Sac et Accessoires" items={accessories} category="accessories" containerRef={accessoriesContainerRef} navigate={navigate} />
  </>
                </div>
            )}
            {/* Footer Section */}
            <Box sx={{ textAlign: 'center', marginTop: '3rem', padding: '2rem 0', backgroundColor: '#f5f5f5' }}>
                <Typography variant="body1" sx={{ marginBottom: '1rem' }}>Téléchargez notre application sur l'App Store</Typography>
                <Button 
                   target="_blank"
                   rel="noopener noreferrer"
                   sx={{ padding: 0 }} 
                  href = 'https://apps.apple.com/fr/app/couturia/id6504248657'
                >
                    <img src={appStoreLogo} alt="Télécharger dans l'App Store" style={{ width: '150px' }} />

                </Button>
            </Box>
        </Container>
        </Box>
    );
};

export default DefaultScreen;
