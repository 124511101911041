import React, { useState, useContext } from 'react';
import { Container, TextField, Button, Typography, Card, CardContent, MenuItem, Select, InputLabel, Box, FormControl} from '@mui/material';
import { addDoc, collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, db } from '../firebase/firebase';
import { AuthContext } from '../features/authContext';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { ArrowLeft } from 'lucide-react';

const PublishScreen = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [priceType, setPriceType] = useState('Négociable');
  const [gender, setGender] = useState('Mixte');
  const [ageGroup, setAgeGroup] = useState('Adultes');
  const [images, setImages] = useState([]);
  const [currency, setCurrency] = useState('XOF');
  const [duration, setDuration] = useState(1);
  const [fabricType, setFabricType] = useState('getzner');
  const [paymentType, setPaymentType] = useState('');

  const onDrop = (acceptedFiles) => {
    setImages([...images, ...acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))]);
  };

  const paymentOptions = [
    { label: 'Avant livraison', value: 'BeforeDelivery' },
    { label: 'A la livraison', value: 'AtDelivery' },
    { label: 'Après essai', value: 'AfterDelivery' },];

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*', maxFiles: 5 });

  const uploadImages = async () => {
    const storage = getStorage();
    const uploadedImages = [];
    const userId = auth.currentUser?.uid;

    if (!userId) {
      throw new Error('User is not authenticated');
    }

    for (const file of images) {
      const storageRef = ref(storage, `users/${userId}/pattern_images/${new Date().getTime()}_${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      uploadedImages.push(downloadURL);
    }

    return uploadedImages;
  };

  const publishPattern = async () => {
    if (!title || !description || !price || images.length === 0) {
      alert('Veuillez remplir tous les champs et sélectionner au moins une image.');
      return;
    }

    try {
      const downloadURLs = await uploadImages();

      const docRef = await addDoc(collection(db, 'patterns'), {
        title,
        description,
        price,
        priceType,
        paymentType,
        gender,
        ageGroup,
        currency, 
        duration,
        fabricType,
        images: downloadURLs,
        userId: currentUser.uid,
        timestamp: new Date()
      });

      console.log('Pattern added with ID: ', docRef.id);
      alert('Votre produit a été publié avec succès ! Vous pouvez le visualiser dans l\'onglet Mes Publications.');

      setTitle('');
      setDescription('');
      setPrice('');
      setImages([]);

      navigate('/my-posts');
    } catch (error) {
      console.error('Error adding pattern: ', error);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url("https://www.transparenttextures.com/patterns/white-linen.png")',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        py: 4
      }}
    >
      <Button onClick={() => navigate(-1)} leftIcon={<ArrowLeft />}>Retour</Button>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 3 }}>
          Ajouter patron de couture
        </Typography>
  
        <Box
          {...getRootProps()}
          sx={{
            border: '2px dashed #1976d2',
            borderRadius: 2,
            padding: 4,
            textAlign: 'center',
            mb: 3,
            bgcolor: '#f0f4f8',
            '&:hover': { borderColor: '#115293', bgcolor: '#e3f2fd' },
          }}
        >
          <input {...getInputProps()} />
          <Typography variant="body1" color="textSecondary">
            + Ajouter jusqu'à 5 photos
          </Typography>
        </Box>
  
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 3 }}>
          {images.map((file, index) => (
            <img
              key={index}
              src={file.preview}
              alt={`upload-${index}`}
              style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
            />
          ))}
        </Box>
  
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <TextField
              label="Titre"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </CardContent>
        </Card>
  
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              multiline
              rows={4}
              margin="normal"
              variant="outlined"
            />
          </CardContent>
        </Card>
  
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <TextField
              label="Prix sans les frais de port"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              fullWidth
              margin="normal"
              type="number"
              variant="outlined"
            />
          </CardContent>
        </Card>
  
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Devise</InputLabel>
              <Select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                label="Devise"
              >
                <MenuItem value="XOF">XOF</MenuItem>
                <MenuItem value="EUR">EUR</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
        </Card>
  
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Prix de vente</InputLabel>
              <Select
                value={priceType}
                onChange={(e) => setPriceType(e.target.value)}
                label="Prix de vente"
              >
                <MenuItem value="Négociable">Négociable</MenuItem>
                <MenuItem value="Fixe">Fixe</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
        </Card>
  
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Type de paiement
            </Typography>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="payment-type-label">Sélectionner le type de paiement</InputLabel>
              <Select
                labelId="payment-type-label"
                id="payment-type"
                value={paymentType}
                onChange={(e) => setPaymentType(e.target.value)}
                label="Sélectionner le type de paiement"
              >
                {paymentOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
        </Card>
  
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Genre</InputLabel>
              <Select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                label="Genre"
              >
                <MenuItem value="Homme">Homme</MenuItem>
                <MenuItem value="Femme">Femme</MenuItem>
                <MenuItem value="Mixte">Mixte</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
        </Card>
  
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Âge</InputLabel>
              <Select
                value={ageGroup}
                onChange={(e) => setAgeGroup(e.target.value)}
                label="Âge"
              >
                <MenuItem value="Enfants">Enfants</MenuItem>
                <MenuItem value="Adultes">Adultes</MenuItem>
                <MenuItem value="Personnalisables">Personnalisables</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
        </Card>
  
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Durée de confection</InputLabel>
              <Select
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                label="Durée de confection"
              >
                {Array.from({ length: 30 }, (_, i) => i + 1).map(day => (
                  <MenuItem key={day} value={day}>{`${day} jours`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
        </Card>
  
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Type de tissu</InputLabel>
              <Select
                value={fabricType}
                onChange={(e) => setFabricType(e.target.value)}
                label="Type de tissu"
              >
                <MenuItem value="aucun">Aucun</MenuItem>
                <MenuItem value="getzner">Getzner</MenuItem>
                <MenuItem value="bazin">Bazin</MenuItem>
                <MenuItem value="brodé">Brodé</MenuItem>
                <MenuItem value="gagnila">Gagnila</MenuItem>
                <MenuItem value="supercent">Super 100</MenuItem>
                <MenuItem value="auchoix">Au Choix</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
        </Card>
  
        <Button
          variant="contained"
          color="primary"
          onClick={publishPattern}
          fullWidth
          sx={{
            py: 1.5,
            fontSize: '1rem',
            bgcolor: '#1976d2',
            '&:hover': { bgcolor: '#115293' },
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          }}
        >
          Publier
        </Button>
      </Container>
    </Box>
  );
  
};

export default PublishScreen;
